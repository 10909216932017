import { IonModal, IonSpinner } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { FormInput } from "@/components/Form/Input";
import { Button } from "@/components/Global/Button";
import { backendService } from "@/lib/backend";
import { useMe } from "@/lib/query/functions/user/me";
import { useSelectorAuthStore } from "@/lib/store/auth";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalDeleteAccount() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.appDeleteAccount();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const unauthenticate = useSelectorAuthStore.use.unauthenticate();
	const [me] = useMe();

	const form = useForm({
		defaultValues: { email: "" },
		validators: {
			onSubmit: z.object({
				email: z.string().refine((value) => value === me?.email, {
					message: t("auth.delete-acount.dialog.email-must-match"),
				}),
			}),
		},
		onSubmit: async () => {
			if (!me) {
				return;
			}
			posthog.capture("Modal App DeleteAccount Finished", { $set: { deleted: true } });
			await backendService.user.usersControllerRemove(me.id);
			unauthenticate();
		},
	});

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => {
				present("appDeleteAccount");
				posthog.capture("Modal App DeleteAccount Present");
			}}
			onWillDismiss={() => {
				dismiss("appDeleteAccount");
				posthog.capture("Modal App DeleteAccount Dismiss");
				form.reset();
			}}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="ion-h-auto"
		>
			<form
				onSubmit={(event) => {
					event.preventDefault();
					event.stopPropagation();
					form.handleSubmit();
				}}
				className="flex flex-col gap-2"
			>
				<div className="ion-padding flex flex-col gap-6 pt-12 pb-8">
					<div className="flex flex-col gap-2">
						<h1 className="text-brown-700 text-3xl font-bold">
							{t("auth.delete-acount.dialog.title")}
						</h1>
						<p className="text-brown-600">{t("auth.delete-acount.dialog.description")}</p>
						<form.Field
							name="email"
							children={(field) => (
								<FormInput
									data-attr="modal.app.delete-account.email"
									field={field}
									type="email"
									autocomplete="email"
									inputMode="email"
									placeholder={t("fields.email.placeholder")}
								></FormInput>
							)}
						/>
					</div>
					<div className="flex flex-col gap-2">
						<form.Subscribe
							selector={(state) => [state.canSubmit, state.isSubmitting]}
							children={([canSubmit, isSubmitting]) => (
								<Button
									data-attr="modal.app.delete-account.submit"
									type="submit"
									expand="block"
									disabled={!canSubmit}
									className="!ion-rounded-2xl text-lg font-bold"
								>
									{isSubmitting ? (
										<>
											<IonSpinner slot="start" className="me-2" />
											{`${t("loading")}...`}
										</>
									) : (
										t("auth.delete-acount.dialog.actions.delete")
									)}
								</Button>
							)}
						/>
						<Button
							data-attr="modal.app.delete-account.back"
							expand="block"
							className="ion-bg-a-brown-100 ion-bg-brown-100 ion-bg-f-brown-100 ion-bg-h-brown-100 ion-text-brown-700 !ion-rounded-2xl text-lg font-bold"
							onClick={() => modalRef.current?.dismiss()}
						>
							{t("auth.delete-acount.dialog.actions.back")}
						</Button>
					</div>
				</div>
			</form>
		</IonModal>
	);
}
