import { IonCheckbox, IonHeader, IonIcon, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { motion } from "motion/react";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { useShallow } from "zustand/shallow";
import SplashIcon from "@/assets/splash_icon.svg";
import { FormInput } from "@/components/Form/Input";
import { getErrorText } from "@/components/Form/utils";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { type PatchFriendCheckEmailDto } from "@/lib/backend/api";
import { useExtractedMutation } from "@/lib/backend/utils/hooks";
import { Toast } from "@/lib/capacitor/Toast";
import { arrowRightIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { useMe } from "@/lib/query/functions/user/me";
import { Routes } from "@/lib/router";
import { authSelectors, useAuthStore } from "@/lib/store/auth";
import { useFriendCheckStore } from "@/lib/store/friend-check";
import { cx } from "@/lib/style/cva.config";

const MotionButton = motion.create(Button);

export function SharedFriendCheckThankYou() {
	const { t, i18n } = useTranslation();
	const posthog = usePostHog();
	const state = useFriendCheckStore(
		useShallow((state) => ({
			...state.result,
			friendId: state.friendId,
			friendHash: state.friendHash,
			email: state.email,
		})),
	);

	const isAuthenticated = useAuthStore(useShallow(authSelectors.isAuthenticated));
	const [me] = useMe();

	const mutation = useExtractedMutation({
		mutationKey: ["friend-check", "update"],
		mutationFn: ({
			friendId,
			friendHash,
			...data
		}: { friendId: string; friendHash: string } & PatchFriendCheckEmailDto) =>
			backendService.friendcheck.friendcheckControllerPatchPublicFriendCheckWithEmail(
				friendId,
				friendHash,
				data,
			),
	});

	const form = useForm({
		defaultValues: {
			email: "",
			acceptsEmail: false,
		},
		onSubmit: ({ value }) => {
			if (!state.patchToken || !state.submissionId || !value.email) {
				return;
			}

			if (!isAuthenticated) {
				posthog.setPersonProperties({ email: value.email });
			}

			mutation.mutate(
				{
					friendId: state.friendId,
					friendHash: state.friendHash,
					email: value.email,
					patchToken: state.patchToken,
					submissionId: state.submissionId,
				},
				{
					onSuccess: () => {
						Toast.show({ text: t("page.shared-friend-check.thank-you.message") });
						posthog.capture("Friend Check Email Saved", { friendId: state.friendId });
					},
				},
			);
		},
	});

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
					<div slot="start" className="flex min-w-0 items-center justify-start gap-3">
						<img
							src={SplashIcon}
							className="drag-none -mx-2 -mt-[1.125rem] -mb-4 size-12 min-h-12 min-w-12 select-none"
						/>
						<span className="text-brown-700 truncate text-start text-base font-semibold">
							{t("seo.title")}
						</span>
					</div>
					<motion.a
						slot="end"
						data-attr="page.share.friend-check.thank-you.more-information"
						href="https://imperfect.so"
						target="_blank"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ type: "spring", delay: 0.5 }}
						className="bg-brown-100 text-brown-700 hover:bg-brown-100/75 focus:bg-brown-100/75 active:bg-brown-100/75 plt-native:hidden flex min-h-8 items-center rounded-3xl ps-3 pe-1 text-sm font-semibold"
					>
						{t("page.shared-friend-check.header.more-information")}
						<IonIcon icon={arrowRightIcon} className="size-4 p-2" />
					</motion.a>
					<MotionButton
						slot="end"
						data-attr="page.share.friend-check.thank-you.to-app"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ type: "spring", delay: 0.5 }}
						routerLink={Routes.App()}
						routerDirection="none"
						className="ion-bg-a-brown-100/75 ion-bg-brown-100 ion-bg-f-brown-100/75 ion-bg-h-brown-100/75 ion-text-brown-700 ion-p-0 ion-pe-1 ion-ps-3 plt-native:flex hidden min-h-8 items-center rounded-3xl text-sm font-semibold"
					>
						{t("to-app")}
						<IonIcon slot="end" icon={arrowRightIcon} className="size-4 p-2" />
					</MotionButton>
				</Toolbar>
			</IonHeader>

			<Content>
				<div className="flex flex-1 flex-col items-center justify-center gap-2 *:text-center">
					<h1 className="text-brown-700 text-4xl font-bold">
						{t("page.shared-friend-check.thank-you.title")}
					</h1>
					<span className="text-brown-600 text-base font-normal">
						{t("page.shared-friend-check.thank-you.description")}
					</span>
				</div>

				{!me &&
					!state.email &&
					!!state.patchToken &&
					!!state.patchUntil &&
					!!state.submissionId && (
						<motion.form
							className="flex flex-col gap-4 rounded-2xl bg-white p-3"
							initial={{ opacity: 0, scale: 0.75 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{ type: "spring", delay: 1 }}
							onSubmit={(event) => {
								event.preventDefault();
								event.stopPropagation();
								form.handleSubmit();
							}}
						>
							<form.Field
								name="email"
								validators={{ onSubmit: z.literal("").or(z.string().email()) }}
								children={(field) => (
									<FormInput
										data-attr="page.share.friend-check.thank-you.email"
										field={field}
										type="email"
										autocomplete="email"
										disabled={
											field.form.state.isSubmitted ||
											field.state.meta.isValidating ||
											field.form.state.isSubmitting
										}
										label={t("page.shared-friend-check.fields.email.label")}
										placeholder={t("fields.email.placeholder")}
										className="[&_.native-wrapper]:border-brown-200! [&_.input-wrapper]:gap-4! [&_.label-text-wrapper]:mt-0! [&_.label-text-wrapper]:transform-none!"
									/>
								)}
							/>
							<form.Field
								name="acceptsEmail"
								validators={{
									onSubmit: z.coerce
										.boolean()
										.refine((value) => (form.getFieldValue("email") ? value === true : true), {
											message: t(
												"page.shared-friend-check.fields.email.errors.confirmation-required",
											),
										}),
								}}
								children={(field) => (
									<div
										className={cx(
											"border-brown-200 text-brown-700 flex flex-col gap-2 rounded-xl border-2 p-3 text-sm",
											field.state.meta.isTouched && "ion-touched",
											!!field.state.meta.errors &&
												field.state.meta.errors.length > 0 &&
												"ion-invalid",
										)}
									>
										<IonCheckbox
											data-attr="page.share.friend-check.thank-you.acceptsEmail"
											value={field.state.value}
											labelPlacement="end"
											justify="start"
											alignment="center"
											name={field.name}
											checked={field.state.value === true}
											onIonChange={(event) => field.handleChange(event.detail.checked)}
											onIonBlur={field.handleBlur}
											disabled={
												field.form.state.isSubmitted ||
												field.state.meta.isValidating ||
												field.form.state.isSubmitting
											}
											className="ion-border-brown-200 ion-checkbox-bg-transparent part-container:border-brown-200 font-semibold"
										>
											{t("page.shared-friend-check.fields.email.description")}
										</IonCheckbox>
										{!!field.state.meta.errors && !!field.state.meta.errors.length && (
											<span className="text-danger-500 ms-9 text-xs">
												{getErrorText({ language: i18n.language, errors: field.state.meta.errors })}
											</span>
										)}
									</div>
								)}
							/>

							<form.Subscribe
								selector={(state) => [state.isSubmitted, state.canSubmit, state.isSubmitting]}
								children={([isSubmitted, canSubmit, isSubmitting]) => (
									<Button
										data-attr="page.share.friend-check.thank-you.submit"
										type="submit"
										className="text-lg font-bold"
										disabled={isSubmitted || !canSubmit || isSubmitting}
									>
										{t("save")}
									</Button>
								)}
							/>
						</motion.form>
					)}
			</Content>
		</IonPage>
	);
}
