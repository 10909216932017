import {
	ActionSheetButtonStyle,
	ActionSheet as CapacitorActionSheet,
	type ShowActionsOptions,
} from "@capacitor/action-sheet";

export const ActionSheet = (function () {
	async function showActions(options: ShowActionsOptions) {
		return (await CapacitorActionSheet.showActions(options)).index;
	}

	return {
		...CapacitorActionSheet,
		showActions,
		ButtonStyle: ActionSheetButtonStyle,
	};
})();

export type * from "@capacitor/action-sheet";
