import {
	IonButton,
	IonButtons,
	IonChip,
	IonHeader,
	IonIcon,
	IonPage,
	IonProgressBar,
	IonRefresher,
	IonRefresherContent,
	IonText,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Image_nl from "@/assets/nl/explain-friend-check@2x.png";
import { FriendCheckFilledCard } from "@/components/FriendCheck/FilledCard";
import { FriendCheckProgress } from "@/components/FriendCheck/Progress";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { getBasePath } from "@/lib/backend/getBasePath";
import { useExtractedQuery } from "@/lib/backend/utils/hooks";
import { Clipboard } from "@/lib/capacitor/Clipboard";
import { Share } from "@/lib/capacitor/Share";
import { Toast } from "@/lib/capacitor/Toast";
import { FRIEND_CHECK_TARGET } from "@/lib/constants";
import {
	clipboardDocumentIcon,
	inboxIcon,
	questionMarkCircleIcon,
} from "@/lib/icons/@heroicons/react/24/outline";
import { queryOptions_canShare } from "@/lib/query/functions/@capacitor/share/canShare";
import { queryOptions_friendCheckSubmissions } from "@/lib/query/functions/friend-check/submissions";
import { useMyData } from "@/lib/query/functions/user/data";
import { useMe } from "@/lib/query/functions/user/me";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";

export function FriendCheck() {
	const { t, i18n } = useTranslation();
	const presentModal = useSelectorModalStore.use.present();
	const [myData] = useMyData();
	const [myProgression] = useMyProgression();
	const [me] = useMe();
	const query = useQuery(queryOptions_friendCheckSubmissions());
	const queryCanShare = useQuery(queryOptions_canShare());
	const queryFriendCheckEnabled = useExtractedQuery({
		queryKey: ["friend-check", "enabled"],
		queryFn: (context) =>
			backendService.friendcheck.friendcheckControllerGetFriendCheckEnabled({
				signal: context.signal,
			}),
	});
	const queryFriendHash = useExtractedQuery({
		queryKey: ["friend-check", "friend-hash"],
		queryFn: (context) =>
			backendService.friendcheck.friendcheckControllerGetFriendHash({
				signal: context.signal,
				format: "text",
			}),
		enabled: !!queryFriendCheckEnabled.data,
	});

	const link =
		!queryFriendHash.data || !me?.friendId
			? undefined
			: getBasePath(
					Routes.SharedFriendCheck({
						id: me.friendId,
						hash: queryFriendHash.data,
					}),
					{ withScheme: false },
				);

	async function shareSheet() {
		await Share.share({
			dialogTitle: t("friend-check.share.title"),
			title: t("friend-check.share.title"),
			text: `${t("friend-check.share.text", {
				link,
				sender_name: me?.name,
			})}`,
		});
	}

	async function copyUrl() {
		await Clipboard.write({ url: link });
		await Toast.show({ text: t("copied-to-clipboard") });
	}

	return (
		<IonPage>
			{!myData.modal.friendCheckExplainer.seen ? (
				<Content className="part-scroll:justify-center">
					<img
						src={{ nl: Image_nl }[i18n.language]}
						className="mx-auto max-h-[50vmin] max-w-[50vmin]"
					/>

					<div className="flex flex-col items-center gap-8">
						<div className="flex flex-col items-center gap-1">
							<IonText className="text-brown-700 text-center text-3xl font-bold">
								{t("friend-check.empty-page.title")}
							</IonText>
							<IonText className="text-brown-600 text-center text-lg text-pretty">
								{t("friend-check.empty-page.message")}
							</IonText>
						</div>
						<Button
							data-attr="page.app.friend-check.empty.action"
							expand="block"
							className="w-full text-lg font-bold sm:max-w-56"
							onClick={() => presentModal("friendCheckExplainer")}
						>
							{t("friend-check.empty-page.action")}
						</Button>
					</div>
				</Content>
			) : (
				<>
					<IonHeader>
						<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
							<IonTitle className="ion-text-brown-700 text-lg font-bold">
								{t("friend-check.name")}
							</IonTitle>

							<IonButtons collapse slot="primary">
								<Button
									data-attr="page.app.friend-check.help"
									shape="round"
									color="light"
									size="small"
									className="part-icon:m-0 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
									onClick={() => presentModal("friendCheckExplainer")}
								>
									<IonIcon slot="icon-only" icon={questionMarkCircleIcon} />
								</Button>
							</IonButtons>
						</Toolbar>
					</IonHeader>

					<Content className="part-scroll:gap-4">
						<IonHeader collapse="condense" className="-mt-(--padding-top)">
							<IonToolbar className="ion-px-0">
								<IonTitle className="ion-text-brown-700 p-0 text-start text-3xl font-bold">
									{t("friend-check.name")}
								</IonTitle>

								<IonButtons collapse slot="primary">
									<Button
										data-attr="page.app.friend-check.help"
										shape="round"
										color="light"
										size="small"
										className="part-icon:m-0 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
										onClick={() => presentModal("friendCheckExplainer")}
									>
										<IonIcon slot="icon-only" icon={questionMarkCircleIcon} className="size-5" />
									</Button>
								</IonButtons>
							</IonToolbar>
						</IonHeader>

						<IonRefresher
							slot="fixed"
							onIonRefresh={(event) => query.refetch().then(() => event.detail.complete())}
						>
							<IonRefresherContent />
						</IonRefresher>

						{(query.data?.length || 0) >= FRIEND_CHECK_TARGET && (
							<div className="border-brown-300 flex flex-col gap-2 rounded-2xl border-2 p-4">
								<IonText className="text-brown-600 text-xl font-bold">
									{t("friend-check.finished")}
								</IonText>
								<FriendCheckProgress withAction={false} />
								<IonButton
									className="font-bold"
									onClick={() => {
										presentModal(
											myProgression.friend_check === "ANALYSED" ||
												myProgression.friend_check === "SUMMARIZED"
												? "friendCheckAnalysisView"
												: "friendCheckAnalysisGenerate",
										);
									}}
								>
									{myProgression.friend_check === "ANALYSED" ||
									myProgression.friend_check === "SUMMARIZED"
										? t("friend-check.progress.view-analysis")
										: t("friend-check.progress.analyse-the-friend-check")}
								</IonButton>
							</div>
						)}

						<div className="flex flex-col gap-2">
							<IonText className="text-brown-600 text-xl font-bold">
								{t("friend-check.answers")}
							</IonText>

							<div className="flex flex-col gap-6">
								<div className="border-brown-300 flex flex-col gap-4 rounded-2xl border-2 border-solid p-4">
									<div className="flex w-full flex-col items-center gap-2">
										{(myProgression.friend_check === "NOT_STARTED" ||
											myProgression.friend_check === "STARTED") && (
											<IonIcon icon={inboxIcon} className="text-brown-400 mt-6 size-14" />
										)}

										{!query.data || query.data.length === 0 ? (
											<div className="text-brown-600 flex flex-col items-center text-center">
												<IonText className="font-bold">
													{t("friend-check.start-sharing.title")}
												</IonText>
												<IonText>
													{t("friend-check.start-sharing.text")}{" "}
													{(query.data?.length ?? 0) < FRIEND_CHECK_TARGET && (
														<IonText>
															{t("friend-check.sharing.text", { count: FRIEND_CHECK_TARGET })}
														</IonText>
													)}
												</IonText>
											</div>
										) : (
											<div className="text-brown-600 flex w-full flex-col items-center gap-4 text-center">
												{!!queryFriendCheckEnabled.data && (
													<>
														<IonChip
															disabled
															outline
															className="border-brown-300 mobile-sm:w-1/2 mobile-sm:min-w-32 flex w-full max-w-full flex-row gap-2 border-2 px-3 py-1 opacity-100"
														>
															<IonProgressBar
																className="ion-bg-brown-300 ion-progress-bg-brown-500 h-2 rounded-full"
																value={query.data.length / FRIEND_CHECK_TARGET}
															/>
															<IonText className="text-brown-700 text-xs font-medium tabular-nums">
																{query.data.length}/{FRIEND_CHECK_TARGET}
															</IonText>
														</IonChip>
														{(query.data?.length ?? 0) < FRIEND_CHECK_TARGET && (
															<IonText>
																{t("friend-check.sharing.text", { count: FRIEND_CHECK_TARGET })}
															</IonText>
														)}
													</>
												)}
												<div className="flex w-full flex-col gap-3 *:h-[4.5rem]">
													{query.data.map((friendCheckSubmission) => (
														<FriendCheckFilledCard
															key={friendCheckSubmission.id}
															data={friendCheckSubmission}
															withUnseen
															routerDirection="forward"
															routerLink={Routes.FriendCheckSubmission({
																id: friendCheckSubmission.id,
															})}
														/>
													))}
													{Array.from({
														length: Math.max(0, FRIEND_CHECK_TARGET - (query.data ?? []).length),
													}).map((_, index) => (
														<div
															key={index}
															className="border-brown-300 rounded-2xl border-2 border-dashed px-4 py-3"
														/>
													))}
												</div>
											</div>
										)}
									</div>
									{!!query.data && query.data.length === 0 && !!link && (
										<div className="flex w-full flex-col gap-2">
											<Button
												data-attr="page.app.friend-check.action.copy"
												fill="clear"
												color="secondary"
												className="bg-brown-100! rounded-2xl text-lg"
												expand="block"
												onClick={() => copyUrl()}
											>
												<IonText data-mask-text className="w-full truncate text-start leading-7">
													{link}
												</IonText>
												<IonIcon slot="end" icon={clipboardDocumentIcon} />
											</Button>
											{!!queryCanShare.data && (
												<Button
													data-attr="page.app.friend-check.action.share"
													className="text-lg font-bold"
													expand="block"
													onClick={() => shareSheet()}
												>
													{t("friend-check.share-link")}
												</Button>
											)}
										</div>
									)}
								</div>

								{!!query.data &&
									query.data.length > 0 &&
									!!link &&
									(myProgression.friend_check === "NOT_STARTED" ||
										myProgression.friend_check === "STARTED") && (
										<div className="border-brown-300 flex flex-col gap-4 rounded-2xl border-2 border-solid p-4">
											<div className="text-brown-600 flex flex-col items-center text-center">
												<IonText className="font-bold">
													{t("friend-check.start-sharing.again")}
												</IonText>
												<IonText>
													{t("friend-check.start-sharing.text")}{" "}
													{t("friend-check.sharing.text", { count: FRIEND_CHECK_TARGET })}
												</IonText>
											</div>

											<div className="flex w-full flex-col gap-2">
												<Button
													data-attr="page.app.friend-check.action.copy"
													fill="clear"
													color="secondary"
													className="bg-brown-100! rounded-2xl text-lg"
													expand="block"
													onClick={() => copyUrl()}
												>
													<IonText data-mask-text className="w-full truncate text-start leading-7">
														{link}
													</IonText>
													<IonIcon slot="end" icon={clipboardDocumentIcon} />
												</Button>
												{!!queryCanShare.data && (
													<Button
														data-attr="page.app.friend-check.action.share"
														className="text-lg font-bold"
														expand="block"
														onClick={() => shareSheet()}
													>
														{t("friend-check.share-link")}
													</Button>
												)}
											</div>
										</div>
									)}
							</div>
						</div>
					</Content>
				</>
			)}
		</IonPage>
	);
}
