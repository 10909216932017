import {
	IonFab,
	IonFabButton,
	IonHeader,
	IonIcon,
	IonRefresher,
	IonRefresherContent,
	useIonRouter,
	useIonViewWillEnter,
} from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Image_nl from "@/assets/nl/perfect-day-in-progress@2x.png";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { backendService } from "@/lib/backend";
import { plusIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { PerfectDaySceneCard } from "./Card";
import { PerfectDayHeader } from "./OverviewHeader";
import { PerfectDaySceneReorderContent } from "./Reorder/Content";

export const PerfectDaySceneOverviewContent = () => {
	const presentModal = useSelectorModalStore.use.present();
	const [isReorderMode, setIsReorderMode] = useState(false);
	const { t, i18n } = useTranslation();

	const queryPerfectDayScenes = useQuery({
		queryKey: ["perfectDay", "scenes"],
		queryFn: async () => {
			const response = await backendService.perfectDay.perfectDayControllerGetScenes();
			return response.data;
		},
	});

	useIonViewWillEnter(() => {
		queryPerfectDayScenes.refetch();
	});

	const router = useIonRouter();

	return (
		<>
			{!isReorderMode ? (
				<>
					<IonHeader>
						<PerfectDayHeader setIsReorderMode={setIsReorderMode} />
					</IonHeader>
					<Content className="part-scroll">
						<IonRefresher
							slot="fixed"
							onIonRefresh={(event) =>
								queryPerfectDayScenes.refetch().then(() => event.detail.complete())
							}
						>
							<IonRefresherContent />
						</IonRefresher>

						<IonHeader collapse="condense" className="-mt-(--padding-top)">
							<PerfectDayHeader isCondensed setIsReorderMode={setIsReorderMode} />
						</IonHeader>

						<div className="flex flex-col gap-4">
							{queryPerfectDayScenes.data?.map((scene) => (
								<PerfectDaySceneCard
									key={scene.id}
									className="flex flex-col gap-4 p-0"
									onClick={() => router.push(Routes.PerfectDayDetails({ id: scene.id }))}
								>
									<div className="relative aspect-16/9 max-h-60">
										<img
											src={{ nl: scene.imageUrl || Image_nl }[i18n.language]}
											alt={t("perfect-day.details.image-alt")}
											className="absolute inset-0 h-full w-full rounded-2xl object-cover"
										/>
									</div>
									<div className="absolute inset-x-0 bottom-0 flex flex-col gap-2 bg-linear-to-t from-black/80 to-transparent px-4 pt-6 pb-4">
										<strong className="text-brown-100 text-2xl font-bold">{scene.name}</strong>
									</div>
								</PerfectDaySceneCard>
							))}
						</div>
						<Button
							data-attr="page.perfect-day.create"
							className="ion-bg-brown-100 ion-text-brown-700 !ion-rounded-2xl !ion-px-7 !ion-py-3 part-native:text-lg part-native:font-bold"
							onClick={() => presentModal("perfectDayCreate")}
						>
							{t("perfect-day.create")}
						</Button>

						<IonFab slot="fixed" vertical="bottom" horizontal="end">
							<IonFabButton onClick={() => presentModal("perfectDayCreate")}>
								<IonIcon icon={plusIcon} className="ml-[0.04em] text-2xl" />
							</IonFabButton>
						</IonFab>
					</Content>
				</>
			) : (
				<PerfectDaySceneReorderContent
					sceneData={queryPerfectDayScenes.data || []}
					onCompleted={() => {
						queryPerfectDayScenes.refetch();
						setIsReorderMode(false);
					}}
				/>
			)}
		</>
	);
};
