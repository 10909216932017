import { IonModal, IonPage, IonText, useIonRouter } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { AuthAvatar } from "@/components/Auth/Avatar";
import { AuthContent } from "@/components/Auth/Content";
import { AuthHeader } from "@/components/Auth/Header";
import { AuthPageHeaderTitle } from "@/components/Auth/PageHeaderTitle";
import { AuthSubmitButton } from "@/components/Auth/SubmitButton";
import { FormInput } from "@/components/Form/Input";
import { Content } from "@/components/Global/Content";
import { fadeAnimation } from "@/components/Modal/animations";
import { backendService } from "@/lib/backend";
import { type UpdateUserDto } from "@/lib/backend/api";
import { useExtractedMutation } from "@/lib/backend/utils/hooks";
import { Camera } from "@/lib/capacitor/Camera";
import { Haptics } from "@/lib/capacitor/Haptics";
import { useMe } from "@/lib/query/functions/user/me";
import { Routes } from "@/lib/router";
import { Sentry } from "@/lib/sentry";
import { useSelectorModalStore } from "@/lib/store/modal";
import { logger } from "@/logger";

export function AuthSignUpProfile() {
	const posthog = usePostHog();
	const { t } = useTranslation();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const router = useIonRouter();
	const queryClient = useQueryClient();
	const presentModal = useSelectorModalStore.use.present();

	const [me] = useMe();

	const mutationSelfUpdate = useExtractedMutation({
		mutationKey: ["auth", "sign-up", "profile"],
		mutationFn: backendService.user.usersControllerUpdateMe,
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["user", "me"] }),
	});

	const form = useForm({
		defaultValues: {
			avatarURL: me?.avatarURL ?? "",
			name: me?.name ?? "",
		},
		validators: {
			onSubmit: z.object({
				avatarURL: z.string(),
				name: z
					.string()
					.min(1, t("fields.name.errors.min", { count: 1 }))
					.regex(/^^[^@]+$/, t("fields.name.errors.no-email")),
			}),
		},
		onSubmit: async ({ value }) => {
			const variables: Partial<UpdateUserDto> = {};

			if (me?.avatarURL !== value.avatarURL && !!value.avatarURL) {
				variables.avatar = await fetch(value.avatarURL).then(
					(response) => response.blob() as unknown as File,
				);
			}
			if (value.name) {
				variables.name = value.name;
			}

			return await mutationSelfUpdate.mutateAsync(variables, {
				onSuccess: () => {
					posthog.capture("User Sign Up Profile", { success: true });
					Haptics.impact(Haptics.ImpactStyle.Heavy);
					modalRef.current?.present();
				},
				onError: (error) => {
					Sentry.captureException(error);
					posthog.capture("User Sign Up Profile", { success: false });
					logger.getLogger("Auth").error("User Sign Up Profile", { error });
				},
			});
		},
	});

	return (
		<IonPage>
			<AuthHeader />
			<AuthContent>
				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.handleSubmit();
					}}
					className="flex flex-col gap-6"
				>
					<form.Field
						name="avatarURL"
						children={(field) => (
							<AuthAvatar
								withEdit
								parts={{ img: { src: field.state.value } }}
								onClick={() =>
									Camera.checkPermissions().then(() =>
										Camera.getPhoto({ resultType: Camera.ResultType.DataUrl }).then((photo) =>
											presentModal("appCropProfilePicture", {
												imageUrl: photo.dataUrl,
												onCompleted: field.setValue,
											}),
										),
									)
								}
							/>
						)}
					/>

					<AuthPageHeaderTitle
						parts={{
							title: { children: t("page.auth.sign-up.steps.profile.title") },
							subtitle: { children: t("page.auth.sign-up.steps.profile.subtitle") },
						}}
					/>

					<form.Field
						name="name"
						children={(field) => (
							<FormInput
								data-attr="page.auth.sign-up.profile.name"
								field={field}
								label={t("fields.name.label")}
								required
								autocomplete="given-name"
								placeholder={t("fields.givenName.placeholder")}
							/>
						)}
					/>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<AuthSubmitButton
								data-attr="page.auth.sign-up.profile.action.primary"
								disabled={!canSubmit}
								isSubmitting={isSubmitting}
							>
								{t("page.auth.sign-up.steps.profile.action.primary")}
							</AuthSubmitButton>
						)}
					/>
				</form>
			</AuthContent>

			<IonModal
				ref={modalRef}
				onClick={() => modalRef.current?.dismiss()}
				onDidDismiss={() => router.push(Routes.Dashboard(), "root")}
				enterAnimation={(baseEl) => fadeAnimation(baseEl).direction("normal")}
				leaveAnimation={(baseEl) => fadeAnimation(baseEl).direction("reverse")}
				className="!ion-rounded-none ion-h-full ion-w-full cursor-pointer"
				data-attr="page.auth.sign-up.profile.modal"
			>
				<Content
					fullscreen
					scrollY={false}
					className="part-background:bg-gradient-to-b part-background:from-orange-500 part-background:to-[#FF944C] part-scroll:items-center part-scroll:justify-center part-scroll:gap-3 part-scroll:text-center part-scroll:text-white"
				>
					<IonText className="text-4xl font-bold">
						{t("page.auth.sign-up.steps.done.title")}
					</IonText>
					<IonText className="font-semibold text-gray-100">
						{t("page.auth.sign-up.steps.done.action.primary")}
					</IonText>
				</Content>
			</IonModal>
		</IonPage>
	);
}
