import { IonButton, IonIcon } from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { type PerfectDayMessageImageAttachment } from "@/lib/backend/api";
import { arrowPathIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { informationCircleIcon } from "@/lib/icons/@heroicons/react/24/outline";

interface PerfectDayChatImageAttachmentProps extends PerfectDayMessageImageAttachment {
	onRetryGenerating: () => void;
}

const PerfectDayChatImageAttachment = (props: PerfectDayChatImageAttachmentProps) => {
	const [isRegenerating, setIsRegenerating] = useState(false);
	const { t } = useTranslation();

	return props.status === "SUCCESS" ? (
		<div className="w-full">
			<img
				className="ph-no-capture border-brown-300 w-full rounded-2xl border-2"
				src={props.content}
				alt={t("page.perfect-day.scene.chat.image-alt")}
			/>
		</div>
	) : (
		<div className="border-brown-300 flex h-full w-fit flex-col items-center justify-center rounded-2xl border p-8">
			<div
				className="border-brown-300 flex items-center gap-2 rounded-full border-4 p-2"
				role="alert"
				aria-label={t("page.perfect-day.scene.chat.error.image-loading")}
			>
				<IonIcon icon={informationCircleIcon} size="large" />
			</div>
			<div className="mt-2 font-medium">{t("page.perfect-day.scene.chat.error.image-loading")}</div>
			<IonButton
				className="ion-bg-brown-300 ion-text-brown-700 ion-rounded-lg ion-px-8 mt-4 font-bold"
				disabled={isRegenerating}
				onClick={() => {
					setIsRegenerating(true);
					props.onRetryGenerating();
				}}
			>
				{t("page.perfect-day.scene.chat.error.retry-generating")}
				<IonIcon className="ml-2" icon={arrowPathIcon} size="small" />
			</IonButton>
		</div>
	);
};

export default PerfectDayChatImageAttachment;
