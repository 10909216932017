import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { type JSX, useCallback } from "react";
import { Redirect, type RouteComponentProps } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useShallow } from "zustand/shallow";
import { AppUrlListener } from "@/components/Global/AppUrlListener";
import { NotificationListener } from "@/components/Global/NotificationListener";
import { ModalProvider } from "@/components/Modal/Provider";
import { PostHogPageView } from "@/lib/posthog/components/PageView";
import { queryOptions_myAccess } from "@/lib/query/functions/user/access";
import { Routes } from "@/lib/router";
import { history } from "@/lib/router/history";
import { SentryRoute as Route } from "@/lib/sentry/route";
import { authSelectors, useAuthStore } from "@/lib/store/auth";
import { AppTabs } from "@/pages/app";
import { AuthForgotPassword } from "@/pages/auth/forgot-password";
import { AuthForgotPasswordReset } from "@/pages/auth/forgot-password/reset";
import { AuthForgotPasswordVerify } from "@/pages/auth/forgot-password/verify";
import { AuthOAuthRedirect } from "@/pages/auth/oauth/redirect";
import { AuthSignInMagic } from "@/pages/auth/sign-in/magic";
import { AuthSignInMagicVerify } from "@/pages/auth/sign-in/magic/verify";
import { AuthSignInPassword } from "@/pages/auth/sign-in/password";
import { AuthSignUp } from "@/pages/auth/sign-up";
import { AuthSignUpPassword } from "@/pages/auth/sign-up/password";
import { AuthSignUpProfile } from "@/pages/auth/sign-up/profile";
import { AuthSignUpVerify } from "@/pages/auth/sign-up/verify";
import { FriendCheckSubmission } from "@/pages/friend-check/submission";
import { NoAccess } from "@/pages/no-access";
import { ReflectionDetail } from "@/pages/reflection/detail";
import { ReflectionQuestion } from "@/pages/reflection/question";
import { Settings } from "@/pages/settings";
import { SettingsProfile } from "@/pages/settings/profile";
import { SharedFriendCheckInformation } from "@/pages/share/friend-check/information";
import { SharedFriendCheckQuestions } from "@/pages/share/friend-check/questions";
import { SharedFriendCheckThankYou } from "@/pages/share/friend-check/thank-you";
import { CoreValuesChat } from "./pages/core-values/chat";
import { CoreValuesDetails } from "./pages/core-values/details";
import { PerfectDayChat } from "./pages/perfect-day/chat";
import { PerfectDayDetails } from "./pages/perfect-day/details";
import { SettingsNotifications } from "./pages/settings/notifications";

setupIonicReact({ mode: "ios" });

export function App() {
	const isAuthenticated = useAuthStore(useShallow(authSelectors.isAuthenticated));

	const queryAccess = useQuery({
		...queryOptions_myAccess(),
		enabled: isAuthenticated,
	});
	const hasAccess = queryAccess.data?.hasAccess;

	const requireAccess = useCallback(
		(component: <T extends object>(props: RouteComponentProps<T>) => JSX.Element) =>
			// eslint-disable-next-line react/display-name
			<T extends object>(props: RouteComponentProps<T>) =>
				hasAccess !== false ? (
					component<T>(props)
				) : (
					<Route
						render={(props) => <Redirect to={Routes.NoAccess({ path: props.match.path })} />}
					/>
				),
		[hasAccess],
	);

	const requireAuth = useCallback(
		(
			component: <T extends object>(props: RouteComponentProps<T>) => JSX.Element,
			options?: { required?: boolean; authPath?: string; unauthPath?: string },
		) =>
			// eslint-disable-next-line react/display-name
			<T extends object>(props: RouteComponentProps<T>) =>
				((options?.required ?? true) && isAuthenticated) ||
				(!(options?.required ?? true) && !isAuthenticated) ? (
					component<T>(props)
				) : (
					<Route
						render={() => (
							<Redirect
								to={
									(options?.required ?? true)
										? (options?.unauthPath ?? Routes.AuthSignIn())
										: (options?.authPath ?? Routes.Dashboard())
								}
							/>
						)}
					/>
				),
		[isAuthenticated],
	);

	return (
		<IonApp>
			<IonReactRouter history={history}>
				<ModalProvider />
				<AppUrlListener />
				<NotificationListener />
				<PostHogPageView />

				<IonRouterOutlet id="global">
					<Route exact path={Routes.NoAccess()} component={requireAuth(NoAccess)} />

					{/* App */}
					<Route path={Routes.App()} component={requireAuth(requireAccess(AppTabs))} />

					<Route
						exact
						path={Routes.CoreValuesChat()}
						component={requireAuth(requireAccess(CoreValuesChat))}
					/>
					<Route
						exact
						path={Routes.CoreValuesDetails()}
						component={requireAuth(requireAccess(CoreValuesDetails))}
					/>
					<Route
						exact
						path={Routes.FriendCheckSubmission()}
						component={requireAuth(requireAccess(FriendCheckSubmission))}
					/>
					<Route
						exact
						path={Routes.ReflectionQuestion()}
						component={requireAuth(requireAccess(ReflectionQuestion))}
					/>
					<Route
						exact
						path={Routes.ReflectionDetail()}
						component={requireAuth(requireAccess(ReflectionDetail))}
					/>
					<Route
						exact
						path={Routes.PerfectDayDetails()}
						component={requireAuth(requireAccess(PerfectDayDetails))}
					/>
					<Route
						exact
						path={Routes.PerfectDayChat()}
						component={requireAuth(requireAccess(PerfectDayChat))}
					/>

					{/* Settings */}
					<Route exact path={Routes.Settings()} component={requireAuth(requireAccess(Settings))} />
					<Route
						exact
						path={Routes.Profile()}
						component={requireAuth(requireAccess(SettingsProfile))}
					/>
					<Route
						exact
						path={Routes.Notifications()}
						component={requireAuth(requireAccess(SettingsNotifications))}
					/>

					{/* Shared Friend Check */}
					<Route
						exact
						path={Routes.SharedFriendCheckQuestions()}
						component={SharedFriendCheckQuestions}
					/>
					<Route
						exact
						path={Routes.SharedFriendCheckThankYou()}
						component={SharedFriendCheckThankYou}
					/>
					<Route exact path={Routes.SharedFriendCheck()} component={SharedFriendCheckInformation} />

					{/* Authentication: Sign in */}
					<Route
						exact
						path={Routes.AuthSignInMagic()}
						component={requireAuth(AuthSignInMagic, { required: false })}
					/>
					<Route
						exact
						path={Routes.AuthSignInMagicVerify()}
						component={requireAuth(AuthSignInMagicVerify, { required: false })}
					/>
					<Route
						exact
						path={Routes.AuthSignInPassword()}
						component={requireAuth(AuthSignInPassword, { required: false })}
					/>
					<Redirect exact from={Routes.AuthSignIn()} to={Routes.AuthSignInMagic()} />

					{/* Authentication: Sign up */}
					<Route
						exact
						path={Routes.AuthSignUp()}
						component={requireAuth(AuthSignUp, { required: false })}
					/>
					<Route
						exact
						path={Routes.AuthSignUpVerify()}
						component={requireAuth(AuthSignUpVerify, { required: false })}
					/>
					<Route
						exact
						path={Routes.AuthSignUpPassword()}
						component={requireAuth(AuthSignUpPassword, {
							required: false,
							authPath: Routes.AuthSignUpProfile(),
						})}
					/>
					<Route
						exact
						path={Routes.AuthSignUpProfile()}
						component={requireAuth(AuthSignUpProfile)}
					/>

					{/* Authentication: Forgot password */}
					<Route
						exact
						path={Routes.AuthForgotPassword()}
						component={requireAuth(AuthForgotPassword, { required: false })}
					/>
					<Route
						exact
						path={Routes.AuthForgotPasswordVerify()}
						component={requireAuth(AuthForgotPasswordVerify, { required: false })}
					/>
					<Route
						exact
						path={Routes.AuthForgotPasswordReset()}
						component={requireAuth(AuthForgotPasswordReset, { required: false })}
					/>

					{/* Authentiation: OAuth */}
					<Route
						exact
						path={Routes.AuthOAuthRedirect()}
						component={requireAuth(AuthOAuthRedirect, { required: false })}
					/>

					<Redirect
						exact
						from="/"
						to={isAuthenticated ? Routes.Dashboard() : Routes.AuthSignIn()}
					/>

					{/* Authentication: Fallback */}
					<Redirect exact from={Routes.Auth()} to={Routes.AuthSignIn()} />

					{/* Fallback */}
					<Redirect to={isAuthenticated ? Routes.Dashboard() : Routes.AuthSignIn()} />
				</IonRouterOutlet>
			</IonReactRouter>
		</IonApp>
	);
}
