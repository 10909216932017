import { IonBackButton, IonButtons, IonHeader, IonIcon, IonPage, IonTitle } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { FormInput } from "@/components/Form/Input";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { useExtractedMutation } from "@/lib/backend/utils/hooks";
import { Toast } from "@/lib/capacitor/Toast";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { pencilSquareIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { useMe } from "@/lib/query/functions/user/me";
import { Routes } from "@/lib/router";

export function SettingsProfile() {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const PreferredHaptics = usePreferredHaptics();

	const [me] = useMe();

	const mutationSelfUpdate = useExtractedMutation({
		mutationKey: ["auth", "sign-up", "profile"],
		mutationFn: backendService.user.usersControllerUpdateMe,
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ["user", "me"] }),
	});

	const form = useForm({
		defaultValues: {
			name: me?.name ?? "",
		},
		validators: {
			onSubmit: z.object({
				name: z
					.string()
					.min(1, t("fields.name.errors.min", { count: 1 }))
					.regex(/^^[^@]+$/, t("fields.name.errors.no-email")),
			}),
		},
		onSubmit: ({ value }) =>
			mutationSelfUpdate.mutateAsync(value, {
				onSuccess: () => Toast.show({ text: t("settings.profile.message.success") }),
			}),
	});

	return (
		<IonPage>
			<IonHeader translucent>
				<Toolbar className="plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
					<IonButtons slot="secondary" onClick={() => PreferredHaptics.impact()}>
						<IonBackButton
							data-touch-target
							data-attr="page.settings.profile.back"
							defaultHref={Routes.Settings()}
							color="light"
							className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
							icon={arrowLeftIcon}
							text=""
						/>
					</IonButtons>

					<IonTitle>{t("settings.profile.name")}</IonTitle>
				</Toolbar>
			</IonHeader>
			<Content fullscreen>
				<IonHeader collapse="condense">
					<Toolbar className="!ion-px-0">
						<IonTitle size="large" className="text-brown-700 px-0 text-2xl">
							{t("settings.profile.name")}
						</IonTitle>
					</Toolbar>
				</IonHeader>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.handleSubmit();
					}}
					className="flex flex-1 flex-col gap-2"
				>
					<form.Field
						name="name"
						children={(field) => (
							<FormInput
								data-attr="page.settings.profile.name"
								field={field}
								label={t("fields.name.label")}
								required
								autocomplete="given-name"
								placeholder={t("fields.givenName.placeholder")}
							>
								<IonIcon slot="end" icon={pencilSquareIcon} aria-hidden="true" />
							</FormInput>
						)}
					/>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<Button
								type="submit"
								data-attr="page.settings.profile.submit"
								disabled={!canSubmit}
								expand="block"
								className="ion-rounded-2xl part-native:text-lg part-native:font-bold m-0 mt-auto h-12"
							>
								{isSubmitting ? t("loading") : t("save")}
							</Button>
						)}
					/>
				</form>
			</Content>
		</IonPage>
	);
}
