import { useIonViewDidEnter } from "@ionic/react";
import { useEffect, useRef } from "react";
import { useChat } from "@/components/Chat/store";
import { ChatMessage } from "./Message";

interface ChatContentProps {
	customOnTypingBubble?: React.ReactNode;
	scrollDelayInMilliseconds?: number;
}

const DEFAULT_SCROLL_DELAY = 0;

export function ChatContent({
	customOnTypingBubble,
	children,
	scrollDelayInMilliseconds: scrollDelay = DEFAULT_SCROLL_DELAY,
}: React.PropsWithChildren<ChatContentProps>) {
	const chat = useChat();
	const messagesEnd = useRef<HTMLDivElement>(null);

	useIonViewDidEnter(() => messagesEnd.current?.scrollIntoView());
	const scrollDelayInMilliseconds =
		scrollDelay > DEFAULT_SCROLL_DELAY ? scrollDelay : DEFAULT_SCROLL_DELAY;

	useEffect(() => {
		// This optional delay ensures all content (such as images) is rendered before scrolling to the bottom
		if (scrollDelayInMilliseconds > DEFAULT_SCROLL_DELAY) {
			setTimeout(() => {
				messagesEnd.current?.scrollIntoView();
			}, scrollDelayInMilliseconds);
		} else {
			messagesEnd.current?.scrollIntoView();
		}
	}, [children, customOnTypingBubble]);

	return (
		<div className="flex flex-col gap-4">
			{children}

			{chat.isResponding && chat.streamUserMessage && (
				<ChatMessage sender="user">{chat.streamUserMessage}</ChatMessage>
			)}

			{!customOnTypingBubble && chat.isResponding && (
				<ChatMessage sender="bot">{chat.streamAssistantMessage}</ChatMessage>
			)}

			{customOnTypingBubble}

			<div ref={messagesEnd} />
		</div>
	);
}
