import type {
	useFeatureFlagEnabled,
	useFeatureFlagPayload,
	useFeatureFlagVariantKey,
} from "posthog-js/react";

export const FEATURE_FLAG = {
	DEVELOPMENT: "development", // owner: @MaxAltena
	COREVALUE_REFINE_ENABLED: "corevalue-refine-enabled", // owner: @IlayHamer
	PERFECT_DAY_MENU_ITEM_ENABLED: "perfect-day-menu-item-enabled", // owner @Jess
} as const;

export type FeatureFlagKey = (typeof FEATURE_FLAG)[keyof typeof FEATURE_FLAG];

export const FEATURE_FLAG_VALUE = {
	[FEATURE_FLAG.DEVELOPMENT]: "boolean",
	[FEATURE_FLAG.COREVALUE_REFINE_ENABLED]: "boolean",
	[FEATURE_FLAG.PERFECT_DAY_MENU_ITEM_ENABLED]: "boolean",
} satisfies Record<FeatureFlagKey, "boolean" | "variant" | "payload">;

export type FeatureFlagValue = {
	[K in keyof typeof FEATURE_FLAG_VALUE]: (typeof FEATURE_FLAG_VALUE)[K] extends "boolean"
		? ReturnType<typeof useFeatureFlagEnabled>
		: (typeof FEATURE_FLAG_VALUE)[K] extends "variant"
			? ReturnType<typeof useFeatureFlagVariantKey>
			: (typeof FEATURE_FLAG_VALUE)[K] extends "payload"
				? ReturnType<typeof useFeatureFlagPayload>
				: never;
};

export const FEATURE_FLAG_DEFAULT_VALUE = {
	[FEATURE_FLAG.DEVELOPMENT]: false,
	[FEATURE_FLAG.COREVALUE_REFINE_ENABLED]: false,
	[FEATURE_FLAG.PERFECT_DAY_MENU_ITEM_ENABLED]: false,
} satisfies FeatureFlagValue;
