import { IonButton, IonIcon } from "@ionic/react";
import { arrowUpDownIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { cx } from "@/lib/style/cva.config";

export function PerfectDaySceneReorderButton(props: React.ComponentProps<typeof IonButton>) {
	return (
		<IonButton
			data-attr="page.perfect-day.details.back"
			routerDirection="back"
			color="light"
			{...props}
			className={cx(
				"part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0",
				props.className,
			)}
		>
			<IonIcon icon={arrowUpDownIcon} />
		</IonButton>
	);
}
