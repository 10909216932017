import {
	IonButton,
	IonButtons,
	IonFooter,
	IonHeader,
	IonIcon,
	IonPage,
	IonTitle,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { ChatContent } from "@/components/Chat/Content";
import { ChatFooter } from "@/components/Chat/Footer";
import { ChatInput } from "@/components/Chat/Input";
import { ChatMessage } from "@/components/Chat/Message";
import { ChatStoreContext } from "@/components/Chat/store";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { PerfectDayChatGeneratingImageStatus } from "@/components/PerfectDay/Chat/GeneratingImageStatus";
import PerfectDayChatImageAttachment from "@/components/PerfectDay/Chat/ImageAttachment";
import { usePerfectDayChatStore } from "@/components/PerfectDay/Chat/store";
import { PerfectDayNotFound } from "@/components/PerfectDay/NotFound";
import { backendService } from "@/lib/backend";
import { Toast } from "@/lib/capacitor/Toast";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { Routes } from "@/lib/router";
import { logger } from "@/logger";

export function PerfectDayChat() {
	const params = Routes.PerfectDayChat.useParams();
	const { t } = useTranslation();
	const sceneId = params.id || "";

	const {
		queryPerfectDay,
		streamStatus,
		chat,
		chatStore,
		mutationStream,
		perfectDaySceneWithChat,
	} = usePerfectDayChatStore(sceneId);

	if (!queryPerfectDay.isLoading && queryPerfectDay.isError) {
		logger.getLogger("PerfectDayChat").error(t("page.perfect-day.scene.chat.error.failed-to-load"));
		return <PerfectDayNotFound />;
	}

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
					<IonTitle className="text-md ion-text-brown-500 font-bold">
						{perfectDaySceneWithChat?.name}
					</IonTitle>
					<IonButtons collapse slot="secondary">
						<IonButton
							data-attr="page.perfect-day.chat.back"
							routerLink={Routes.PerfectDay()}
							routerDirection="back"
							color="light"
							className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
						>
							<IonIcon icon={arrowLeftIcon} />
						</IonButton>
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<ChatStoreContext.Provider value={chatStore}>
				<Content className="part-scroll:gap-8">
					<ChatContent
						customOnTypingBubble={
							streamStatus === "GENERATING_IMAGE" && <PerfectDayChatGeneratingImageStatus />
						}
						scrollDelayInMilliseconds={100}
					>
						{[...(perfectDaySceneWithChat?.chat.messages || [])]
							.filter((message) => message.content || message.attachment)
							.map((message, index) => (
								<ChatMessage
									key={`${message.id || message.role}-${index}`}
									sender={message.role === "USER" ? "user" : "bot"}
									className={message.attachment ? "bg-transparent" : ""}
								>
									{message.attachment ? (
										<PerfectDayChatImageAttachment
											{...message.attachment}
											onRetryGenerating={() => {
												chat.setResponding(true);
												mutationStream.mutate();
											}}
										/>
									) : (
										message.content
									)}
								</ChatMessage>
							))}
					</ChatContent>
				</Content>
				<IonFooter>
					<ChatFooter>
						{(perfectDaySceneWithChat?.chat.status === "REFLECTION" ||
							perfectDaySceneWithChat?.chat.status === "IMAGE_GENERATION") && (
							<ChatInput
								focusOnLoad
								onSubmit={async (content) => {
									try {
										await backendService.perfectDay.perfectDayControllerSendUserMessageInChat(
											perfectDaySceneWithChat.chat.id,
											{
												content: content,
											},
										);

										chat.setResponding(true);
										mutationStream.mutate();

										return true;
									} catch (error) {
										logger.error("[PerfectDayChat] Failed to send message", error);
										Toast.show({ text: t("modal.perfect-day.scene.error.send-message-failed") });
										return false;
									}
								}}
							/>
						)}
					</ChatFooter>
				</IonFooter>
			</ChatStoreContext.Provider>
		</IonPage>
	);
}
