import { IonCheckbox } from "@ionic/react";
import { cx } from "@/lib/style/cva.config";

export function MultipleChoiceAnswer({
	selected,
	...props
}: { selected: string[] } & React.ComponentPropsWithoutRef<"div">) {
	return (
		<div {...props} className={cx("flex flex-col gap-2", props.className)}>
			{selected.map((choice, index) => (
				<IonCheckbox
					key={index}
					justify="start"
					labelPlacement="end"
					checked
					disabled
					data-mask-text
					className="bg-brown-200 text-brown-700 part-[label]:overflow-auto part-[label]:whitespace-normal rounded-xl p-3 text-base font-normal opacity-100"
				>
					{choice}
				</IonCheckbox>
			))}
		</div>
	);
}
