import { IonButtons, IonFooter, IonIcon, IonModal, IonText } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/Global/Button";
import { Toolbar } from "@/components/Global/Toolbar";
import { arrowRightIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { useMyData } from "@/lib/query/functions/user/data";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalPerfectDayExplainer() {
	const { t } = useTranslation();

	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.perfectDayExplainer();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const [, { mutation }] = useMyData();

	const [myData] = useMyData();
	const isPerfectDayExplainerSeen = myData.modal.perfectDayExplainer.seen;

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => {
				present("perfectDayExplainer");
			}}
			onWillDismiss={() => {
				dismiss("perfectDayExplainer");
			}}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="ion-h-auto"
		>
			<div className="ion-padding flex flex-col gap-6 pt-12">
				<IonText className="text-brown-700 text-2xl font-bold">
					{t("modal.perfect-day.explainer.title")}
				</IonText>

				<ol className="flex flex-col gap-8">
					{[
						{
							title: t("modal.perfect-day.explainer.point-1.title"),
							text: t("modal.perfect-day.explainer.point-1.text"),
						},
						{
							title: t("modal.perfect-day.explainer.point-2.title"),
							text: t("modal.perfect-day.explainer.point-2.text"),
						},
						{
							title: t("modal.perfect-day.explainer.point-3.title"),
							text: t("modal.perfect-day.explainer.point-3.text"),
						},
					].map((value, index) => (
						<li key={index + 1} className="flex items-start gap-3">
							<IonText className="text-brown-500 grid size-8 shrink-0 place-content-center rounded-full bg-white text-center text-lg font-bold select-none">
								{index + 1}
							</IonText>
							<div className="flex flex-col">
								<IonText className="text-brown-600 text-lg font-bold">{value.title}</IonText>
								<IonText className="text-brown-700 text-base font-normal">{value.text}</IonText>
							</div>
						</li>
					))}
				</ol>
			</div>

			<IonFooter className="ion-no-border">
				<Toolbar className="ion-bg-brown-200 ion-p-4">
					<IonButtons slot="primary">
						<Button
							data-attr="modal.perfect-day.explainer.next"
							className="ion-bg-brown-100 ion-text-brown-700 !ion-rounded-2xl !ion-px-7 !ion-py-3 part-native:text-lg part-native:font-bold"
							onClick={async () => {
								if (!isPerfectDayExplainerSeen) {
									mutation.mutate((draft) => void (draft.modal.perfectDayExplainer.seen = true));
									modalRef.current?.dismiss({ onCompleted: null });
									modal.onCompleted?.();
								} else {
									modalRef.current?.dismiss({ onCompleted: null });
								}
							}}
						>
							{isPerfectDayExplainerSeen ? (
								t("modal.perfect-day.explainer.close")
							) : (
								<>
									<IonIcon slot="end" icon={arrowRightIcon} className="size-6" />
									{t("modal.perfect-day.explainer.next")}
								</>
							)}
						</Button>
					</IonButtons>
				</Toolbar>
			</IonFooter>
		</IonModal>
	);
}
