import { useMemo } from "react";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { type Producer, produce } from "immer";
import { z } from "zod";
import { useShallow } from "zustand/shallow";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";
import { useExtractedMutation } from "@/lib/backend/utils/hooks";
import { authSelectors, useAuthStore } from "@/lib/store/auth";

export const queryOptions_myData = () =>
	queryOptions({
		queryKey: ["user", "me", "data"] as const,
		queryFn: (context) =>
			extractionWrapper(backendService.user.usersControllerGetAppData({ signal: context.signal })),
	});

export const MyDataSchema = z
	.object({
		modal: z
			.object({
				appExplainer: z.object({ seen: z.coerce.boolean().default(false) }).default({}),
				reflectionExplainerGeneral: z
					.object({ seen: z.coerce.boolean().default(false) })
					.default({}),
				friendCheckExplainer: z.object({ seen: z.coerce.boolean().default(false) }).default({}),
				friendCheckCompleted: z.object({ seen: z.coerce.boolean().default(false) }).default({}),
				perfectDayExplainer: z.object({ seen: z.coerce.boolean().default(false) }).default({}),
				coreValuesCompileExplainer: z
					.object({ seen: z.coerce.boolean().default(false) })
					.default({}),
				chosenCoreValuesPersonalizeExplainer: z
					.object({ seen: z.coerce.boolean().default(false) })
					.default({}),
				coreValuesPersonalizeExplainer: z
					.object({ seen: z.coerce.boolean().default(false) })
					.default({}),
			})
			.default({}),
		reflection: z
			.object({
				questions: z
					.record(
						z
							.object({
								content: z.string(),
								options: z.array(z.string()),
							})
							.optional(),
					)
					.default({}),
			})
			.default({}),
		coreValues: z
			.object({
				savedFriendCheck: z.array(z.string()).default([]),
			})
			.default({}),
		ai: z
			.object({
				model: z.string().optional(),
			})
			.default({}),
	})
	.default({});

export function useMyData(options?: Partial<ReturnType<typeof queryOptions_myData>>) {
	const isAuthenticated = useAuthStore(useShallow(authSelectors.isAuthenticated));

	const query = useQuery({
		...queryOptions_myData(),
		enabled: isAuthenticated,
		...options,
	});
	const parseResult = MyDataSchema.safeParse(query.data);
	let data: z.infer<typeof MyDataSchema> = MyDataSchema.parse({});

	if (parseResult.success) {
		data = parseResult.data;
	}

	const mutation = useExtractedMutation({
		mutationKey: queryOptions_myData().queryKey,
		mutationFn: (variables: Producer<z.infer<typeof MyDataSchema>>) =>
			backendService.user.usersControllerUpdateAppData(
				produce(data as unknown as Record<string, string | number | boolean>, variables),
			),
		onSuccess: () => query.refetch(),
	});

	return useMemo(() => [data, { query, mutation }] as const, [data]);
}
