import { IonHeader, IonIcon, IonModal, IonRange, IonText } from "@ionic/react";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useTranslation } from "react-i18next";
import { useMeasure } from "react-use";
import { usePostHog } from "posthog-js/react";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { xMarkIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalCropProfilePicture() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.appCropProfilePicture();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();

	const [containerRef, { height, width }] = useMeasure<HTMLDivElement>();
	const editorRef = useRef<AvatarEditor>(null);

	const [scale, setScale] = useState(1);
	const border = 42;
	const minScale = 1;
	const maxScale = 5;

	const calculateSize = () => {
		if (height == 0 || width == 0) {
			return 100;
		}
		if (width < height) {
			return width - border * 2;
		} else {
			return height - border * 2;
		}
	};

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => {
				present("appCropProfilePicture");
				posthog.capture("Modal App CropProfilePicture Present");
			}}
			onWillDismiss={() => {
				dismiss("appCropProfilePicture");
				posthog.capture("Modal App CropProfilePicture Dismiss");
			}}
			className="!ion-rounded-none ion-h-full ion-w-full"
		>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-py-2">
					<Button
						slot="secondary"
						data-attr="modal.app.crop-profile-picture.close"
						className="ion-bg-a-brown-300 ion-bg-f-brown-300 ion-bg-transparent ion-border-brown-300 ion-text-a-brown-600 ion-text-brown-600 ion-text-f-brown-600 ion-text-h-brown-600 ion-rounded-full ion-p-2 ion-border min-h-0"
						onClick={() => modalRef.current?.dismiss()}
					>
						<IonIcon slot="icon-only" icon={xMarkIcon} className="size-5" />
					</Button>
				</Toolbar>
			</IonHeader>
			<Content fullscreen inModal>
				<div className="text-brown-700 flex h-full flex-col gap-2">
					<IonText className="text-4xl font-bold">{t("modal.app.crop.title")}</IonText>
					<div ref={containerRef} className="flex grow justify-center">
						<AvatarEditor
							ref={editorRef}
							data-mask-text
							data-attr="modal.app.crop-profile-picture.editor"
							className="rounded-2xl"
							image={modal.imageUrl}
							width={calculateSize()}
							height={calculateSize()}
							border={border}
							scale={scale}
							rotate={0}
							borderRadius={height}
						/>
					</div>
					<IonRange
						data-attr="modal.app.crop-profile-picture.scale"
						onIonInput={({ detail }) =>
							setScale(((detail.value as number) / 100) * maxScale + minScale)
						}
						labelPlacement="start"
						label={t("modal.app.crop.size")}
					/>
					<Button
						data-attr="modal.app.crop-profile-picture.submit"
						expand="block"
						className="ion-rounded-2xl part-native:text-lg part-native:font-bold m-0 mt-auto h-12"
						onClick={async () => {
							if (!editorRef.current || !modal.onCompleted) {
								return;
							}
							const canvas = editorRef.current.getImage().toDataURL();
							const res = await fetch(canvas);
							const blob = await res.blob();
							const imageURL = window.URL.createObjectURL(blob);
							modal.onCompleted(imageURL);
							posthog.capture("Modal App CropProfilePicture Finished");
							modalRef.current?.dismiss();
						}}
					>
						{t("modal.app.crop.finished")}
					</Button>
				</div>
			</Content>
		</IonModal>
	);
}
