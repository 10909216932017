import { IonFooter, IonModal, IonText } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/Global/Button";
import { Toolbar } from "@/components/Global/Toolbar";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalPerfectDayConfirmDelete() {
	const { t } = useTranslation();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.perfectDayConfirmDelete();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => {
				present("perfectDayConfirmDelete");
			}}
			onWillDismiss={() => {
				dismiss("perfectDayConfirmDelete", { onConfirmed: null });
			}}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="ion-h-auto"
			aria-labelledby="delete-modal-title"
			aria-describedby="delete-modal-description"
		>
			<div className="ion-padding flex flex-col gap-2 pt-12">
				<IonText className="text-brown-700 text-2xl font-bold" id="delete-modal-title">
					{t("modal.perfect-day.delete.title")}
				</IonText>

				<IonText className="text-brown-700 text-lg" id="delete-modal-description">
					{t("modal.perfect-day.delete.description")}
				</IonText>
			</div>

			<IonFooter className="ion-no-border">
				<Toolbar className="ion-bg-brown-200 ion-p-4">
					<div className="flex flex-col gap-2">
						<Button
							data-attr="modal.perfect-day.delete.confirm"
							expand="block"
							fill="solid"
							className="!ion-rounded-2xl text-lg font-bold"
							onClick={() => {
								modalRef.current?.dismiss();
								modal.onConfirmed?.();
							}}
						>
							{t("modal.perfect-day.delete.confirm")}
						</Button>
						<Button
							data-attr="modal.perfect-day.delete.cancel"
							expand="block"
							fill="clear"
							className="ion-bg-a-brown-100 ion-bg-brown-100 ion-bg-f-brown-100 ion-bg-h-brown-100 ion-text-brown-700 !ion-rounded-2xl text-lg font-bold"
							onClick={() => {
								modalRef.current?.dismiss();
							}}
						>
							{t("modal.perfect-day.delete.cancel")}
						</Button>
					</div>
				</Toolbar>
			</IonFooter>
		</IonModal>
	);
}
