import { createContext, useContext } from "react";
import { FEATURE_FLAG_DEFAULT_VALUE, type FeatureFlagValue } from "@/lib/posthog/feature-flags";

export const FeatureFlagContext = createContext<FeatureFlagValue>(FEATURE_FLAG_DEFAULT_VALUE);

export function useFeatureFlagsContext() {
	const context = useContext(FeatureFlagContext);

	if (!context) {
		throw new Error("useFeatureFlagsContext must be used within a FeatureFlagContext");
	}

	return context;
}
