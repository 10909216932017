import {
	IonHeader,
	IonIcon,
	IonModal,
	IonSpinner,
	IonText,
	IonTitle,
	useIonRouter,
} from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { z } from "zod";
import { FormInput } from "@/components/Form/Input";
import { FormTextarea } from "@/components/Form/Textarea";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { xMarkIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";

const FORM_CONSTRAINTS = {
	NAME: {
		MIN_LENGTH: 4,
		MAX_LENGTH: 75,
	},
	DESCRIPTION: {
		MIN_LENGTH: 4,
		MAX_LENGTH: 255,
	},
} as const;

export function ModalPerfectDayCreate() {
	const { t } = useTranslation();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const router = useIonRouter();
	const modal = useSelectorModalStore.use.perfectDayCreate();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const firstInputRef = useRef<HTMLIonTextareaElement>(null);

	const form = useForm({
		defaultValues: { name: "", description: "" },
		validators: {
			onSubmit: z.object({
				name: z
					.string()
					.min(
						FORM_CONSTRAINTS.NAME.MIN_LENGTH,
						t("fields.answer.errors.min", { count: FORM_CONSTRAINTS.NAME.MIN_LENGTH }),
					)
					.max(
						FORM_CONSTRAINTS.NAME.MAX_LENGTH,
						t("fields.answer.errors.max", { count: FORM_CONSTRAINTS.NAME.MAX_LENGTH }),
					),
				description: z
					.string()
					.min(
						FORM_CONSTRAINTS.DESCRIPTION.MIN_LENGTH,
						t("fields.answer.errors.min", { count: FORM_CONSTRAINTS.DESCRIPTION.MIN_LENGTH }),
					)
					.max(
						FORM_CONSTRAINTS.DESCRIPTION.MAX_LENGTH,
						t("fields.answer.errors.max", { count: FORM_CONSTRAINTS.DESCRIPTION.MAX_LENGTH }),
					),
			}),
		},
		onSubmit: async ({ value, formApi }) => {
			try {
				const response = await backendService.perfectDay.perfectDayControllerCreateScene({
					name: value.name,
					description: value.description,
				});
				router.push(Routes.PerfectDayChat({ id: response.data.id }));
				modalRef.current?.dismiss();
			} catch (error: any) {
				if (error.error.statusCode === 400) {
					formApi.setFieldMeta("name", (current) => ({
						...current,
						errorMap: {
							onSubmit: t("modal.perfect-day.create.fields.name.errors.already-exists"),
						},
					}));
				} else {
					formApi.setFieldMeta("name", (current) => ({
						...current,
						errorMap: {
							onSubmit: t("modal.perfect-day.create.error.general"),
						},
					}));
				}
			}
		},
	});

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => {
				present("perfectDayCreate");
			}}
			onDidPresent={() => firstInputRef.current?.setFocus()}
			onWillDismiss={() => {
				dismiss("perfectDayCreate", { onCompleted: null });
				form.reset();
			}}
		>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-py-2">
					<IonTitle className="ion-text-brown-700 text-lg font-bold">
						{t("modal.perfect-day.create.title")}
					</IonTitle>
					<Button
						slot="primary"
						data-attr="modal.perfect-day.create.close"
						className="ion-bg-brown-300 ion-text-brown-700 ion-rounded-full ion-p-2.5 min-h-0"
						onClick={() => modalRef.current?.dismiss()}
					>
						<IonIcon slot="icon-only" icon={xMarkIcon} className="size-5" />
					</Button>
				</Toolbar>
			</IonHeader>
			<Content inModal className="part-scroll:gap-0 part-scroll:p-4 part-scroll:pt-2">
				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.handleSubmit();
					}}
				>
					<div className="flex flex-col pt-2">
						<IonText className="text-brown-700 font-semibold">
							{t("modal.perfect-day.create.fields.name.label")}
						</IonText>
						<form.Field
							name="name"
							children={(field) => (
								<FormInput
									data-attr="modal.perfect-day.create.name"
									field={field}
									inputMode="text"
									autocapitalize="sentences"
									className="text-brown-700 [&_textarea]:-mx-4! [&_textarea]:box-content! [&_textarea]:px-4!"
								/>
							)}
						/>
					</div>

					<div className="mt-4 flex flex-col pt-2">
						<IonText className="text-brown-700 font-semibold">
							{t("modal.perfect-day.create.fields.description.label")}
						</IonText>
						<form.Field
							name="description"
							children={(field) => (
								<FormTextarea
									data-attr="modal.perfect-day.create.description"
									field={field}
									inputMode="text"
									autocapitalize="sentences"
									className="text-brown-700 flex flex-1 flex-col [&_textarea]:-mx-4! [&_textarea]:box-content! [&_textarea]:px-8!"
								/>
							)}
						/>
					</div>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<Button
								type="submit"
								data-attr="modal.perfect-day.create.submit"
								disabled={isSubmitting || !canSubmit}
								color="primary"
								size="default"
								className="mt-8 w-full"
							>
								{isSubmitting ? <IonSpinner name="crescent" /> : t("modal.perfect-day.create.next")}
							</Button>
						)}
					/>
				</form>
			</Content>
		</IonModal>
	);
}
