import { IonIcon } from "@ionic/react";
import { cx } from "@/lib/style/cva.config";

export function TitleIcon(props: React.ComponentProps<typeof IonIcon>) {
	return (
		<IonIcon
			{...props}
			className={cx("bg-brown-100 text-brown-700 size-6 rounded-xl p-2", props.className)}
		/>
	);
}
