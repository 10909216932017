import {
	IonButton,
	IonButtons,
	IonHeader,
	IonIcon,
	IonItem,
	IonList,
	IonPage,
	IonSkeletonText,
	IonText,
	useIonPopover,
	useIonRouter,
	useIonViewDidEnter,
} from "@ionic/react";
import { Toast } from "@capacitor/toast";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "motion/react";
import Image_nl from "@/assets/nl/perfect-day-in-progress@2x.png";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { PerfectDayNotFound } from "@/components/PerfectDay/NotFound";
import { backendService } from "@/lib/backend";
import { useExtractedQuery } from "@/lib/backend/utils/hooks";
import { useToast } from "@/lib/hooks/useToast";
import {
	arrowLeftIcon,
	arrowRightIcon,
	ellipsisVerticalIcon,
} from "@/lib/icons/@heroicons/react/16/solid";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { logger } from "@/logger";

export function PerfectDayDetails() {
	const params = Routes.PerfectDayDetails.useParams();
	const { t, i18n } = useTranslation();
	const perfectDaySceneId = params.id || "";
	const router = useIonRouter();
	const [showHeaderBackground, setShowHeaderBackground] = useState(false);
	const [showHeaderTitle, setShowHeaderTitle] = useState(false);
	const titleRef = useRef<HTMLIonTextElement>(null);
	const presentModal = useSelectorModalStore.use.present();
	const toast = useToast();

	const queryPerfectDayScene = useExtractedQuery({
		queryKey: ["perfect-day", perfectDaySceneId] as const,
		queryFn: (context) =>
			backendService.perfectDay.perfectDayControllerGetSceneWithChat(context.queryKey[1], {
				signal: context.signal,
			}),
		enabled: !!perfectDaySceneId,
	});

	useIonViewDidEnter(() => {
		queryPerfectDayScene.refetch();
	});

	const perfectDayScene = queryPerfectDayScene.data;

	if (!queryPerfectDayScene.isLoading && queryPerfectDayScene.isError) {
		return <PerfectDayNotFound />;
	}

	const HEADER_MARGIN_MULTIPLIER = 2;
	const TITLE_HEIGHT_MARGIN_PIXELS = 20;

	const [presentPopover, dismissPopover] = useIonPopover(
		<IonList className="w-fit text-nowrap" lines="none">
			<IonItem
				className="cursor-pointer"
				onClick={() => {
					dismissPopover();
					presentModal("perfectDayUpdate", {
						id: perfectDaySceneId,
						name: perfectDayScene?.name,
						onCompleted: () => {
							queryPerfectDayScene.refetch();
							toast.show(t("modal.perfect-day.update.success"));
						},
					});
				}}
			>
				{t("perfect-day.details.rename")}
			</IonItem>
			<IonItem
				className="cursor-pointer"
				onClick={() => {
					dismissPopover();
					presentModal("perfectDayConfirmDelete", {
						onConfirmed: async () => {
							try {
								await backendService.perfectDay.perfectDayControllerDeleteScene(perfectDaySceneId);
								toast.show(t("modal.perfect-day.delete.success"));
								router.push(Routes.PerfectDay());
							} catch (error) {
								logger.error(error);
								Toast.show({ text: t("modal.perfect-day.delete.error") });
							}
						},
					});
				}}
			>
				{t("perfect-day.details.delete")}
			</IonItem>
		</IonList>,
	);

	return (
		<IonPage>
			<IonHeader
				className={`ion-no-border transition-colors duration-150 ${showHeaderBackground ? "bg-brown-100" : "bg-transparent"}`}
			>
				<div className="bg-brown-100 absolute h-16 w-[calc((100%-min(640px,100%)-1rem)/2)]" />
				<div className="bg-brown-100 absolute right-0 h-16 w-[calc((100%-min(640px,100%))/2)]" />
				<Toolbar className="plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))] mx-auto flex min-h-[48px] w-full justify-between">
					<IonButtons collapse slot="secondary" className="w-full">
						<IonButton
							data-attr="page.perfect-day.details.back"
							routerLink={Routes.PerfectDay()}
							routerDirection="back"
							color="light"
							className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
						>
							<IonIcon icon={arrowLeftIcon} />
						</IonButton>
						{showHeaderTitle && (
							<IonText className="text-brown-700 w-full text-center text-lg font-bold">
								{perfectDayScene?.name}
							</IonText>
						)}
					</IonButtons>

					{(perfectDayScene?.sortOrder || 0) > 0 && (
						<IonButtons collapse slot="primary">
							<IonButton
								onClick={(e: any) => presentPopover({ event: e })}
								color="light"
								className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
							>
								<IonIcon icon={ellipsisVerticalIcon} />
							</IonButton>
						</IonButtons>
					)}
				</Toolbar>
			</IonHeader>

			<Content
				fullscreen
				className="part-background:bg-brown-100 part-scroll:-mt-20 grid"
				onIonScroll={() => {
					const header = document.querySelector("ion-header");
					if (!header || !titleRef.current) return;

					const headerHeight = header.clientHeight;
					const titlePosition = titleRef.current.getBoundingClientRect();

					setShowHeaderBackground(titlePosition.bottom < headerHeight * HEADER_MARGIN_MULTIPLIER);
					setShowHeaderTitle(
						titlePosition.bottom + titlePosition.height + TITLE_HEIGHT_MARGIN_PIXELS <
							headerHeight * HEADER_MARGIN_MULTIPLIER,
					);
				}}
				scrollEvents
			>
				<div className="plt-desktop:sm:w-[640px] absolute top-0 right-0 left-0 mx-auto h-[60vh] w-full overflow-hidden">
					<img
						src={
							perfectDayScene?.imageUrl
								? perfectDayScene?.imageUrl
								: { nl: perfectDayScene?.imageUrl || Image_nl }[i18n.language]
						}
						alt={t("perfect-day.details.image-alt")}
						aria-label={t("perfect-day.details.image-aria-label", { name: perfectDayScene?.name })}
						className="h-full w-full object-cover"
					/>
					<div className="from-brown-100/100 absolute top-0 right-0 left-0 z-10 h-20 w-full bg-linear-to-b to-transparent" />
					<div className="from-brown-100/100 absolute right-0 bottom-0 left-0 h-20 w-full bg-linear-to-t to-transparent" />
				</div>

				<div className="plt-desktop:sm:w-[640px] mx-auto mt-[60vh] w-full">
					<IonText ref={titleRef} className="text-brown-700 text-center text-3xl font-bold">
						{perfectDayScene?.name}
					</IonText>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.5 }}
						className="border-brown-200 mt-4 flex min-h-40 flex-col gap-2 rounded-xl border p-4"
					>
						<strong className="text-brown-700 text-base font-semibold">
							{t("perfect-day.details.chat-summary")}
						</strong>
						<div data-mask-text className="text-brown-600 block min-h-[4lh] text-pretty">
							<motion.div transition={{ staggerChildren: 0.1 }}>
								{queryPerfectDayScene.isLoading ? (
									<IonSkeletonText animated className="h-20 w-full" />
								) : (
									<>
										<motion.span
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											transition={{ duration: 1 }}
										>
											{perfectDayScene?.chat?.summary || t("perfect-day.details.chat-not-finished")}
										</motion.span>
										<Button
											className="text-brown-600 part-native:pl-0 block w-fit text-sm"
											fill="clear"
											onClick={() => {
												router.push(Routes.PerfectDayChat({ id: perfectDayScene?.id }));
											}}
										>
											<p className="text-brown-600 flex items-center font-semibold">
												{perfectDayScene?.chat?.status === "REFLECTION"
													? t("perfect-day.details.continue-chat")
													: t("perfect-day.details.continue-chat-completed")}
												<IonIcon icon={arrowRightIcon} className="text-brown-400 size-4" />
											</p>
										</Button>
									</>
								)}
							</motion.div>
						</div>
					</motion.div>
				</div>
			</Content>
		</IonPage>
	);
}
