import { IonText, useIonRouter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import Image_nl from "@/assets/nl/explain-perfect-day@2x.png";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { backendService } from "@/lib/backend";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { logger } from "@/logger";

export const PerfectDayLandingPageOnboardingContent = () => {
	const [myProgression] = useMyProgression();
	const presentModal = useSelectorModalStore.use.present();
	const router = useIonRouter();
	const { t, i18n } = useTranslation();

	return (
		<Content className="part-scroll:justify-center">
			<img
				src={{ nl: Image_nl }[i18n.language]}
				className="mx-auto sm:max-h-[60vmin] sm:max-w-[60vmin]"
				alt={t("perfect-day.uninitialized-state.image-alt")}
			/>

			<div className="flex flex-col items-center gap-8">
				<div className="flex flex-col items-center gap-1">
					<IonText className="text-brown-700 text-center text-3xl font-bold">
						{t("perfect-day.uninitialized-state.title")}
					</IonText>
					<IonText className="text-brown-600 text-center text-lg text-pretty">
						{t("perfect-day.uninitialized-state.message")}
					</IonText>
				</div>

				<Button
					data-attr="page.app.perfect-day.action"
					expand="block"
					className="w-full text-lg font-bold sm:max-w-56"
					onClick={() =>
						presentModal("perfectDayExplainer", {
							onCompleted: async () => {
								try {
									const response =
										await backendService.perfectDay.perfectDayControllerInitializeSceneWithChat();
									router.push(Routes.PerfectDayChat({ id: response.data.id }));
								} catch (error) {
									logger.error("Fout bij het initialiseren van de scene:", error);
								}
							},
						})
					}
				>
					{t("perfect-day.uninitialized-state.action")}
				</Button>

				{myProgression.chosen_core_values !== "COMPLETED" && (
					<div className="text-brown-700 text-center">
						<span className="font-semibold">
							{t("perfect-day.uninitialized-state.chosen-core-values-not-completed.title")}
						</span>{" "}
						{t("perfect-day.uninitialized-state.chosen-core-values-not-completed.message")}
					</div>
				)}
			</div>
		</Content>
	);
};
