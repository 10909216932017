import { IonButton, IonButtons, IonHeader, IonIcon, IonPage, useIonRouter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { CoreValueBadge } from "@/components/CoreValue/Badge";
import { Content } from "@/components/Global/Content";
import { NotFound } from "@/components/Global/NotFound";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionCard } from "@/components/Reflection/Card";
import { MultipleChoiceAnswer } from "@/components/Reflection/MultipleChoiceAnswer";
import { backendService } from "@/lib/backend";
import { useExtractedQuery } from "@/lib/backend/utils/hooks";
import { ActionSheet } from "@/lib/capacitor/ActionSheet";
import { Toast } from "@/lib/capacitor/Toast";
import {
	arrowLeftIcon,
	arrowRightIcon,
	ellipsisVerticalIcon,
} from "@/lib/icons/@heroicons/react/20/solid";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { useActionSheet } from "@/lib/useActionSheet";

export function ReflectionDetail() {
	const params = Routes.ReflectionDetail.useParams();
	const router = useIonRouter();
	const { t } = useTranslation();
	const presentModal = useSelectorModalStore.use.present();
	const [myProgression] = useMyProgression();

	const questionId = params.id || "";

	const queryReflection = useExtractedQuery({
		queryKey: ["reflection", questionId] as const,
		queryFn: (context) =>
			backendService.reflection.reflectionControllerGetReflectionById(context.queryKey[1], {
				signal: context.signal,
			}),
		enabled: !!questionId,
		retry: false,
	});

	const reflectionChat = queryReflection.data;

	if (!queryReflection.isLoading && !queryReflection.data && questionId) {
		return (
			<IonPage>
				<IonHeader className="ion-no-border">
					<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
						<IonButtons slot="secondary">
							<IonButton
								data-attr="page.reflection.detail.back"
								data-touch-target
								routerLink={Routes.Reflection()}
								routerDirection="back"
								color="light"
								className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
							>
								<IonIcon icon={arrowLeftIcon} />
							</IonButton>
						</IonButtons>
					</Toolbar>
				</IonHeader>
				<Content>
					<NotFound
						title={t("page.reflection.not-found.title")}
						description={t("page.reflection.not-found.description")}
					/>
				</Content>
			</IonPage>
		);
	}

	const [presentActionSheet] = useActionSheet();

	return (
		<IonPage>
			<IonHeader className="ion-no-border bg-brown-100">
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
					<IonButtons slot="secondary" className="flex w-full justify-between">
						<IonButton
							data-attr="page.reflection.detail.back"
							data-touch-target
							routerLink={Routes.Reflection()}
							routerDirection="back"
							color="light"
							className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
						>
							<IonIcon icon={arrowLeftIcon} />
						</IonButton>
						{myProgression.reflection !== "ANALYSED" && (
							<>
								<IonButton
									onClick={async () => {
										await presentActionSheet({
											title: t("reflection.detail.title"),
											actions: [
												{
													text: t("reflection.detail.restart"),
													style: ActionSheet.ButtonStyle.Destructive,
													handler: async () => {
														try {
															await backendService.reflection.reflectionControllerDeleteReflection(
																reflectionChat?.id ?? "",
															);
															router.push(Routes.Reflection(), "back");
															presentModal("reflectionQuestionFirstAnswer", {
																id: reflectionChat?.reflection?.id,
															});
														} catch {
															Toast.show({
																text: t("reflection.detail.error"),
															});
														}
													},
												},
												{
													text: t("cancel"),
													style: ActionSheet.ButtonStyle.Cancel,
													handler: () => {},
												},
											],
										});
									}}
									className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:p-1 part-native:text-xs part-native:text-brown-600"
								>
									<IonIcon className="size-6" icon={ellipsisVerticalIcon} />
								</IonButton>
							</>
						)}
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<Content className="ion-bg-brown-100 part-scroll:gap-8">
				<div className="flex flex-col gap-4">
					<h1 className="text-brown-700 text-xl font-semibold">
						{reflectionChat?.reflection?.name}
					</h1>

					<div className="flex flex-col gap-2">
						{reflectionChat?.reflection?.initialMessageChoices?.length &&
						reflectionChat?.reflection?.initialMessageChoices?.length > 0 ? (
							<MultipleChoiceAnswer selected={reflectionChat.initialMessageChosen ?? []} />
						) : (
							<p className="text-md text-brown-700 font-normal">
								{reflectionChat?.initialMessageAnswer}
							</p>
						)}

						<div className="flex flex-wrap gap-1">
							{reflectionChat?.generatedCoreValues.map((coreValue) => (
								<CoreValueBadge key={coreValue.id} value={coreValue} />
							))}
						</div>
					</div>

					<ReflectionCard
						onClick={() => router.push(Routes.ReflectionQuestion({ id: questionId }))}
						className="bg-brown-200 flex flex-col gap-2"
					>
						<h2 className="text-md text-brown-700 font-semibold">
							{t("reflection.detail.chat-summary")}
						</h2>
						<p className="text-md text-brown-600 font-normal">{reflectionChat?.userSummary}</p>
						<p className="text-brown-6000 flex items-center gap-2 text-sm font-semibold">
							{t("reflection.detail.read-chat")}
							<IonIcon icon={arrowRightIcon} className="text-brown-600 size-4" />
						</p>
					</ReflectionCard>
				</div>
			</Content>
		</IonPage>
	);
}
