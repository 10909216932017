import { IonButton, IonButtons, IonHeader, IonIcon, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Content } from "@/components/Global/Content";
import { NotFound } from "@/components/Global/NotFound";
import { Toolbar } from "@/components/Global/Toolbar";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { Routes } from "@/lib/router";

export function PerfectDayNotFound() {
	const { t } = useTranslation();

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
					<IonButtons slot="secondary">
						<IonButton
							data-touch-target
							routerLink={Routes.PerfectDay()}
							routerDirection="back"
							aria-label={t("back")}
							color="light"
							className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
						>
							<IonIcon icon={arrowLeftIcon} />
						</IonButton>
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<Content>
				<NotFound
					title={t("page.perfect-day.not-found.title")}
					description={t("page.perfect-day.not-found.description")}
				/>
			</Content>
		</IonPage>
	);
}
