import { type ActionSheetOptions, useIonActionSheet } from "@ionic/react";
import { type HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";
import { type ActionSheetButtonStyle, type ShowActionsOptions } from "@capacitor/action-sheet";
import { Capacitor } from "@/lib/capacitor";
import { ActionSheet } from "@/lib/capacitor/ActionSheet";

const ROLE_MAP = {
	[ActionSheet.ButtonStyle.Destructive]: "destructive",
	[ActionSheet.ButtonStyle.Cancel]: "cancel",
	[ActionSheet.ButtonStyle.Default]: undefined,
};

type ActionSheetAction = {
	text: string;
	style?: ActionSheetButtonStyle;
	handler?: () => boolean | void | Promise<boolean | void>;
};

export type ActionSheetConfig = {
	title?: string;
	message?: string;
	actions: ActionSheetAction[];
	overrides?: {
		native?: Partial<ShowActionsOptions>;
		default?: Partial<ActionSheetOptions & HookOverlayOptions>;
	};
};

export function useActionSheet() {
	const [presentIon, dismissIon] = useIonActionSheet();

	async function present(config: ActionSheetConfig) {
		if (Capacitor.isNativePlatform()) {
			return await ActionSheet.showActions({
				title: config.title,
				message: config.message,
				options: config.actions.map((option) => ({
					title: option.text,
					style: option.style,
				})),
				...config.overrides?.native,
			}).then((index) => config.actions[index].handler?.());
		}

		return await presentIon({
			header: config.title,
			subHeader: config.message,
			buttons: config.actions.map((option) => ({
				text: option.text,
				role: !option.style ? undefined : ROLE_MAP[option.style],
				handler: option.handler,
			})),
			...config.overrides?.default,
		});
	}

	async function dismiss() {
		if (Capacitor.isNativePlatform()) {
			return;
		}

		return await dismissIon();
	}

	return [present, dismiss] as const;
}
