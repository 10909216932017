import { IonIcon, IonImg, IonItem, IonLabel, IonReorder } from "@ionic/react";
import { useTranslation } from "react-i18next";
import Image_nl from "@/assets/nl/perfect-day-in-progress@2x.png";
import type { PerfectDayScene } from "@/lib/backend/api";
import { bar3Icon } from "@/lib/icons/@heroicons/react/24/outline";

export const PerfectDaySceneReorderItem = ({ scene }: { scene: PerfectDayScene }) => {
	const { i18n } = useTranslation();

	return (
		<IonItem
			lines="none"
			className="text-brown-100 part-native:flex part-native:h-20 part-native:flex-row part-native:items-center part-native:p-0 rounded-xl text-xl"
		>
			<IonImg
				src={scene?.imageUrl ?? { nl: Image_nl }[i18n.language]}
				className="absolute inset-0 left-0 h-full w-full object-cover"
			/>
			<div className="absolute inset-0 flex flex-col gap-2 bg-[radial-gradient(circle_at_center,_transparent_0%,_rgba(0,0,0,0.7)_100%)]" />

			<IonLabel className="relative z-10 h-full w-full">
				<div className="flex h-full w-full flex-col justify-end pb-2 pl-4 font-bold">
					{scene.name}
				</div>
			</IonLabel>

			{scene.sortOrder > 0 && (
				<IonReorder className="text-brown-100 relative z-10 font-bold">
					<IonIcon icon={bar3Icon} className="mt-2 text-2xl font-bold" />
				</IonReorder>
			)}
		</IonItem>
	);
};
