import { IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { cx } from "@/lib/style/cva.config";

export function AuthStrategyDivider(props: React.ComponentPropsWithoutRef<"div">) {
	const { t } = useTranslation();

	return (
		<div {...props} className={cx("flex items-center gap-10", props.className)}>
			<Line />
			<IonText className="text-brown-700 shrink text-center font-semibold">
				{t("auth.strategy-divider")}
			</IonText>
			<Line />
		</div>
	);
}

function Line(props: React.ComponentPropsWithoutRef<"div">) {
	return <div {...props} className={cx("border-brown-300 grow border-t", props.className)} />;
}
