import {
	IonButton,
	IonButtons,
	IonFooter,
	IonHeader,
	IonIcon,
	IonPage,
	useIonRouter,
} from "@ionic/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { useStore } from "zustand";
import { ChatContent } from "@/components/Chat/Content";
import { ChatFooter } from "@/components/Chat/Footer";
import { ChatInput } from "@/components/Chat/Input";
import { ChatMessage } from "@/components/Chat/Message";
import { ChatStoreContext, useChatStore } from "@/components/Chat/store";
import { Content } from "@/components/Global/Content";
import { NotFound } from "@/components/Global/NotFound";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionCard } from "@/components/Reflection/Card";
import { MultipleChoiceAnswer } from "@/components/Reflection/MultipleChoiceAnswer";
import { backendService } from "@/lib/backend";
import { useExtractedQuery } from "@/lib/backend/utils/hooks";
import { ActionSheet } from "@/lib/capacitor/ActionSheet";
import { Toast } from "@/lib/capacitor/Toast";
import { arrowLeftIcon, ellipsisVerticalIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { useMutationStream } from "@/lib/query/hooks/useMutationStream";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { useActionSheet } from "@/lib/useActionSheet";

export function ReflectionQuestion() {
	const params = Routes.ReflectionQuestion.useParams();
	const posthog = usePostHog();
	const presentModal = useSelectorModalStore.use.present();
	const { t } = useTranslation();
	const router = useIonRouter();
	const [myProgression] = useMyProgression();

	const chatStore = useChatStore();
	const chat = useStore(chatStore);

	const questionId = params.id || "";

	const queryReflection = useExtractedQuery({
		queryKey: ["reflection", questionId] as const,
		queryFn: (context) =>
			backendService.reflection.reflectionControllerGetReflectionById(context.queryKey[1], {
				signal: context.signal,
			}),
		enabled: !!questionId,
		retry: false,
	});

	const reflectionChat = queryReflection.data;
	const chatId = reflectionChat?.id ?? "";

	const [streamedResponse, mutationStream] = useMutationStream({
		mutationKey: ["reflection", "generate"],
		mutationFn: (params) =>
			backendService.reflection.reflectionControllerGenerateReflection(chatId, params),
		onSettled: () => {
			queryReflection.refetch();
			chat.setResponding(false);
		},
	});

	useEffect(() => {
		if (reflectionChat?.messages.length == 0 && !mutationStream.isPending) {
			mutationStream.mutate();
			chat.setResponding(true);
		}
	}, [reflectionChat?.messages.length]);

	if (streamedResponse !== chat.streamAssistantMessage) {
		chat.setStreamAssistantMessage(streamedResponse);
	}

	useEffect(() => {
		if (reflectionChat?.status == "CHAT_COMPLETED") {
			presentModal("reflectionQuestionCompleted", { id: questionId });
			posthog.capture("Reflection Question Completed", { questionId });
		}
	}, [reflectionChat?.status]);

	if (!queryReflection.isLoading && !queryReflection.data && questionId) {
		return (
			<IonPage>
				<IonHeader className="ion-no-border">
					<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
						<IonButtons slot="secondary">
							<IonButton
								data-attr="page.reflection.question.back"
								data-touch-target
								routerLink={Routes.Reflection()}
								routerDirection="back"
								color="light"
								className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
							>
								<IonIcon icon={arrowLeftIcon} />
							</IonButton>
						</IonButtons>
					</Toolbar>
				</IonHeader>
				<Content>
					<NotFound
						title={t("page.reflection.not-found.title")}
						description={t("page.reflection.not-found.description")}
					/>
				</Content>
			</IonPage>
		);
	}

	const [presentActionSheet] = useActionSheet();

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
					<IonButtons slot="secondary" className="flex w-full justify-between">
						<IonButton
							data-attr="page.reflection.question.back"
							data-touch-target
							routerLink={Routes.Reflection()}
							routerDirection="back"
							color="light"
							className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
						>
							<IonIcon icon={arrowLeftIcon} />
						</IonButton>
						{myProgression.reflection !== "ANALYSED" && (
							<IonButton
								onClick={async () => {
									await presentActionSheet({
										title: t("reflection.detail.title"),
										actions: [
											{
												text: t("reflection.detail.restart"),
												style: ActionSheet.ButtonStyle.Destructive,
												handler: async () => {
													try {
														await backendService.reflection.reflectionControllerDeleteReflection(
															reflectionChat?.id ?? "",
														);
														router.push(Routes.Reflection(), "back");
														presentModal("reflectionQuestionFirstAnswer", {
															id: reflectionChat?.reflection?.id,
														});
													} catch {
														Toast.show({
															text: t("reflection.detail.error"),
														});
													}
												},
											},
											{
												text: t("cancel"),
												style: ActionSheet.ButtonStyle.Cancel,
												handler: () => {},
											},
										],
									});
								}}
								className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:p-1 part-native:text-xs part-native:text-brown-600"
							>
								<IonIcon className="size-6" icon={ellipsisVerticalIcon} />
							</IonButton>
						)}
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<ChatStoreContext.Provider value={chatStore}>
				<Content className="part-scroll:gap-8">
					<ChatContent>
						<ReflectionCard
							onClick={() => {
								if (
									reflectionChat?.status === "CHAT_COMPLETED" ||
									reflectionChat?.status === "FINALIZED"
								) {
									presentModal("reflectionQuestionCompleted", { id: params.id });
								}
							}}
							className="shrink-0 gap-2"
						>
							<strong className="pt-2 text-base font-semibold text-pretty">
								{reflectionChat?.reflection?.initialMessageContent}
							</strong>
							{reflectionChat?.reflection?.initialMessageChoices?.length &&
							reflectionChat?.reflection?.initialMessageChoices?.length > 0 ? (
								<MultipleChoiceAnswer
									data-mask-text
									selected={reflectionChat.initialMessageChosen ?? []}
								/>
							) : (
								<p data-mask-text className="text-base font-normal">
									{reflectionChat?.initialMessageAnswer}
								</p>
							)}
						</ReflectionCard>
						{reflectionChat?.messages
							.filter((message) => message.content)
							.map((message, index) => (
								<ChatMessage key={index} sender={message.role === "USER" ? "user" : "bot"}>
									{message.content}
								</ChatMessage>
							))}
					</ChatContent>
				</Content>
				<IonFooter>
					<ChatFooter>
						{reflectionChat?.status === "IN_PROGRESS" && (
							<ChatInput
								focusOnLoad={reflectionChat?.status === "IN_PROGRESS"}
								onSubmit={async (content) => {
									try {
										await backendService.reflection.reflectionControllerAnswerQuestion(chatId, {
											content,
										});
									} catch {
										Toast.show({
											text: t("modal.reflection.explainer.question.error.send-message-failed"),
										});
										return false;
									}

									posthog.capture("Reflection Question Answered", { questionId });

									mutationStream.mutate();

									return true;
								}}
							/>
						)}
					</ChatFooter>
				</IonFooter>
			</ChatStoreContext.Provider>
		</IonPage>
	);
}
