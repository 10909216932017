import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { type VariantProps } from "cva";
import { buttonVariants } from "@/components/ui/button/variants";
import { cx } from "@/lib/style/cva.config";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
	VariantProps<typeof buttonVariants> & {
		as?: "child" | "button";
	};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
	{ className, variant, size, as = "button", ...props },
	ref,
) {
	const Comp = as === "child" ? Slot : as;
	return <Comp className={cx(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
