import { cx } from "@/lib/style/cva.config";
import { LoadingThreeDots } from "../Loading/LoadingThreeDots";

export function ChatMessage({
	sender,
	parts,
	...props
}: React.ComponentPropsWithoutRef<"div"> & {
	sender: "user" | "bot";
	parts?: {
		smallBubble?: React.ComponentPropsWithoutRef<"div">;
		text?: React.ComponentPropsWithoutRef<"div">;
	};
}) {
	return (
		<div
			{...props}
			data-attr="chat-bubble"
			data-mask-text
			className={cx(
				"text-brown-700 relative w-fit max-w-[90%] rounded-2xl px-3 py-2",
				sender === "user" && "bg-brown-100 ms-auto me-3",
				sender === "bot" && "bg-brown-300 ms-3 me-auto",
				props.className,
			)}
		>
			<div
				{...parts?.smallBubble}
				className={cx(
					"absolute bottom-0 size-3 rounded-full",
					sender === "user" && "bg-brown-100 -right-3",
					sender === "bot" && "bg-brown-300 -left-3",
					parts?.smallBubble?.className,
				)}
			/>
			{!props.children && sender === "bot" && <LoadingThreeDots opacity={0.2} />}
			{typeof props.children === "string" ? (
				<p
					data-mask-text
					{...parts?.text}
					className={cx("text-brown-700 font-medium", parts?.text?.className)}
				>
					{props.children}
				</p>
			) : (
				props.children
			)}
		</div>
	);
}
