import { IonButtons, IonFooter, IonIcon, IonModal, IonText, useIonRouter } from "@ionic/react";
import { useDeferredValue, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import AutoHeight from "embla-carousel-auto-height";
import { motion } from "motion/react";
import { usePostHog } from "posthog-js/react";
import Image_5_nl from "@/assets/app/explainer/nl/5@2x.png";
import { CoreValueCard } from "@/components/CoreValue/CoreValueCard";
import { Button } from "@/components/Global/Button";
import { Toolbar } from "@/components/Global/Toolbar";
import {
	Carousel,
	type CarouselApi,
	CarouselContent,
	CarouselItem,
} from "@/components/ui/carousel";
import { arrowRightIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { queryOptions_coreValuesChosen } from "@/lib/query/functions/core-values/chosen";
import { useMyData } from "@/lib/query/functions/user/data";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";

const MotionButton = motion.create(Button);
const steps = [1, 2] as const;

export function ModalCoreValuesPersonalizeExplainer() {
	const { t, i18n } = useTranslation();
	const posthog = usePostHog();
	const modalStepRef = useRef<HTMLIonModalElement>(null);
	const modalExplainerRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.coreValuesPersonalizeExplainer();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const [, { mutation }] = useMyData();
	const [api, setApi] = useState<CarouselApi>();
	const [scrollProgress, setScrollProgress] = useState(0);
	const deferredScrollProgress = useDeferredValue(scrollProgress);
	const router = useIonRouter();

	const query = useQuery({
		...queryOptions_coreValuesChosen(),
		enabled: modal.isOpen,
	});

	function onScroll(api: NonNullable<CarouselApi>) {
		setScrollProgress(api.scrollProgress() * (steps.length - 1));
	}

	useEffect(() => {
		if (!api) {
			return;
		}

		api.on("scroll", onScroll);

		return () => {
			api.off("scroll", onScroll);
		};
	}, [api]);

	useEffect(() => {
		if (!api || !modal.isOpen || query.status !== "success") {
			return;
		}

		api.reInit();
		// For the query content
		setTimeout(() => api.reInit(), 0);
	}, [api, modal.isOpen, query.status]);

	return (
		<>
			<IonModal
				ref={modalStepRef}
				isOpen={modal.isOpen}
				onWillPresent={() => {
					present("coreValuesPersonalizeExplainer");
					posthog.capture("Modal CoreValues Personalize Explainer Present");
				}}
				onWillDismiss={() => {
					dismiss("coreValuesPersonalizeExplainer");
					posthog.capture("Modal CoreValues Personalize Explainer Dismiss");
				}}
				onDidDismiss={() => api?.scrollTo(0, true)}
				initialBreakpoint={1}
				breakpoints={[0, 1]}
				className="ion-h-auto"
			>
				<Carousel plugins={[AutoHeight()]} setApi={setApi} className="ion-padding -m-4 pt-12">
					<div className="flex flex-col gap-2 px-6">
						<img
							src={
								{
									nl: Image_5_nl,
								}[i18n.language]
							}
							onLoad={() => api?.reInit()}
							alt={t(`modal.app.explainer.step5.alt`)}
							className="max-h-[50dvh] w-full flex-1 object-contain"
						/>

						<div className="flex flex-col gap-3">
							<IonText className="text-brown-700 text-2xl font-semibold">
								{t(`modal.app.explainer.step5.title`)}
							</IonText>
							<IonText className="text-brown-600 text-base">
								{t(`modal.app.explainer.step5.text`)}
							</IonText>
						</div>
					</div>
				</Carousel>

				<IonFooter className="ion-no-border">
					<Toolbar className="ion-bg-brown-200 ion-p-4">
						<div className="flex w-full justify-end">
							<Button
								data-attr="modal.core-values.personalize.explainer.start"
								fill="solid"
								className="ion-bg-orange-500 ion-text-white !ion-rounded-2xl !ion-px-5 !ion-py-3 part-native:text-lg part-native:font-bold part-native:shadow-xs"
								onClick={() => {
									modalStepRef.current?.dismiss();
									modalExplainerRef.current?.present();
								}}
							>
								{t("start")}
								<IonIcon slot="end" icon={arrowRightIcon} className="size-6" />
							</Button>
						</div>
					</Toolbar>
				</IonFooter>
			</IonModal>

			<IonModal
				ref={modalExplainerRef}
				onDidDismiss={() => api?.scrollTo(0, true)}
				initialBreakpoint={1}
				breakpoints={[0, 1]}
				className="ion-h-auto"
			>
				<Carousel plugins={[AutoHeight()]} setApi={setApi} className="ion-padding -m-4 pt-12">
					<CarouselContent className="items-start transition-[height] *:*:p-4">
						<CarouselItem>
							<div className="flex flex-col gap-6">
								<IonText className="text-brown-700 text-2xl font-bold">
									{t("modal.friend-check.explainer.step1.title")}
								</IonText>

								<ol className="flex flex-col gap-8">
									{[
										{
											title: t("modal.core-values.personalize.explainer.step1.point-1.title"),
											text: t("modal.core-values.personalize.explainer.step1.point-1.text"),
										},
										{
											title: t("modal.core-values.personalize.explainer.step1.point-2.title"),
											text: t("modal.core-values.personalize.explainer.step1.point-2.text"),
										},
										{
											title: t("modal.core-values.personalize.explainer.step1.point-3.title"),
											text: t("modal.core-values.personalize.explainer.step1.point-3.text"),
										},
									].map((value, index) => (
										<li key={index + 1} className="flex items-start gap-3">
											<IonText className="text-brown-500 grid size-8 shrink-0 place-content-center rounded-full bg-white text-center text-lg font-bold select-none">
												{index + 1}
											</IonText>
											<div className="flex flex-col">
												<IonText className="text-brown-600 text-lg font-bold">
													{value.title}
												</IonText>
												<IonText className="text-brown-700 text-base font-normal">
													{value.text}
												</IonText>
											</div>
										</li>
									))}
								</ol>
							</div>
						</CarouselItem>
						<CarouselItem>
							<div className="flex flex-col gap-2">
								<IonText className="text-brown-700 text-2xl font-bold">
									{t("modal.core-values.personalize.explainer.step2.title")}
								</IonText>

								<IonText className="text-brown-700 text-base font-normal">
									{t("modal.core-values.personalize.explainer.step2.text")}
								</IonText>

								{!!query.data?.length && (
									<div className="flex flex-col gap-4">
										<CoreValueCard coreValue={query.data[0]} />
									</div>
								)}
							</div>
						</CarouselItem>
					</CarouselContent>
				</Carousel>

				<IonFooter className="ion-no-border">
					<Toolbar className="ion-bg-brown-200 ion-p-4">
						<div className="relative isolate flex w-fit flex-row items-center justify-center gap-2 py-2 *:size-2.5 *:rounded-full!">
							{steps.map((_, index) => (
								<button key={index} className="bg-brown-300" onClick={() => api?.scrollTo(index)} />
							))}
							<motion.div
								className="bg-brown-500 pointer-events-none absolute left-0 z-10"
								initial={{ x: "0rem" }}
								animate={{ x: `${deferredScrollProgress * 1.125}rem` }}
								transition={{ type: false }}
							/>
						</div>
						<IonButtons slot="primary" className="relative">
							{steps.map((step, index, self) => (
								<MotionButton
									key={step}
									initial="dynamic"
									animate="dynamic"
									variants={{
										dynamic: {
											opacity: 1 - Math.abs(deferredScrollProgress - index),
											zIndex: Math.floor((1 - Math.abs(deferredScrollProgress - index)) * 10),
										},
									}}
									transition={{ type: false }}
									className={cx(
										"!ion-rounded-2xl !ion-px-5 !ion-py-3 part-native:text-lg part-native:font-bold part-native:shadow-xs",
										step === 2
											? "ion-bg-orange-500 ion-text-white"
											: "ion-bg-brown-100 ion-text-brown-700",
										step !== self.length - 1 && "absolute right-0",
									)}
									data-attr={`modal.core-values.personalize..explainer.step${step}.next`}
									onClick={() => {
										if (api?.canScrollNext()) {
											api.scrollNext();
										} else {
											mutation.mutate(
												(draft) => void (draft.modal.coreValuesPersonalizeExplainer.seen = true),
											);
											posthog.capture("Modal CoreValues Personalize Explainer Finished");
											modalExplainerRef.current?.dismiss();
											router.push(Routes.CoreValuesChat({ id: query.data?.[0].id }));
										}
									}}
								>
									{t(`modal.friend-check.explainer.step${step}.next`)}
									<IonIcon slot="end" icon={arrowRightIcon} className="size-6" />
								</MotionButton>
							))}
						</IonButtons>
					</Toolbar>
				</IonFooter>
			</IonModal>
		</>
	);
}
