import { createContext, useContext, useRef } from "react";
import { createStore, useStore } from "zustand";

export interface ChatStates {
	isResponding?: boolean;
	streamAssistantMessage?: string | null;
	streamUserMessage?: string | null;
}

export interface ChatActions {
	/**
	 * Controls if the stream messages are showing
	 *
	 * @param isResponding Is the LLM currently responding
	 */
	setResponding: (isResponding: boolean) => void;

	/** @param message Temporary bot message while streaming */
	setStreamAssistantMessage: (message: string | null) => void;

	/**
	 * @param message Temporary user message while streaming
	 * @param isResponding Optional parameter to update isResponding (leave undefined to keep the
	 *   current state)
	 */
	setStreamUserMessage: (message: string, isResponding?: boolean) => void;
}

export type ChatStore = ReturnType<typeof createChatStore>;

function createChatStore(options?: ChatStates) {
	return createStore<ChatStates & ChatActions>()((set) => ({
		isResponding: false,
		streamAssistantMessage: undefined,
		streamUserMessage: undefined,
		...options,

		setResponding: (isResponding) => set(() => ({ isResponding })),
		setStreamAssistantMessage: (message) => set(() => ({ streamAssistantMessage: message })),
		setStreamUserMessage: (message, isResponding?: boolean) =>
			set((state) => ({
				streamUserMessage: message,
				isResponding: isResponding ?? state.isResponding,
			})),
	}));
}

export function useChatStore(options?: ChatStates) {
	const store = useRef(createChatStore(options)).current;
	return store;
}

export const ChatStoreContext = createContext<ChatStore | null>(null);

export function useChatStoreContext() {
	const context = useContext(ChatStoreContext);

	if (!context) {
		throw new Error("useChatStoreContext must be used within a ChatStoreContext");
	}

	return context;
}

export function useChat() {
	const chatStore = useChatStoreContext();
	const chat = useStore(chatStore);

	return chat;
}
