import { useFeatureFlagEnabled } from "posthog-js/react";
import { IS_DEVELOPMENT } from "@/lib/constants";
import { FeatureFlagContext } from "@/lib/posthog/components/FeatureFlag/context";
import { FEATURE_FLAG } from "@/lib/posthog/feature-flags";

export function FeatureFlagProvider(
	props: Omit<React.ComponentProps<typeof FeatureFlagContext.Provider>, "value">,
) {
	const development = useFeatureFlagEnabled(FEATURE_FLAG.DEVELOPMENT);
	const corevalueRefineEnabled = useFeatureFlagEnabled(FEATURE_FLAG.COREVALUE_REFINE_ENABLED);
	const perfectDayMenuItemEnabled = useFeatureFlagEnabled(
		FEATURE_FLAG.PERFECT_DAY_MENU_ITEM_ENABLED,
	);

	return (
		<FeatureFlagContext.Provider
			{...props}
			value={{
				[FEATURE_FLAG.DEVELOPMENT]: IS_DEVELOPMENT || development,
				[FEATURE_FLAG.COREVALUE_REFINE_ENABLED]: corevalueRefineEnabled,
				[FEATURE_FLAG.PERFECT_DAY_MENU_ITEM_ENABLED]: IS_DEVELOPMENT || perfectDayMenuItemEnabled, // In the future this should be enabled by default, so this already forces it to always be enabled in development and staging/production is managed through posthog
			}}
		/>
	);
}
