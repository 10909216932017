import { IonFooter, IonModal, IonText } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { FriendCheckProgress } from "@/components/FriendCheck/Progress";
import { Button } from "@/components/Global/Button";
import { Toolbar } from "@/components/Global/Toolbar";
import { useMyData } from "@/lib/query/functions/user/data";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalFriendCheckCompleted() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.friendCheckCompleted();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const [, { mutation }] = useMyData();
	const [myProgression] = useMyProgression();

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => {
				present("friendCheckCompleted");
				posthog.capture("Modal FriendCheck Completed Present");
			}}
			onWillDismiss={() => {
				dismiss("friendCheckCompleted");
				posthog.capture("Modal FriendCheck Completed Dismiss");
			}}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="ion-h-auto"
		>
			<div className="ion-padding flex flex-col gap-2 pt-12">
				<IonText className="text-brown-700 text-2xl font-bold">
					{t("modal.friend-check.completed.title")}
				</IonText>

				<FriendCheckProgress withAction={false} className="pointer-events-none" />
			</div>

			<IonFooter className="ion-no-border">
				<Toolbar className="ion-bg-brown-200 ion-p-4">
					<div className="flex flex-col gap-2">
						<Button
							data-attr="modal.friend-check.completed.confirm"
							expand="block"
							fill="solid"
							className="!ion-rounded-2xl text-lg font-bold"
							onClick={() => {
								mutation.mutate((draft) => void (draft.modal.friendCheckCompleted.seen = true));
								posthog.capture("Modal FriendCheck Completed Finished");
								present(
									myProgression.friend_check === "ANALYSED" ||
										myProgression.friend_check === "SUMMARIZED"
										? "friendCheckAnalysisView"
										: "friendCheckAnalysisGenerate",
								);
								modalRef.current?.dismiss();
							}}
						>
							{t("modal.friend-check.completed.confirm")}
						</Button>
						<Button
							data-attr="modal.friend-check.completed.cancel"
							expand="block"
							fill="clear"
							className="ion-bg-a-brown-100 ion-bg-brown-100 ion-bg-f-brown-100 ion-bg-h-brown-100 ion-text-brown-700 !ion-rounded-2xl text-lg font-bold"
							onClick={() => {
								mutation.mutate((draft) => void (draft.modal.friendCheckCompleted.seen = true));
								posthog.capture("Modal FriendCheck Completed Finished");
								modalRef.current?.dismiss();
							}}
						>
							{t("modal.friend-check.completed.cancel")}
						</Button>
					</div>
				</Toolbar>
			</IonFooter>
		</IonModal>
	);
}
