import { IonIcon, IonProgressBar, IonRouterLink, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { FRIEND_CHECK_TARGET } from "@/lib/constants";
import { checkIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { arrowRightIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { queryOptions_friendCheckSubmissions } from "@/lib/query/functions/friend-check/submissions";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";

export function FriendCheckProgress({
	withAction = true,
	...props
}: React.ComponentProps<typeof IonRouterLink> & { withAction?: boolean }) {
	const { t } = useTranslation();
	const presentModal = useSelectorModalStore.use.present();
	const [myProgression] = useMyProgression();

	const query = useQuery({
		...queryOptions_friendCheckSubmissions(),
		select: (data) => ({
			count: data?.length ?? 0,
			target: FRIEND_CHECK_TARGET,
		}),
	});
	const { count, target } = query.data ?? { count: 0, target: FRIEND_CHECK_TARGET };
	const hasCompleted = count >= target;

	return (
		<IonRouterLink
			data-attr="friend-check.progress"
			{...(hasCompleted
				? {
						onClick: () =>
							presentModal(
								myProgression.friend_check === "ANALYSED" ||
									myProgression.friend_check === "SUMMARIZED"
									? "friendCheckAnalysisView"
									: "friendCheckAnalysisGenerate",
							),
					}
				: {
						routerLink: Routes.FriendCheck(),
						routerDirection: "none",
					})}
			{...props}
			className={cx(
				"border-brown-200 bg-brown-100 flex flex-col rounded-2xl border p-3 text-start",
				hasCompleted && "ion-activatable cursor-pointer",
				props.className,
			)}
		>
			<div className="flex items-center justify-between gap-2">
				<IonText className="text-brown-700 leading-5 font-bold">
					{t("friend-check.progress.title")}
				</IonText>

				{withAction && (
					<div className="flex items-center justify-end gap-1">
						{hasCompleted && (
							<IonText className="text-brown-400 text-xs font-semibold">
								{myProgression.friend_check === "ANALYSED" ||
								myProgression.friend_check === "SUMMARIZED"
									? t("friend-check.progress.view-analysis")
									: t("friend-check.progress.generate-analysis")}
							</IonText>
						)}
						<IonIcon icon={arrowRightIcon} className="text-brown-400 size-5" />
					</div>
				)}
			</div>

			<IonText className="text-brown-700 text-sm leading-5">
				{t("friend-check.progress.text", {
					count,
					target,
				})}
			</IonText>

			<div className="relative isolate flex h-5 w-full items-center">
				<IonProgressBar value={count / target} className="ion-bg-brown-200 h-2" />
				<div
					aria-checked={count >= target}
					className="bg-brown-200 absolute inset-y-0 right-0 z-10 grid size-5 place-content-center rounded-full text-transparent aria-checked:bg-orange-500 aria-checked:text-white"
				>
					<IonIcon icon={checkIcon} className="size-4" />
				</div>
			</div>
		</IonRouterLink>
	);
}
