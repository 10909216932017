import { extendTailwindMerge } from "tailwind-merge";

export const twMerge = extendTailwindMerge({
	extend: {
		classGroups: {
			"bg-color": ["ion-bg-transparent", "ion-bg-brown-100", "ion-bg-brown-200"],
			rounded: [
				"ion-rounded-none",
				"ion-rounded-sm",
				"ion-rounded",
				"ion-rounded-md",
				"ion-rounded-lg",
				"ion-rounded-xl",
				"ion-rounded-2xl",
				"ion-rounded-3xl",
				"ion-rounded-full",
			],
		},
	},
});
