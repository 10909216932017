import { useIonToast } from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";

export const useToast = () => {
	const [present] = useIonToast();
	const durationInMilliseconds = 2000;

	return {
		show: (message: string) => {
			present({
				message,
				duration: durationInMilliseconds,
				position: "top",
				icon: checkmarkCircle,
			});
		},
	};
};
