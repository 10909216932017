import { IonPage } from "@ionic/react";
import { PerfectDayLandingPageOnboardingContent } from "@/components/PerfectDay/OnboardingContent";
import { PerfectDaySceneOverviewContent } from "@/components/PerfectDay/Scene/OverviewContent";
import { useMyData } from "@/lib/query/functions/user/data";

export function PerfectDay() {
	const [myData] = useMyData();

	return (
		<IonPage>
			{myData.modal.perfectDayExplainer.seen ? (
				<PerfectDaySceneOverviewContent />
			) : (
				<PerfectDayLandingPageOnboardingContent />
			)}
		</IonPage>
	);
}
