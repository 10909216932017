import { IonIcon } from "@ionic/react";
import { type ReactNode } from "react";
import { exclamationTriangleIcon } from "@/lib/icons/@heroicons/react/24/outline";

interface NotFoundProps {
	title: ReactNode;
	description?: ReactNode;
	icon?: string;
}

export function NotFound({
	title,
	description,
	icon = exclamationTriangleIcon,
	...props
}: React.PropsWithChildren<NotFoundProps>) {
	return (
		<div className="flex h-full w-full flex-col items-center justify-center gap-3 p-4 text-center">
			<IonIcon icon={icon} className="text-brown-700 mb-3 size-32" />
			<h1 className="text-brown-700 text-xl font-bold text-pretty">{title}</h1>
			{description && <p className="text-brown-600 text-base text-balance">{description}</p>}
			{!!props.children && <div className="mt-3">{props.children}</div>}
		</div>
	);
}
