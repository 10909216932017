import { IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { ChatMessage } from "@/components/Chat/Message";
import { photoIcon } from "@/lib/icons/@heroicons/react/24/outline";

export function PerfectDayChatGeneratingImageStatus() {
	const { t } = useTranslation();

	return (
		<ChatMessage sender="bot" className="aspect-square w-3/4 bg-transparent p-0">
			<div className="-pt-4 border-brown-300 flex h-full w-full flex-col items-center justify-center rounded-2xl border px-4">
				<div className="inset-0 flex items-center justify-center">
					<div className="border-brown-300 border-t-brown-600 animate-spin-longer size-16 rounded-full border-2" />
					<IonIcon icon={photoIcon} className="text-brown-400 absolute size-10" />
				</div>
				<p className="text-brown-500 mt-2 font-medium">
					{t("page.perfect-day.scene.chat.generating-image")}
				</p>
			</div>
		</ChatMessage>
	);
}
