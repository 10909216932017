import { useIonRouter } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { useStore } from "zustand";
import { useChatStore } from "@/components/Chat/store";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";
import { usePerfectDayMutationStream } from "@/lib/query/functions/perfect-day/hooks/useMutationStream";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";

export function usePerfectDayChatStore(sceneId: string) {
	const router = useIonRouter();
	const presentModal = useSelectorModalStore.use.present();
	const chatStore = useChatStore();
	const chat = useStore(chatStore);

	const queryPerfectDay = useQuery({
		queryKey: ["perfectDay", sceneId] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.perfectDay.perfectDayControllerGetSceneWithChat(context.queryKey[1], {
					signal: context.signal,
				}),
			),
		enabled: !!sceneId,
		retry: false,
	});

	const [streamedResponse, mutationStream, streamStatus] = usePerfectDayMutationStream({
		mutationKey: ["perfectDay", "generate"],
		mutationFn: (params) =>
			backendService.perfectDay.perfectDayControllerGenerateChatResponse(
				queryPerfectDay.data?.chat.id || "",
				params,
			),
		onSettled: () => {
			queryPerfectDay.refetch().then((result) => {
				if (!result.data) return;

				if (result.data.chat.status === "COMPLETED") {
					presentModal("perfectDayCompleted", { id: result.data.id });
					router.push(Routes.PerfectDayDetails({ id: result.data.id }));
					return;
				}

				const previousStatus = queryPerfectDay.data?.chat.status;
				const newStatus = result.data.chat.status;

				if (previousStatus !== "IMAGE_GENERATION" && newStatus === "IMAGE_GENERATION") {
					chat.setStreamUserMessage("");
					mutationStream.mutate();
					return;
				}

				chat.setResponding(false);
			});
		},
	});

	if (streamedResponse !== chat.streamAssistantMessage && streamStatus !== "GENERATING_IMAGE") {
		chat.setStreamAssistantMessage(streamedResponse);
	}

	return {
		queryPerfectDay,
		streamStatus,
		chat,
		chatStore,
		mutationStream,
		perfectDaySceneWithChat: queryPerfectDay.data,
	};
}
