import {
	IonIcon,
	IonLabel,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs,
	useIonRouter,
} from "@ionic/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { FRIEND_CHECK_TARGET } from "@/lib/constants";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import {
	bookOpenIcon as bookOpenOutlineIcon,
	chartPieIcon as chartPieOutlineIcon,
	homeIcon as homeOutlineIcon,
	sunIcon as sunOutlineIcon,
	userGroupIcon as userGroupOutlineIcon,
} from "@/lib/icons/@heroicons/react/24/outline";
import {
	bookOpenIcon as bookOpenSolidIcon,
	chartPieIcon as chartPieSolidIcon,
	homeIcon as homeSolidIcon,
	sunIcon as sunSolidIcon,
	userGroupIcon as userGroupSolidIcon,
} from "@/lib/icons/@heroicons/react/24/solid";
import { useFeatureFlagsContext } from "@/lib/posthog/components/FeatureFlag/context";
import { FEATURE_FLAG } from "@/lib/posthog/feature-flags";
import { queryOptions_friendCheckSubmissions } from "@/lib/query/functions/friend-check/submissions";
import { useMyData } from "@/lib/query/functions/user/data";
import { useMe } from "@/lib/query/functions/user/me";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { CoreValues } from "@/pages/app/core-values";
import { Dashboard } from "@/pages/app/dashboard";
import { FriendCheck } from "@/pages/app/friend-check";
import { Reflection } from "@/pages/app/reflection";
import { PerfectDay } from "./perfect-day";

export function AppTabs() {
	const { t } = useTranslation();
	const [me, meQuery] = useMe();
	const [myData, { query: myDataQuery }] = useMyData();
	const router = useIonRouter();
	const PreferredHaptics = usePreferredHaptics();
	const presentModal = useSelectorModalStore.use.present();
	const featureFlags = useFeatureFlagsContext();

	const queryFriendCheck = useQuery(queryOptions_friendCheckSubmissions());

	useEffect(() => {
		if (meQuery.status === "pending" || myDataQuery.status === "pending") {
			return;
		}

		if (!me?.name) {
			router.push(Routes.AuthSignUpProfile(), undefined, "replace");
			return;
		}

		if (!myData.modal.appExplainer.seen) {
			presentModal("appExplainer");
			return;
		}

		if (
			!!queryFriendCheck.data &&
			queryFriendCheck.data.length >= FRIEND_CHECK_TARGET &&
			!myData.modal.friendCheckCompleted.seen
		) {
			presentModal("friendCheckCompleted");
			return;
		}
	}, [
		meQuery.status,
		JSON.stringify(meQuery.data),
		queryFriendCheck.status,
		queryFriendCheck.data?.length,
		myDataQuery.status,
		JSON.stringify(myData),
	]);

	return (
		<IonTabs>
			<IonRouterOutlet id="app">
				<Route exact path={Routes.Dashboard()} component={Dashboard} />
				<Route exact path={Routes.Reflection()} component={Reflection} />
				<Route exact path={Routes.FriendCheck()} component={FriendCheck} />
				<Route exact path={Routes.PerfectDay()} component={PerfectDay} />
				<Route exact path={Routes.CoreValues()} component={CoreValues} />
				<Redirect exact from={Routes.App()} to={Routes.Dashboard()} />
			</IonRouterOutlet>
			<IonTabBar
				slot="bottom"
				className="ion-bg-white *:ion-bg-f-brown-700/5 *:ion-text-brown-700 *:ion-text-f-brown-700 *:ion-text-s-brown-700 *:ion-p-2 *:plt-desktop:max-w-32 mobile-sm:min-h-16 *:mobile-md:ion-px-4 rounded-t-3xl shadow-[0_0_16px_0_--alpha(var(--color-black)/6%)]"
			>
				<IonTabButton
					tab="dashboard"
					data-attr="pages.app.index.tab.dashboard"
					href={Routes.Dashboard()}
					onClick={() => PreferredHaptics.impact()}
					className="group"
				>
					<IonIcon icon={homeOutlineIcon} className="size-6 group-[.tab-selected]:hidden" />
					<IonIcon icon={homeSolidIcon} className="hidden size-6 group-[.tab-selected]:block" />
					<IonLabel className="mobile-sm:block hidden text-xs leading-5 font-normal">
						{t("dashboard.name")}
					</IonLabel>
				</IonTabButton>
				<IonTabButton
					tab="reflection"
					data-attr="pages.app.index.tab.reflection"
					href={Routes.Reflection()}
					onClick={() => PreferredHaptics.impact()}
					className="group"
				>
					<IonIcon icon={bookOpenOutlineIcon} className="size-6 group-[.tab-selected]:hidden" />
					<IonIcon icon={bookOpenSolidIcon} className="hidden size-6 group-[.tab-selected]:block" />
					<IonLabel className="mobile-sm:block hidden text-xs font-normal">
						{t("reflection.name")}
					</IonLabel>
				</IonTabButton>
				<IonTabButton
					tab="friend-check"
					data-attr="pages.app.index.tab.friend-check"
					href={Routes.FriendCheck()}
					onClick={() => PreferredHaptics.impact()}
					className="group"
				>
					<IonIcon icon={userGroupOutlineIcon} className="size-6 group-[.tab-selected]:hidden" />
					<IonIcon
						icon={userGroupSolidIcon}
						className="hidden size-6 group-[.tab-selected]:block"
					/>
					<IonLabel className="mobile-sm:block hidden text-xs font-normal">
						{t("friend-check.name")}
					</IonLabel>
					<div
						aria-hidden={queryFriendCheck.data?.every((submission) => submission.seen) ?? true}
						className="mobile-sm:right-1/3 pointer-events-none absolute top-0 right-0.5 size-2.5 opacity-100 transition-opacity *:size-full *:rounded-full! *:bg-orange-500 aria-hidden:opacity-0"
					>
						<div className="absolute animate-ping" />
						<div className="relative" />
					</div>
				</IonTabButton>
				{featureFlags[FEATURE_FLAG.PERFECT_DAY_MENU_ITEM_ENABLED] && (
					<IonTabButton
						tab="perfect-day"
						data-attr="pages.app.index.tab.perfect-day"
						href={Routes.PerfectDay()}
						onClick={() => PreferredHaptics.impact()}
						className="group"
					>
						<IonIcon icon={sunOutlineIcon} className="size-6 group-[.tab-selected]:hidden" />
						<IonIcon icon={sunSolidIcon} className="hidden size-6 group-[.tab-selected]:block" />
						<IonLabel className="mobile-sm:block hidden text-xs font-normal">
							{t("perfect-day.name")}
						</IonLabel>
					</IonTabButton>
				)}
				<IonTabButton
					tab="core-values"
					data-attr="pages.app.index.tab.core-values"
					href={Routes.CoreValues()}
					onClick={() => PreferredHaptics.impact()}
					className="group"
				>
					<IonIcon icon={chartPieOutlineIcon} className="size-6 group-[.tab-selected]:hidden" />
					<IonIcon icon={chartPieSolidIcon} className="hidden size-6 group-[.tab-selected]:block" />
					<IonLabel className="mobile-sm:block hidden text-xs font-normal">
						{t("core-values.name")}
					</IonLabel>
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	);
}
