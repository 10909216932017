import { IonButton, IonButtons, IonIcon, IonTitle, IonToolbar } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { questionMarkCircleIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { useSelectorModalStore } from "@/lib/store/modal";
import { PerfectDaySceneReorderButton } from "./Reorder/Button";

type PerfectDayHeaderProps = {
	isCondensed?: boolean;
	setIsReorderMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PerfectDayHeader = ({
	isCondensed = false,
	setIsReorderMode,
}: PerfectDayHeaderProps) => {
	const { t } = useTranslation();
	const presentModal = useSelectorModalStore.use.present();

	return (
		<IonToolbar
			className={
				isCondensed
					? "ion-px-0"
					: "ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]"
			}
		>
			<IonTitle
				className={
					isCondensed
						? "ion-text-brown-700 p-0 text-start text-3xl font-bold"
						: "ion-text-brown-700 text-lg font-bold"
				}
			>
				{t("perfect-day.name")}
			</IonTitle>
			<IonButtons collapse slot="primary">
				<PerfectDaySceneReorderButton
					onClick={() => setIsReorderMode((prevValue: boolean) => !prevValue)}
				/>
				<IonButton
					onClick={() => presentModal("perfectDayExplainer")}
					color="light"
					className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
				>
					<IonIcon icon={questionMarkCircleIcon} />
				</IonButton>
			</IonButtons>
		</IonToolbar>
	);
};
