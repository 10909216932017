import { IonModal, IonSkeletonText, IonText, useIonRouter } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { motion } from "motion/react";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { CoreValueAvatarWithBackground } from "@/components/CoreValue/AvatarWithBackground";
import { CoreValueRankingRow } from "@/components/CoreValue/RankingRow";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { LoadingText } from "@/components/Loading/LoadingText";
import { fadeAnimation } from "@/components/Modal/animations";
import { backendService } from "@/lib/backend";
import { queryOptions_coreValues } from "@/lib/query/functions/core-values/all";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { useQueryStream } from "@/lib/query/hooks/useQueryStream";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { jsonParse } from "@/utils/json/parse";

const MotionButton = motion.create(Button);

export function ModalReflectionAnalysisGenerate() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const router = useIonRouter();
	const contentRef = useRef<HTMLIonContentElement>(null);
	const modalRevealRef = useRef<HTMLIonModalElement>(null);
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.reflectionAnalysisGenerate();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const [myProgression, queryMyProgression] = useMyProgression();

	const coreValueLoadingAnimation = {
		transitionDurationInSeconds: 0.75,
		transitionDelayInSeconds: 0.75,
		transitionAfterElementAmount: 2,
		skeletonElementAmount: 15,
	};

	const queryAllCoreValues = useQuery({ ...queryOptions_coreValues(), enabled: modal.isOpen });

	const [streamedCoreValues, queryStream] = useQueryStream({
		queryKey: ["core-values", "reflection", "analysis-stream"],
		queryFn: (params) =>
			backendService.corevalues.coreValuesControllerGenerateCoreValueAnalysisStream(
				{ type: "reflection" },
				params,
			),
	});

	const parsedCoreValues = jsonParse({
		input: streamedCoreValues || "[]",
		schema: z.array(
			z
				.object({
					name: z.string().nullish(),
					score: z.coerce.number().nullish(),
					reason: z.string().nullish(),
				})
				.passthrough(),
		),
	});

	const coreValues = parsedCoreValues
		?.filter((coreValue) => queryAllCoreValues.data?.some((value) => value.name === coreValue.name))
		.map((coreValue) => ({
			...coreValue,
			// Enrich with actual emojis and colors
			...queryAllCoreValues.data?.find((value) => value.name === coreValue.name),
		}));

	const isBusy = queryStream.isFetching;
	const hasCompleted = !isBusy && queryStream.isFetched;
	const hasValue = !!parsedCoreValues && parsedCoreValues.length > 0;
	const hasInvalidCompleted = hasCompleted && !hasValue;

	return (
		<>
			<IonModal
				ref={modalRevealRef}
				data-attr="modal.reflection.analysis.generate.splash"
				isOpen={modal.isOpen}
				onWillPresent={() => {
					present("reflectionAnalysisGenerate");
					posthog.capture("Modal Reflection Analysis Generate Present");
				}}
				onWillDismiss={() => modalRef.current?.present()}
				onClick={() => modalRevealRef.current?.dismiss()}
				enterAnimation={(baseEl) => fadeAnimation(baseEl).direction("normal")}
				leaveAnimation={(baseEl) => fadeAnimation(baseEl).direction("reverse")}
				className="!ion-rounded-none ion-h-full ion-w-full cursor-pointer"
			>
				<Content
					fullscreen
					scrollY={false}
					className="part-background:bg-gradient-to-b part-background:from-orange-500 part-background:to-[#FF944C] part-scroll:items-center part-scroll:justify-center part-scroll:gap-3 part-scroll:text-center part-scroll:text-white"
				>
					<IonText className="text-4xl font-bold">
						{t("modal.reflection.analysis.generate.title")}
					</IonText>
					<IonText className="font-semibold text-gray-100">
						{t("modal.reflection.analysis.generate.text")}
					</IonText>
				</Content>
			</IonModal>

			<IonModal
				ref={modalRef}
				onDidPresent={() => queryStream.refetch()}
				onWillDismiss={() => {
					dismiss("reflectionAnalysisGenerate");
					posthog.capture("Modal Reflection Analysis Generate Dismiss");
				}}
				onDidDismiss={() => {
					const friendCheckCompleted =
						myProgression.friend_check === "CLOSED" ||
						myProgression.friend_check === "SUMMARIZED" ||
						myProgression.friend_check === "ANALYSED";

					posthog.capture("Reflection Analysis", {
						completedBeforeFriendCheck: !friendCheckCompleted,
						coreValues: coreValues?.map((coreValue) => coreValue.name),
					});
					if (friendCheckCompleted) {
						posthog.capture("Core Values Ready");
					}
					router.push(Routes.CoreValues());
					queryMyProgression.refetch();

					setTimeout(() => {
						queryMyProgression.refetch();
					}, 500);
				}}
				backdropDismiss={false}
			>
				<Content ref={contentRef} fullscreen inModal className="part-scroll:gap-4">
					<CoreValueAvatarWithBackground streamed coreValues={coreValues} />

					<IonText className="text-brown-700 px-4 py-2 text-center text-pretty">
						{t("modal.reflection.analysis.view.text")}
					</IonText>

					{!queryStream.isFetched && !hasValue && (
						<p className="text-md text-brown-700 text-center font-semibold">
							<LoadingText />
						</p>
					)}

					<ol className="flex flex-1 flex-col gap-3">
						{coreValues?.map((coreValue, index) => (
							<CoreValueRankingRow
								key={index}
								data-attr="modal.reflection.analysis.core-value"
								data={{ ...coreValue, number: index + 1 }}
								transition={{
									duration: coreValueLoadingAnimation.transitionDurationInSeconds,
									delay: coreValueLoadingAnimation.transitionDelayInSeconds,
								}}
								onAnimationComplete={() => {
									if (
										index !== coreValues.length - 1 &&
										index % coreValueLoadingAnimation.transitionAfterElementAmount !== 1
									)
										return;
									const elements = document.querySelectorAll(
										'[data-attr="modal.reflection.analysis.core-value"]',
									);
									const lastElement =
										elements[
											elements.length - coreValueLoadingAnimation.transitionAfterElementAmount
										];
									lastElement?.scrollIntoView({ behavior: "smooth", block: "center" });
								}}
								onClick={() => present("coreValuesDetail", { item: coreValue })}
								className="snap-start"
							/>
						))}
					</ol>

					{(!queryStream.isFetched || isBusy) && (
						<div className="flex flex-col gap-3">
							{Array.from({
								length: Math.max(
									coreValueLoadingAnimation.skeletonElementAmount - (coreValues?.length || 0),
									0,
								),
							}).map((_, index) => (
								<IonSkeletonText key={index} animated className="h-12 snap-start rounded-xl" />
							))}
						</div>
					)}

					<MotionButton
						data-attr="modal.reflection.analysis.generate.next"
						className="mt-auto snap-end text-lg font-bold"
						onClick={() => modalRef.current?.dismiss()}
						initial="hidden"
						animate={hasCompleted && !hasInvalidCompleted ? "visible" : "hidden"}
						variants={{
							visible: { opacity: 1, pointerEvents: "auto" },
							hidden: { opacity: 0, pointerEvents: "none" },
						}}
						transition={{ delay: 3.5, duration: 0.5 }}
					>
						{t("modal.reflection.analysis.generate.next")}
					</MotionButton>
				</Content>
			</IonModal>
		</>
	);
}
