import { IonModal } from "@ionic/react";
import { IonText } from "@ionic/react";
import { useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { Button } from "@/components/Global/Button";
import { backendService } from "@/lib/backend";
import { queryOptions_friendCheckSubmissions } from "@/lib/query/functions/friend-check/submissions";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalDevDeleteFriendcheck() {
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.devDeleteFriendcheck();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();

	const friendChecks = useQuery({
		...queryOptions_friendCheckSubmissions(),
		enabled: modal.isOpen,
	});

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => present("devDeleteFriendcheck")}
			onWillDismiss={() => dismiss("devDeleteFriendcheck")}
			className="ion-h-auto"
		>
			<div className="ion-padding flex flex-col gap-6">
				<IonText className="text-brown-700 text-2xl font-bold">{"Delete Friend Check"}</IonText>

				<ol className="flex flex-col gap-2">
					{friendChecks.data?.map((friendCheck) => (
						<li key={friendCheck.id} className="flex items-center justify-between">
							<div className="flex flex-col gap-2">
								<IonText>{friendCheck.name}</IonText>
								<IonText className="text-brown-400 text-sm">{friendCheck.id}</IonText>
							</div>
							<Button
								fill="clear"
								onClick={() => {
									backendService.friendcheck.friendcheckControllerDeleteSubmission(friendCheck.id);
									friendChecks.refetch();
								}}
							>
								{"Delete"}
							</Button>
						</li>
					))}
					{friendChecks.data?.length === 0 && <IonText>{"No friend checks found"}</IonText>}
				</ol>

				<div className="flex gap-4">
					<Button
						onClick={() => {
							friendChecks.data?.forEach((friendCheck) => {
								backendService.friendcheck.friendcheckControllerDeleteSubmission(friendCheck.id);
							});
							friendChecks.refetch();
						}}
					>
						{"Delete All"}
					</Button>
					<Button
						fill="default"
						onClick={() => {
							dismiss("devDeleteFriendcheck");
						}}
					>
						{"Close"}
					</Button>
				</div>
			</div>
		</IonModal>
	);
}
