import {
	IonButton,
	IonButtons,
	IonChip,
	IonHeader,
	IonIcon,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonSkeletonText,
	IonText,
	IonTitle,
	IonToolbar,
	useIonRouter,
	useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Image_nl from "@/assets/nl/explain-reflection@2x.png";
import { CoreValueBadge } from "@/components/CoreValue/Badge";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionCard } from "@/components/Reflection/Card";
import { MultipleChoiceAnswer } from "@/components/Reflection/MultipleChoiceAnswer";
import { ReflectionProgress } from "@/components/Reflection/Progress";
import { Haptics } from "@/lib/capacitor/Haptics";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowPathIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { questionMarkCircleIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { chatbubbleLeftRight } from "@/lib/icons/@heroicons/react/24/solid";
import { queryOptions_reflection } from "@/lib/query/functions/reflection/all";
import { useMyData } from "@/lib/query/functions/user/data";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";

export function Reflection() {
	const { t, i18n } = useTranslation();
	const router = useIonRouter();
	const PreferredHaptics = usePreferredHaptics();
	const presentModal = useSelectorModalStore.use.present();
	const [myData, { query: queryMyData }] = useMyData();
	const query = useQuery(queryOptions_reflection());
	const [myProgression] = useMyProgression();

	const sortedData = useMemo(
		() =>
			[...(query.data ?? [])].sort((a, b) => {
				const completed =
					Number(!!b.reflectionChat?.messages && b.reflectionChat?.messages.length > 0) -
					Number(!!a.reflectionChat?.messages && a.reflectionChat?.messages.length > 0);

				if (completed === 0) {
					return (
						Number(a.reflectionChat?.messages.length) - Number(b.reflectionChat?.messages.length)
					);
				}

				return completed;
			}),
		[query.data, myData.reflection.questions],
	);

	const [filter, setFilter] = useState<"open" | "completed">("open");

	const items = {
		open:
			sortedData.filter(
				(reflection) =>
					reflection.reflectionChat?.status !== "CHAT_COMPLETED" &&
					reflection.reflectionChat?.status !== "FINALIZED",
			) ?? [],
		completed:
			sortedData.filter(
				(reflection) =>
					reflection.reflectionChat?.status === "CHAT_COMPLETED" ||
					reflection.reflectionChat?.status === "FINALIZED",
			) ?? [],
	} satisfies Record<typeof filter, typeof sortedData>;

	useEffect(() => {
		if (query.status === "pending") {
			return;
		}

		if (items.open.length === 0) {
			setFilter("completed");
		}
	}, [query.status, items.open.length]);

	useIonViewWillEnter(() => {
		query.refetch();
	});

	return (
		<IonPage>
			{!myData.modal.reflectionExplainerGeneral.seen ? (
				<Content className="part-scroll:justify-center">
					<img
						src={{ nl: Image_nl }[i18n.language]}
						className="mx-auto sm:max-h-[60vmin] sm:max-w-[60vmin]"
					/>

					<div className="flex flex-col items-center gap-8">
						<div className="flex flex-col items-center gap-1">
							<IonText className="text-brown-700 text-center text-3xl font-bold">
								{t("reflection.empty-page.title")}
							</IonText>
							<IonText className="text-brown-600 text-center text-lg text-pretty">
								{t("reflection.empty-page.message")}
							</IonText>
						</div>
						<Button
							data-attr="page.app.reflection.empty.action"
							expand="block"
							className="w-full text-lg font-bold sm:max-w-56"
							onClick={() => {
								const firstId = items.open[0]?.id;

								presentModal(
									"reflectionExplainerGeneral",
									firstId
										? {
												onCompleted: () => {
													presentModal("reflectionExplainerFirstQuestion", {
														id: firstId,
													});
												},
											}
										: undefined,
								);
							}}
						>
							{t("reflection.empty-page.action")}
						</Button>
					</div>
				</Content>
			) : (
				<>
					<IonHeader>
						<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
							<IonTitle className="ion-text-brown-700 text-lg font-bold">
								{t("reflection.name")}
							</IonTitle>

							<IonButtons collapse slot="primary">
								<Button
									data-attr="page.app.reflection.help"
									shape="round"
									color="light"
									size="small"
									className="part-icon:m-0 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
									onClick={() => presentModal("reflectionExplainerGeneral")}
								>
									<IonIcon slot="icon-only" icon={questionMarkCircleIcon} />
								</Button>
							</IonButtons>
						</Toolbar>
					</IonHeader>

					<Content className="part-scroll:gap-4">
						<IonHeader collapse="condense" className="-mt-(--padding-top)">
							<IonToolbar className="ion-px-0">
								<IonTitle className="ion-text-brown-700 p-0 text-start text-3xl font-bold">
									{t("reflection.name")}
								</IonTitle>

								<IonButtons collapse slot="primary">
									<Button
										data-attr="page.app.reflection.help"
										shape="round"
										color="light"
										size="small"
										className="part-icon:m-0 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
										onClick={() => presentModal("reflectionExplainerGeneral")}
									>
										<IonIcon slot="icon-only" icon={questionMarkCircleIcon} className="size-5" />
									</Button>
								</IonButtons>
							</IonToolbar>
						</IonHeader>

						<IonRefresher
							slot="fixed"
							onIonRefresh={(event) =>
								Promise.all([query.refetch(), queryMyData.refetch()]).then(() =>
									event.detail.complete(),
								)
							}
						>
							<IonRefresherContent />
						</IonRefresher>

						<div className="flex flex-col gap-4">
							{items.open.length === 0 && (
								<div className="border-brown-300 flex flex-col gap-2 rounded-2xl border-2 p-4">
									<ReflectionProgress withAction={false} />
									<IonButton
										className="font-bold"
										onClick={() => {
											presentModal(
												myProgression.reflection === "ANALYSED"
													? "reflectionAnalysisView"
													: "reflectionAnalysisGenerate",
											);
										}}
									>
										{myProgression.reflection === "ANALYSED"
											? t("reflection.progress.view-analysis")
											: t("reflection.progress.analyse-the-reflection")}
									</IonButton>
								</div>
							)}
							{items.open.length > 0 && (
								<div className="text-brown-700 *:border-brown-300 *:aria-selected:bg-brown-100 flex flex-row gap-2 text-sm font-semibold tabular-nums select-none *:border *:bg-transparent *:aria-selected:border-transparent">
									<IonChip
										aria-selected={filter === "open" ? "true" : "false"}
										data-attr="page.app.reflection.filter.open"
										onClick={() => {
											PreferredHaptics.impact(Haptics.ImpactStyle.Light);
											setFilter("open");
										}}
									>
										{t("reflection.filtering.filter.open", { count: items.open.length })}
									</IonChip>
									<IonChip
										aria-selected={filter === "completed" ? "true" : "false"}
										data-attr="page.app.reflection.filter.completed"
										onClick={() => {
											PreferredHaptics.impact(Haptics.ImpactStyle.Light);
											setFilter("completed");
										}}
									>
										{t("reflection.filtering.filter.completed", { count: items.completed.length })}
									</IonChip>
								</div>
							)}

							{query.isLoading ? (
								Array.from({ length: 3 }).map((_, index) => (
									<IonSkeletonText
										key={index}
										animated
										className="border-brown-200 bg-brown-100 ion-bg-brown-100 ion-rounded-3xl h-[9.5rem] border"
									/>
								))
							) : items[filter].length === 0 ? (
								<span className="px-4 py-8 text-center text-sm font-semibold">
									{t("reflection.filtering.empty")}
								</span>
							) : (
								items[filter].map((reflection) => {
									const lastMessage =
										reflection.reflectionChat?.messages[
											reflection.reflectionChat.messages.length - 1
										];
									return (
										<ReflectionCard
											key={reflection.id}
											className="flex flex-col gap-4"
											onClick={() => {
												PreferredHaptics.impact();
												if (!myData.modal.reflectionExplainerGeneral.seen) {
													presentModal("reflectionExplainerGeneral", {
														onCompleted: () =>
															presentModal("reflectionExplainerFirstQuestion", {
																id: reflection.id,
															}),
													});
												} else if (
													reflection.reflectionChat?.status === "CHAT_COMPLETED" ||
													reflection.reflectionChat?.status === "FINALIZED"
												) {
													router.push(Routes.ReflectionDetail({ id: reflection.id }));
												} else if (
													reflection.reflectionChat?.messages.length &&
													reflection.reflectionChat?.messages.length > 0
												) {
													router.push(Routes.ReflectionQuestion({ id: reflection.id }));
												} else {
													presentModal("reflectionQuestionFirstAnswer", {
														id: reflection.id,
													});
												}
											}}
										>
											<strong className="flex-1 text-xl font-semibold text-pretty">
												{reflection.name}
											</strong>
											{myData.reflection.questions[reflection.id]?.content &&
												reflection.reflectionChat?.status === "IN_PROGRESS" && (
													<div className="flex gap-1">
														<div className="text-md font-bold text-orange-500">{t("concept")}:</div>
														<div data-mask-text className="text-md font-normal text-pretty">
															{myData.reflection.questions[reflection.id]?.content}
														</div>
													</div>
												)}
											{reflection.initialMessageChoices?.length &&
											reflection.initialMessageChoices.length > 0 ? (
												<MultipleChoiceAnswer
													selected={reflection.reflectionChat?.initialMessageChosen ?? []}
												/>
											) : (
												<div data-mask-text className="font-normal">
													{reflection.reflectionChat?.initialMessageAnswer}
												</div>
											)}
											{lastMessage && reflection.reflectionChat?.status === "IN_PROGRESS" && (
												<div className="bg-brown-200 relative flex items-center gap-2 rounded-2xl px-3 py-2">
													<div className="absolute top-[-2px] right-[-3px] size-[14px] rounded-full border-[3px] border-orange-300 bg-orange-500" />
													<IonIcon
														src={chatbubbleLeftRight}
														className="text-brown-400 h-5 min-w-5"
													/>
													<p className="text-brown-700 truncate text-sm font-medium">
														{lastMessage.content}
													</p>
												</div>
											)}

											{reflection.reflectionChat?.status === "CHAT_COMPLETED" ||
												(reflection.reflectionChat?.status === "FINALIZED" && (
													<>
														<div className="flex flex-wrap gap-1">
															{reflection.reflectionChat?.status === "FINALIZED" ? (
																reflection.reflectionChat.generatedCoreValues.map((coreValue) => (
																	<CoreValueBadge key={coreValue.id} value={coreValue} />
																))
															) : (
																<IonChip
																	className="bg-brown-200 text-brown-700 m-0 min-h-0 py-1"
																	data-attr="page.app.reflection.card.core-value"
																	onClick={() =>
																		router.push(Routes.ReflectionQuestion({ id: reflection.id }))
																	}
																>
																	{t("reflection.core-values-generation.title")}
																	<IonIcon icon={arrowPathIcon} className="size-4" />
																</IonChip>
															)}
														</div>
													</>
												))}
										</ReflectionCard>
									);
								})
							)}
						</div>
					</Content>
				</>
			)}
		</IonPage>
	);
}
