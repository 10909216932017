import { IonBackButton, IonButtons, IonHeader, IonIcon, IonPage, useIonRouter } from "@ionic/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { CoreValueBadge } from "@/components/CoreValue/Badge";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { NotFound } from "@/components/Global/NotFound";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils/extract";
import { arrowLeftIcon, arrowRightIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { queryOptions_coreValuesChosen } from "@/lib/query/functions/core-values/chosen";
import { Routes } from "@/lib/router";
import { logger } from "@/logger";

export function CoreValuesDetails() {
	const { t } = useTranslation();
	const params = Routes.CoreValuesDetails.useParams();
	const router = useIonRouter();

	const chosenCoreValueQuery = useQuery({
		queryKey: ["core-value", params.id],
		queryFn: () =>
			extractionWrapper(
				backendService.corevalues.coreValuesControllerGetChosenCoreValue(params.id!),
			),
		enabled: !!params.id,
	});

	const chosenCoreValue = chosenCoreValueQuery.data;

	const chosenCoreValueSourcesQuery = useQuery({
		queryKey: ["core-value-sources", chosenCoreValue?.coreValue.id],
		queryFn: () =>
			extractionWrapper(
				backendService.corevalues.coreValuesControllerGetCoreValueSources(
					chosenCoreValue?.coreValue.id || "",
				),
			),
		enabled: !!chosenCoreValue?.coreValue.id,
	});

	const chosenCoreValuesQuery = useQuery({
		...queryOptions_coreValuesChosen(),
		enabled: !!params.id,
	});

	const otherCoreValues = chosenCoreValuesQuery.data?.filter(
		(coreValue) => coreValue.id !== params.id,
	);

	const offsets = useMemo(() => {
		return [0, 0, 0, 0].map(() => Math.random() * 1 + 5.5) || [];
	}, [params.id]);
	const rotations = useMemo(() => {
		return [0, 0, 0, 0].map(() => Math.random() * 40 + 25) || [];
	}, [params.id]);
	const sizes = useMemo(() => {
		return [0, 0, 0, 0].map(() => Math.random() * 40 + 80) || [];
	}, [params.id]);

	if (!chosenCoreValueQuery.isLoading && !chosenCoreValueQuery.data) {
		return (
			<IonPage>
				<IonHeader className="ion-no-border">
					<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
						<IonButtons slot="secondary">
							<IonBackButton
								data-attr="page.core-values.detail.back"
								data-touch-target
								defaultHref={Routes.CoreValues()}
								color="light"
								className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
								icon={arrowLeftIcon}
								text=""
							/>
						</IonButtons>
					</Toolbar>
				</IonHeader>
				<Content>
					<NotFound
						title={t("page.core-values.not-found.title")}
						description={t("page.core-values.not-found.description")}
					/>
				</Content>
			</IonPage>
		);
	}

	const sources = chosenCoreValueSourcesQuery.data;

	return (
		<IonPage>
			<IonHeader className="ion-no-border bg-brown-100">
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))] relative">
					<IonButtons slot="secondary">
						<IonBackButton
							data-attr="page.core-values.detail.back"
							data-touch-target
							defaultHref={Routes.CoreValues()}
							color="light"
							className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
							icon={arrowLeftIcon}
							text=""
						/>
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<Content
				className="part-scroll:gap-8 part-scroll:p-0"
				withTopPadding={false}
				withBottomPadding={false}
				fullscreen={false}
			>
				<div className="bg-brown-100 rounded-b-3xl p-4">
					<div className="flex flex-col items-center gap-4">
						<div className="relative py-8">
							<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
								{otherCoreValues?.splice(0, 4)?.map((coreValue, i) => {
									return (
										<div
											key={i}
											style={{
												width: `${offsets[i]}rem`,
												transform: `rotate(${rotations[i] + 90 * i}deg) translate(0, -50%)`,
											}}
											className="absolute top-1/2 right-1/2 origin-top-right"
										>
											<div
												className="text-1xl flex size-8 origin-center items-center justify-center rounded-full"
												style={{
													backgroundColor: coreValue?.coreValue.color + "30",
													transform: `rotate(-${rotations[i] + 90 * i}deg) scale(${sizes[i]}%)`,
												}}
											>
												{coreValue?.coreValue.emoji}
											</div>
										</div>
									);
								})}
							</div>
							<div className="flex flex-col items-center gap-2">
								<div
									style={{
										backgroundColor: chosenCoreValue?.coreValue.color + "30",
									}}
									className="flex size-24 items-center justify-center rounded-full text-6xl"
								>
									{chosenCoreValue?.coreValue.emoji}
								</div>
							</div>
						</div>

						<h1 className="text-brown-700 text-2xl font-bold">
							{chosenCoreValue?.customName || chosenCoreValue?.coreValue.name}
						</h1>
						<p data-mask-text className="text-brown-600">
							{chosenCoreValue?.description || chosenCoreValue?.reason}
						</p>

						{chosenCoreValue?.customName && (
							<Button
								data-attr="page.core-values.detail.continue-personalizing"
								className="bg-brown-200 text-brown-600 w-full rounded-2xl text-sm"
								fill="clear"
								onClick={() => {
									router.push(Routes.CoreValuesChat({ id: chosenCoreValue?.id }));
								}}
							>
								<div className="div flex flex-col gap-2 text-start">
									<p>{t("core-values.page.details.continue-personalizing.text")}</p>
									<p className="flex items-center gap-2 font-semibold">
										{t("core-values.page.details.continue-personalizing.cta")}
										<IonIcon icon={arrowRightIcon} className="text-brown-400 size-4" />
									</p>
								</div>
							</Button>
						)}
						{!chosenCoreValue?.customName && (
							<Button
								data-attr="page.core-values.detail.personalize"
								className="w-full text-lg font-bold"
								onClick={() => {
									router.push(Routes.CoreValuesChat({ id: chosenCoreValue?.id }));
								}}
							>
								{t("core-values.page.details.personalize")}
							</Button>
						)}
					</div>
				</div>
				{(sources?.friendchecks.length || 0) > 0 ||
					((sources?.reflections.length || 0) > 0 && (
						<div className="px-4 pb-4">
							<div className="flex flex-col gap-4">
								<p className="text-brown-700 text-xl font-bold">
									{t("core-values.page.details.sources.title")}
								</p>
								{sources?.reflections.map((reflection) => {
									logger.log(reflection);
									return (
										<div
											key={reflection.id}
											className="bg-brown-100 flex flex-col gap-2 rounded-3xl p-4"
										>
											<p className="text-brown-500 text-sm font-semibold">
												{t("core-values.page.details.sources.reflection")}
											</p>
											<p className="text-brown-600 text-xl font-semibold">
												{reflection.reflection?.name}
											</p>
											<p data-mask-text className="text-brown-700">
												{reflection.userSummary}
											</p>
											<div className="flex flex-wrap gap-1">
												{reflection.generatedCoreValues &&
													reflection.generatedCoreValues.length > 0 &&
													reflection.generatedCoreValues.map((coreValue, i) => (
														<CoreValueBadge key={i} value={coreValue} />
													))}
											</div>
										</div>
									);
								})}
								{sources?.friendchecks.map((friendcheck) => {
									return (
										<div
											key={friendcheck.id}
											className="bg-brown-100 flex flex-col gap-2 rounded-3xl p-4"
										>
											<p className="text-brown-500 text-sm font-semibold">
												{t("core-values.page.details.sources.friendcheck")}
											</p>
											<p data-mask-text className="text-brown-600 text-lg font-semibold">
												{friendcheck.reason}
											</p>
											<div className="flex flex-wrap gap-1">
												<CoreValueBadge key={friendcheck.id} value={friendcheck} />
											</div>
										</div>
									);
								})}
							</div>
						</div>
					))}
			</Content>
		</IonPage>
	);
}
