import { IonButton, IonIcon, useIonViewDidEnter } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { useChat } from "@/components/Chat/store";
import { paperAirplaneIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { FormInput } from "../Form/Input";

export function ChatInput({
	defaultMessage,
	canSendMessage,
	focusOnLoad,
	onSubmit,
}: {
	defaultMessage?: string;
	canSendMessage?: boolean;
	focusOnLoad?: boolean;
	/**
	 * Called when the user submits the message.
	 *
	 * @param message Message to send.
	 * @returns Did send successfully?
	 */
	onSubmit?: (message: string) => Promise<boolean>;
}) {
	const chat = useChat();
	const { t } = useTranslation();
	const inputRef = useRef<HTMLIonInputElement>(null);

	useIonViewDidEnter(() => {
		if (focusOnLoad && inputRef.current) {
			inputRef.current?.setFocus();
		}
	}, [focusOnLoad]);

	const form = useForm({
		defaultValues: {
			message: defaultMessage || "",
		},
		onSubmit: async (data) => {
			if (chat.isResponding || !data.value.message) {
				return;
			}
			if (!onSubmit) {
				return;
			}
			if (await onSubmit(data.value.message)) {
				chat.setStreamAssistantMessage(null);
				chat.setStreamUserMessage(data.value.message, true);
				form.reset();
			}
		},
	});

	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();
				event.stopPropagation();
				form.validateAllFields("submit");
				void form.handleSubmit();
			}}
			className="bg-brown-100 flex items-center rounded-2xl pr-2"
		>
			<form.Field
				name="message"
				children={(field) => (
					<FormInput ref={inputRef} type="text" placeholder={t("chat.placeholder")} field={field} />
				)}
			/>

			<form.Subscribe
				selector={(state) => [state.isSubmitting]}
				children={() => (
					<IonButton
						disabled={chat.isResponding || canSendMessage === undefined ? false : !canSendMessage}
						type="submit"
					>
						<IonIcon slot="icon-only" icon={paperAirplaneIcon}></IonIcon>
					</IonButton>
				)}
			/>
		</form>
	);
}
