import { type StandardSchemaV1Issue } from "@tanstack/react-form";

export function getErrorText({
	language,
	errors = [],
}: {
	language: string;
	errors: any[];
}): string {
	const formatter = new Intl.ListFormat(language, { type: "conjunction" });
	const messages = (errors as StandardSchemaV1Issue[])
		.map((error) => error.message)
		.filter(Boolean);

	if (messages.length === 0) {
		return "";
	}

	return formatter.format(messages);
}
