import { IonIcon } from "@ionic/react";
import { lightBulbIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { cx } from "@/lib/style/cva.config";

export function AuthStrategyAlert(props: React.ComponentPropsWithoutRef<"div">) {
	return (
		<div
			{...props}
			className={cx("bg-brown-100 flex flex-row gap-4 rounded-2xl p-4 text-sm", props.className)}
		>
			<span>
				<IonIcon icon={lightBulbIcon} className="size-4" />
			</span>
			<span>{props.children}</span>
		</div>
	);
}
