import { IonSkeletonText, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { motion } from "motion/react";
import { type ReflectionSummary } from "@/lib/backend/api";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { useMyData } from "@/lib/query/functions/user/data";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";
import { DashboardReflectionWidgetCard as Card } from "./Card";

export function DashboardReflectionWidgetStack({
	isLoading = false,
	data,
	...props
}: React.ComponentPropsWithoutRef<"div"> & { isLoading?: boolean; data: ReflectionSummary[] }) {
	const router = useIonRouter();
	const PreferredHaptics = usePreferredHaptics();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [myData] = useMyData();
	const presentModal = useSelectorModalStore.use.present();

	const reflections = data.filter(
		(reflection) =>
			reflection.reflectionChat?.status !== "CHAT_COMPLETED" &&
			reflection.reflectionChat?.status !== "FINALIZED",
	);

	return (
		<div {...props} className={cx("flex flex-col items-center gap-2", props.className)}>
			<div className="relative isolate h-48 w-full">
				{isLoading
					? Array.from({ length: 3 }).map((_, index) => (
							<IonSkeletonText
								key={index}
								animated
								className="border-brown-200 bg-brown-100 ion-bg-brown-100 ion-rounded-3xl absolute h-[9.5rem] border"
								style={{
									transform: `scaleX(${1 - 0.05 * index})`,
									zIndex: -10 * index,
									bottom: `${1 * index}rem`,
								}}
							/>
						))
					: reflections.map((reflection, index) => {
							return (
								<Card
									key={reflection.id}
									data-attr="dashboard.reflection-widget.card"
									reflectionSummary={reflection}
									index={index}
									currentIndex={currentIndex}
									maxIndex={reflections.length}
									handleNext={() => setCurrentIndex((c) => ++c % reflections.length)}
									onClick={() => {
										PreferredHaptics.impact();
										if (!myData.modal.reflectionExplainerGeneral.seen) {
											presentModal("reflectionExplainerGeneral", {
												onCompleted: () =>
													presentModal("reflectionExplainerFirstQuestion", {
														id: reflection.id,
													}),
											});
										} else if (
											reflection.reflectionChat?.messages.length &&
											reflection.reflectionChat?.messages.length > 0
										) {
											router.push(Routes.ReflectionQuestion({ id: reflection.id }));
										} else {
											presentModal("reflectionQuestionFirstAnswer", {
												id: reflection.id,
											});
										}
									}}
								>
									<strong className="line-clamp-2 flex-1 text-xl font-semibold text-pretty">
										{reflection.name}
									</strong>
								</Card>
							);
						})}
			</div>

			{isLoading ? (
				<div className="relative isolate flex w-fit flex-row items-center justify-center gap-2 py-2 *:size-2.5 *:rounded-full!">
					{Array.from({ length: 3 }).map((_, index) => (
						<button key={index} className={index === 0 ? "bg-brown-500" : "bg-brown-300"} />
					))}
				</div>
			) : (
				reflections.length > 1 && (
					<div className="relative isolate flex w-fit flex-row items-center justify-center gap-2 py-2 *:size-2.5 *:rounded-full!">
						{reflections.map((reflection, index) => (
							<button
								key={reflection.id}
								className="bg-brown-300"
								onClick={() => {
									PreferredHaptics.impact();
									setCurrentIndex(index);
								}}
							/>
						))}
						<motion.div
							className="bg-brown-500 pointer-events-none absolute left-0 z-10"
							initial={{ x: "0rem" }}
							animate={{ x: `${currentIndex * 1.125}rem` }}
							transition={{ type: "spring", duration: 0.3 }}
						/>
					</div>
				)
			)}
		</div>
	);
}
