import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { random, sampleSize } from "lodash-es";
import { motion, useAnimation } from "motion/react";
import { AvatarMe } from "@/components/AvatarMe";
import { queryOptions_coreValues } from "@/lib/query/functions/core-values/all";
import { cx } from "@/lib/style/cva.config";

export function CoreValueAvatarWithBackground({
	streamed = false,
	coreValues = [],
	reveal = false,
	...props
}: React.ComponentPropsWithoutRef<"div"> & {
	streamed?: boolean;
	coreValues?: {
		name?: string | null | undefined;
		score?: number | null | undefined;
	}[];
	reveal?: boolean;
}) {
	const queryAllCoreValues = useQuery(queryOptions_coreValues());

	const isBlurred = coreValues.length <= 0;
	const items =
		streamed || coreValues.length
			? coreValues.slice(0, 5)
			: sampleSize(queryAllCoreValues.data || [], 5);

	const values = items.map((item) => ({
		...item,
		...queryAllCoreValues.data?.find((coreValue) => coreValue.name === item.name),
		score: ("score" in item ? item?.score : null) || random(60, 100),
	}));

	const controls = useAnimation();

	useEffect(() => {}, [reveal, values, isBlurred, controls]);

	return (
		<div
			{...props}
			className={cx("relative isolate flex flex-col items-center px-4 py-8", props.className)}
		>
			<AvatarMe className="z-50 size-24" />

			<div className="absolute inset-0 transform-gpu">
				{values.map((value, index) => (
					<motion.div
						key={index}
						className={cx(
							"absolute top-[calc(50%-calc(var(--size)*0.5))] left-[calc(50%-calc(var(--size)*0.5))] grid size-(--size) origin-center transform-gpu place-content-center rounded-full will-change-transform select-none [--size:calc(--spacing(12)*var(--size-scale,1))]",
							value.score >= 95
								? "text-4xl"
								: value.score >= 90
									? "text-3xl"
									: value.score >= 80
										? "text-2xl"
										: "text-xl",
						)}
						initial={{
							backgroundColor: "#FFF2C640",
							"--size-scale": reveal ? 0.6 : 1,
							filter: "blur(4px)",
							...(!reveal
								? { x: 0, y: 0 }
								: {
										...[
											{ x: 120, y: -140 },
											{ x: -150, y: 70 },
											{ x: 140, y: 80 },
											{ x: -110, y: -110 },
											{ x: -40, y: 160 },
										][index],
									}),
						}}
						custom={index}
						animate={{
							backgroundColor: `${value.color ?? "#FFF2C6"}40`,
							"--size-scale": value.score * 0.015,
							filter: `blur(${isBlurred ? 4 : 0}px)`,
							...[
								{ x: 45, y: -50 },
								{ x: -60, y: 20 },
								{ x: 55, y: 25 },
								{ x: -40, y: -50 },
								{ x: -5, y: 65 },
							][index],
						}}
						transition={{
							duration: 0.5 / (streamed ? 2 : 1),
							delay: index * (0.5 / (streamed ? 2 : 1)),
						}}
						style={{ contentVisibility: "auto" }}
					>
						{value.emoji}
					</motion.div>
				))}
			</div>
		</div>
	);
}
