import { IonContent } from "@ionic/react";
import { forwardRef } from "react";
import { cx } from "@/lib/style/cva.config";

export const Content = forwardRef<
	HTMLIonContentElement,
	React.ComponentProps<typeof IonContent> & {
		withTopPadding?: boolean;
		withBottomPadding?: boolean;
		inModal?: boolean;
	}
>(function Content({ withTopPadding, withBottomPadding, inModal, fullscreen, ...props }, ref) {
	return (
		<IonContent
			ref={ref}
			{...props}
			className={cx(
				"ion-padding part-scroll:flex part-scroll:flex-col part-scroll:gap-4 w-full",
				(withBottomPadding || fullscreen) &&
					"ion-pb-[max(var(--ion-safe-area-bottom),--spacing(4))]",
				(withTopPadding || fullscreen) && "ion-pt-[max(var(--ion-safe-area-top),--spacing(4))]",
				inModal
					? "plt-desktop:sm:part-scroll:px-4"
					: "plt-desktop:sm:part-scroll:px-[max(calc(calc(100vw-640px)/2),--spacing(4))]",
				props.className,
			)}
		>
			{props.children}
		</IonContent>
	);
});
