import { AuthStrategyDivider } from "@/components/Auth/Strategy/Divider";
import { SocialButtonApple } from "@/components/SocialButton/Apple";
import { SocialButtonGoogle } from "@/components/SocialButton/Google";
import { getOAuthUrl } from "@/lib/backend/getOAuthUrl";
import { cx } from "@/lib/style/cva.config";

export function AuthStrategyOAuth(props: React.ComponentPropsWithoutRef<"div">) {
	return (
		<div {...props} className={cx("flex flex-col gap-4", props.className)}>
			<AuthStrategyDivider />

			<div className="flex flex-col gap-3">
				<SocialButtonGoogle
					data-attr="auth.strategy.oauth.google"
					href={getOAuthUrl("google")}
					className="ion-rounded-2xl h-12"
				/>
				<SocialButtonApple
					data-attr="auth.strategy.oauth.apple"
					href={getOAuthUrl("apple")}
					className="ion-rounded-2xl h-12"
				/>
			</div>

			{props.children}
		</div>
	);
}
