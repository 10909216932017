import { IonInput, useIonViewDidEnter } from "@ionic/react";
import { forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import { type AnyFieldApi } from "@tanstack/react-form";
import { getErrorText } from "@/components/Form/utils";
import { cx } from "@/lib/style/cva.config";
import { mergeRefs } from "@/utils/mergeRefs";

export type FormInputProps = {
	field: AnyFieldApi;
	autofocusOnIonViewDidEnter?: boolean;
};

export const FormInput = forwardRef(function FormInput(
	{
		field,
		autofocusOnIonViewDidEnter,
		...props
	}: React.ComponentProps<typeof IonInput> & FormInputProps,
	ref: React.ForwardedRef<HTMLIonInputElement>,
) {
	const { i18n } = useTranslation();
	const inputRef = useRef<HTMLIonInputElement>(null);

	useIonViewDidEnter(() => {
		if (autofocusOnIonViewDidEnter) {
			inputRef.current?.setFocus();
		}
	});

	return (
		<IonInput
			ref={mergeRefs(ref, inputRef)}
			name={field.name}
			errorText={getErrorText({ language: i18n.language, errors: field.state.meta.errors })}
			value={field.state.value}
			onIonChange={(event) => field.handleChange(String(event.detail.value))}
			onIonBlur={field.handleBlur}
			readonly={field.state.meta.isValidating || field.form.state.isSubmitting}
			labelPlacement="stacked"
			clearInput={false}
			clearOnEdit={false}
			autocapitalize="sentences"
			{...props}
			className={cx(
				"stacked",
				field.state.meta.isTouched && "ion-touched",
				!!field.state.meta.errors && field.state.meta.errors.length > 0 && "ion-invalid",
				props.className,
			)}
		/>
	);
});
