import { IonHeader, IonIcon, IonModal, IonText } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Reorder, motion, useDragControls, useMotionValue } from "motion/react";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { CoreValueAvatarWithBackground } from "@/components/CoreValue/AvatarWithBackground";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { LoadingText } from "@/components/Loading/LoadingText";
import { fadeAnimation } from "@/components/Modal/animations";
import { NumberTicker } from "@/components/magicui/number-ticker";
import { backendService } from "@/lib/backend";
import { useExtractedMutation } from "@/lib/backend/utils/hooks";
import { Share } from "@/lib/capacitor/Share";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import {
	arrowUpOnSquareIcon,
	bar3Icon,
	questionMarkCircleIcon,
	userGroupIcon,
} from "@/lib/icons/@heroicons/react/24/outline";
import { queryOptions_canShare } from "@/lib/query/functions/@capacitor/share/canShare";
import { queryOptions_coreValues } from "@/lib/query/functions/core-values/all";
import { queryOptions_coreValuesAnalysis } from "@/lib/query/functions/core-values/analysis";
import { useMyData } from "@/lib/query/functions/user/data";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { useQueryStream } from "@/lib/query/hooks/useQueryStream";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { useRaisedShadow } from "@/utils/hooks/useRaisedShadow";
import { jsonParse } from "@/utils/json/parse";

export function ModalCoreValuesCompileGeneral() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const modalConfirmRef = useRef<HTMLIonModalElement>(null);
	const modalRevealRef = useRef<HTMLIonModalElement>(null);
	const modalPreviewRef = useRef<HTMLIonModalElement>(null);
	const modalResultRef = useRef<HTMLIonModalElement>(null);
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.coreValuesCompileGeneral();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const queryAllCoreValues = useQuery({ ...queryOptions_coreValues(), enabled: modal.isOpen });
	const queryCanShare = useQuery(queryOptions_canShare());
	const [myData] = useMyData();
	const [, queryMyProgression] = useMyProgression();

	const [revealStep, setRevealStep] = useState(0);

	const mutation = useExtractedMutation({
		mutationKey: ["core-values", "save"],
		mutationFn: backendService.corevalues.coreValuesControllerSaveTopCoreValues,
	});

	const [summary, querySummaryStream] = useQueryStream({
		queryKey: ["core-values", "summary-stream"],
		queryFn: (params) =>
			backendService.corevalues.coreValuesControllerGenerateCoreValueSummaryStream(
				{ type: "corevalues" },
				params,
			),
	});

	const parsedSummary = jsonParse({
		input: summary || "",
		schema: z.object({ summary: z.string().nullish() }),
	});

	const queryCoreValuesReflection = useQuery({
		...queryOptions_coreValuesAnalysis({ type: "reflection" }),
		enabled: modal.isOpen,
	});
	const queryCoreValuesFriendCheck = useQuery({
		...queryOptions_coreValuesAnalysis({ type: "friendcheck" }),
		enabled: modal.isOpen,
	});

	const compiledCoreValues = [
		...(queryCoreValuesReflection.data?.map((coreValue) => ({
			type: "reflection" as const,
			...coreValue,
		})) ?? []),
		...(queryCoreValuesFriendCheck.data
			?.filter((coreValue) => myData.coreValues.savedFriendCheck.includes(coreValue.id))
			.map((coreValue) => ({
				type: "friend-check" as const,
				...coreValue,
			})) ?? []),
	]
		.map((coreValue) => ({
			...coreValue,
			// Enrich with actual emojis and colors
			...queryAllCoreValues.data?.find((value) => value.name === coreValue.name),
		}))
		.sort((a, b) => (a.score > b.score ? -1 : 1));

	const [coreValues, setCoreValues] = useState(compiledCoreValues);

	useEffect(() => {
		if (modal.isOpen) {
			setCoreValues(compiledCoreValues);
		}
	}, [modal.isOpen]);

	return (
		<>
			<IonModal
				ref={modalRef}
				isOpen={modal.isOpen}
				onWillPresent={() => {
					present("coreValuesCompileGeneral");
					posthog.capture("Modal CoreValues Compile General Present");
				}}
				onDidPresent={() => {
					if (!myData.modal.coreValuesCompileExplainer.seen) {
						present("coreValuesCompileExplainer");
					}
				}}
				onWillDismiss={() => {
					dismiss("coreValuesCompileGeneral");
					posthog.capture("Modal CoreValues Compile General Dismiss");
				}}
				onDidDismiss={() => {
					queryMyProgression.refetch();

					setTimeout(() => {
						queryMyProgression.refetch();
					}, 500);
				}}
			>
				<IonHeader className="ion-no-border">
					<Toolbar className="ion-py-2">
						<Button
							data-attr="modal.core-values.compile.general.dismiss"
							slot="secondary"
							className="ion-bg-a-brown-300 ion-bg-brown-300 ion-bg-f-brown-300 ion-text-a-brown-700 ion-text-brown-700 ion-text-f-brown-700 ion-text-h-brown-700 ion-rounded-full ion-p-2.5 min-h-0"
							onClick={() => modalRef.current?.dismiss()}
						>
							<IonIcon slot="icon-only" icon={arrowLeftIcon} className="size-5" />
						</Button>

						<Button
							slot="primary"
							data-attr="modal.core-values.compile.general.help"
							className="ion-bg-a-brown-300 ion-bg-brown-300 ion-bg-f-brown-300 ion-text-a-brown-700 ion-text-brown-700 ion-text-f-brown-700 ion-text-h-brown-700 ion-rounded-full ion-p-2.5 min-h-0"
							onClick={() => present("coreValuesCompileExplainer")}
						>
							<IonIcon slot="icon-only" icon={questionMarkCircleIcon} className="size-5" />
						</Button>
					</Toolbar>
				</IonHeader>
				<Content fullscreen inModal className="part-scroll:pb-24">
					<div className="flex flex-col items-center p-4 pb-10 *:text-center">
						<IonText className="text-brown-700 text-2xl font-bold">
							{t("modal.compile-core-values.title")}
						</IonText>
						<IonText className="text-brown-600 text-base text-balance">
							{t("modal.compile-core-values.description")}
						</IonText>
					</div>

					<Reorder.Group
						as="ol"
						axis="y"
						values={coreValues}
						onReorder={setCoreValues}
						className="-mx-4 flex flex-1 flex-col gap-4 px-4"
					>
						<div className="bg-brown-300 order-6 h-0.5 w-full shrink-0 rounded-full" />
						{coreValues.map((coreValue, index) => (
							<Row key={coreValue.id} coreValue={coreValue} index={index} />
						))}
					</Reorder.Group>

					<Button
						slot="fixed"
						data-attr="modal.core-values.compile.general.trigger"
						className="inset-x-4 bottom-[max(var(--ion-safe-area-bottom),--spacing(4))] rounded-2xl text-lg font-bold"
						onClick={() => modalConfirmRef.current?.present()}
					>
						{t("modal.compile-core-values.confirm.trigger")}
					</Button>
				</Content>
			</IonModal>

			<IonModal
				ref={modalConfirmRef}
				initialBreakpoint={1}
				breakpoints={[0, 1]}
				className="ion-h-auto"
				onDidDismiss={(event) => {
					if (event.detail.data !== true) {
						return;
					}

					modalRevealRef.current?.present();
				}}
				backdropDismiss={false}
			>
				<div className="ion-padding flex flex-col gap-8 pt-12 pb-8">
					<div className="flex flex-col gap-2">
						<strong className="text-brown-700 text-2xl font-bold">
							{t("modal.compile-core-values.confirm.title")}
						</strong>
						<p className="text-brown-600 text-base font-normal">
							{t("modal.compile-core-values.confirm.description")}
						</p>
					</div>
					<div className="flex flex-col gap-3">
						{coreValues.slice(0, 5).map((coreValue) => (
							<div
								key={coreValue.id}
								className="bg-brown-100 flex items-center justify-between gap-2 rounded-xl p-3"
							>
								<span className="text-brown-700 flex flex-row gap-2 font-semibold">
									<span className="block min-w-5 text-center">{coreValue?.emoji || "❓"}</span>
									<span>{coreValue?.name || ""}</span>
								</span>
							</div>
						))}
					</div>
					<div className="flex flex-col gap-2 *:rounded-2xl *:text-lg *:font-bold">
						<Button
							data-attr="modal.core-values.compile.general.confirm.confirm"
							onClick={() => modalConfirmRef.current?.dismiss(true)}
						>
							{t("modal.compile-core-values.confirm.actions.confirm")}
						</Button>
						<Button
							data-attr="modal.core-values.compile.general.confirm.dismiss"
							fill="clear"
							color="secondary"
							onClick={() => modalConfirmRef.current?.dismiss()}
						>
							{t("modal.compile-core-values.confirm.actions.dismiss")}
						</Button>
					</div>
				</div>
			</IonModal>

			<IonModal
				ref={modalRevealRef}
				data-attr="modal.core-values.compile.general.reveal"
				onWillPresent={() => {
					mutation.mutate({
						values: coreValues
							.slice(0, 5)
							.map((coreValue) => ({ id: coreValue.id, reason: coreValue.reason })),
					});
					posthog.capture("Modal CoreValues Compile General Finished");
					posthog.capture("Core Values Compiled", {
						compiledCoreValues: compiledCoreValues.slice(0, 5).map((coreValue) => coreValue.name),
						coreValues: coreValues.slice(0, 5).map((coreValue) => coreValue.name),
					});
				}}
				onClick={() => modalRevealRef.current?.dismiss()}
				onWillDismiss={() => modalPreviewRef.current?.present()}
				enterAnimation={(baseEl) => fadeAnimation(baseEl).direction("normal")}
				leaveAnimation={(baseEl) => fadeAnimation(baseEl).direction("reverse")}
				className="!ion-rounded-none ion-h-full ion-w-full cursor-pointer"
			>
				<Content
					fullscreen
					scrollY={false}
					className="part-background:bg-gradient-to-b part-background:from-orange-500 part-background:to-[#FF944C] part-scroll:items-center part-scroll:justify-center part-scroll:gap-3 part-scroll:text-center part-scroll:text-white"
				>
					<IonText className="text-4xl font-bold">
						{t("modal.compile-core-values.reveal.title")}
					</IonText>
					<IonText className="font-semibold text-gray-100">
						{t("modal.compile-core-values.reveal.text")}
					</IonText>
				</Content>
			</IonModal>

			<IonModal
				ref={modalPreviewRef}
				data-attr="modal.core-values.compile.general.preview"
				onClick={() => {
					if (revealStep >= 0) {
						modalPreviewRef.current?.dismiss();
					} else {
						setRevealStep((current) => ++current);
					}
				}}
				onDidDismiss={() => modalResultRef.current?.present()}
				enterAnimation={(baseEl) => fadeAnimation(baseEl).direction("normal")}
				leaveAnimation={(baseEl) => fadeAnimation(baseEl).direction("reverse")}
				className="!ion-rounded-none ion-h-full ion-w-full cursor-pointer"
			>
				<Content fullscreen scrollY={false} className="part-scroll:justify-end part-scroll:py-32">
					<div className="grid flex-1 place-content-center">
						<CoreValueAvatarWithBackground coreValues={coreValues} reveal />
					</div>

					<IonText className="text-brown-400 text-center font-semibold">
						{t("modal.compile-core-values.preview.text")}
					</IonText>
				</Content>
			</IonModal>

			<IonModal
				ref={modalResultRef}
				onDidPresent={() => querySummaryStream.refetch()}
				backdropDismiss={false}
			>
				<Content fullscreen inModal>
					<div className="flex flex-col gap-8">
						<CoreValueAvatarWithBackground streamed coreValues={coreValues} />

						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.5 }}
							className="border-brown-200 bg-brown-100 flex min-h-40 flex-col gap-2 rounded-xl border p-4"
						>
							<strong className="text-brown-500 text-base font-semibold">
								{t("modal.compile-core-values.summary.title")}
							</strong>
							<div
								data-mask-text
								className="text-brown-700 block min-h-[4lh] text-lg font-semibold text-pretty"
							>
								{parsedSummary ? (
									<motion.div transition={{ staggerChildren: 0.1 }}>
										{parsedSummary?.summary?.split(" ").map((word, index) => (
											<motion.span
												key={word + index}
												initial={{ opacity: 0 }}
												animate={{ opacity: 1 }}
												transition={{ duration: 1 }}
											>
												{word}{" "}
											</motion.span>
										))}
									</motion.div>
								) : (
									<LoadingText />
								)}
							</div>
						</motion.div>
					</div>

					<div className="mt-auto flex items-end justify-between gap-16 *:min-w-32 *:flex-1 *:font-bold">
						{!!queryCanShare.data && (
							<Button
								data-attr="modal.core-values.compile.general.summary.share"
								fill="clear"
								color="secondary"
								className="bg-brown-100! rounded-2xl text-lg"
								expand="block"
								// TODO: Share a better version
								onClick={() => Share.share({ text: parsedSummary?.summary ?? "" })}
							>
								<IonIcon icon={arrowUpOnSquareIcon} slot="start" className="size-5" />
								{t("modal.compile-core-values.summary.share")}
							</Button>
						)}
						<Button
							data-attr="modal.core-values.compile.general.summary.next"
							expand="block"
							className="rounded-2xl text-lg data-[ready=false]:opacity-75"
							routerLink={Routes.CoreValues()}
							onClick={() => {
								modalRef.current?.dismiss();
								modalResultRef.current?.dismiss();
								present("coreValuesPersonalizeExplainer");
							}}
						>
							{t("modal.compile-core-values.summary.next")}
						</Button>
					</div>
				</Content>
			</IonModal>
		</>
	);
}

function Row({
	coreValue,
	index,
}: {
	coreValue:
		| {
				id: string;
				name: string;
				emoji?: string | undefined;
				color: string;
				score: number;
				reason: string;
				type: "reflection";
		  }
		| {
				id: string;
				name?: string | undefined;
				emoji?: string | undefined;
				color?: string | undefined;
				score: number;
				type: "friend-check";
		  };
	index: number;
}) {
	const { i18n } = useTranslation();
	const controls = useDragControls();

	const y = useMotionValue(0);
	const boxShadow = useRaisedShadow(y);

	return (
		<Reorder.Item
			value={coreValue}
			id={coreValue.id}
			dragListener={false}
			dragControls={controls}
			className="bg-brown-100 flex items-center justify-between gap-2 rounded-xl p-3 select-none"
			style={{ boxShadow, y, order: index + 1 }}
		>
			<span className="text-brown-700 flex flex-row gap-2 font-semibold">
				<IonText className="block min-w-6 text-end">{index + 1}.</IonText>
				<IonText className="block min-w-5 text-center">{coreValue?.emoji || "❓"}</IonText>
				<IonText>{coreValue?.name || ""}</IonText>
			</span>

			<div className="flex items-center justify-end gap-3">
				{coreValue.type === "friend-check" ? (
					<IonIcon icon={userGroupIcon} className="text-brown-400 size-5" />
				) : (
					<NumberTicker
						className="text-brown-400 text-end font-semibold"
						value={coreValue.score}
						getTextContent={(value) =>
							Intl.NumberFormat(i18n.language, { style: "unit", unit: "percent" }).format(
								value.toFixed(0),
							)
						}
						initial="hidden"
						animate={coreValue.score ? "visible" : "hidden"}
						variants={{
							visible: { opacity: 1, pointerEvents: "auto" },
							hidden: { opacity: 0, pointerEvents: "none" },
						}}
						duration={0.25}
						checkInView={false}
					/>
				)}
				<span
					data-touch-target
					className="size-6 cursor-pointer touch-none"
					onPointerDown={(event) => controls.start(event)}
				>
					<IonIcon icon={bar3Icon} className="text-brown-300 size-6" />
				</span>
			</div>
		</Reorder.Item>
	);
}
