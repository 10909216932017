import { IonIcon, IonSkeletonText, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { FriendCheckCard } from "@/components/FriendCheck/Card";
import { FriendCheckFilledCard } from "@/components/FriendCheck/FilledCard";
import { Button } from "@/components/Global/Button";
import { FRIEND_CHECK_TARGET } from "@/lib/constants";
import { arrowRightIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { queryOptions_friendCheckSubmissions } from "@/lib/query/functions/friend-check/submissions";
import { Routes } from "@/lib/router";
import { cx } from "@/lib/style/cva.config";

export function DashboardFriendCheckWidget(props: React.ComponentPropsWithoutRef<"div">) {
	const { t } = useTranslation();
	const query = useQuery(queryOptions_friendCheckSubmissions());

	return (
		<div {...props} className={cx("flex flex-col gap-1 py-2", props.className)}>
			<div className="flex flex-row items-center justify-between gap-4">
				<IonText className="text-brown-600 text-xl font-bold">
					{t("friend-check.widget.title")}
				</IonText>

				<Button
					data-attr="dashboard.friend-check-widget.action"
					fill="clear"
					className="text-brown-600 -mx-4 w-fit text-sm font-semibold"
					routerLink={Routes.FriendCheck()}
					routerDirection="root"
				>
					{t("friend-check.widget.action")}
					<IonIcon slot="end" icon={arrowRightIcon} className="text-brown-400 size-4" />
				</Button>
			</div>

			<div className="flex flex-col gap-3 *:min-h-[4.5rem]">
				{query.isLoading ? (
					Array.from({ length: 3 }).map((_, index) => (
						<IonSkeletonText key={index} animated className="m-0 rounded-2xl px-4 py-3" />
					))
				) : (
					<>
						{!query.data || query.data.length === 0 ? (
							<FriendCheckCard
								data-attr="dashboard.friend-check-widget.start"
								className="text-brown-700 ion-bg-brown-100 part-native:flex part-native:flex-1 part-native:flex-row part-native:items-center part-native:justify-between flex shadow-none"
								routerLink={Routes.FriendCheck()}
								routerDirection="root"
							>
								<strong className="text-base">{t("friend-check.widget.start-sharing")}</strong>
								<IonIcon icon={arrowRightIcon} className="size-6" />
							</FriendCheckCard>
						) : (
							query.data.map((friendCheckSubmission) => (
								<FriendCheckFilledCard
									key={friendCheckSubmission.id}
									data-attr="dashboard.friend-check-widget.card"
									data={friendCheckSubmission}
									withUnseen
									routerDirection="forward"
									routerLink={Routes.FriendCheckSubmission({
										id: friendCheckSubmission.id,
									})}
								/>
							))
						)}
						{Array.from({
							length: Math.min(2, Math.max(0, FRIEND_CHECK_TARGET - (query.data ?? []).length)),
						}).map((_, index) => (
							<div
								key={index}
								className="border-brown-300 rounded-2xl border-2 border-dashed px-4 py-3"
							/>
						))}
					</>
				)}
			</div>
		</div>
	);
}
