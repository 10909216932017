import {
	IonButton,
	IonButtons,
	IonHeader,
	IonIcon,
	IonList,
	IonReorderGroup,
	IonSpinner,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { Toast } from "@capacitor/toast";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Content } from "@/components/Global/Content";
import { backendService } from "@/lib/backend";
import type { PerfectDayScene } from "@/lib/backend/api";
import { useToast } from "@/lib/hooks/useToast";
import { checkIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { logger } from "@/logger";
import { PerfectDaySceneReorderItem } from "./Item";

interface Props {
	sceneData: PerfectDayScene[];
	onCompleted: (sceneData: PerfectDayScene[]) => void;
}

export const PerfectDaySceneReorderContent: React.FC<Props> = ({ sceneData, onCompleted }) => {
	const [scenes, setScenes] = useState(sceneData);
	const [isUpdating, setIsUpdating] = useState(false);
	const REORDERABLE_SCENES_START_INDEX = 1;
	const toast = useToast();
	const { t } = useTranslation();

	const onFinish = async () => {
		try {
			setIsUpdating(true);
			await backendService.perfectDay.perfectDayControllerUpdateSceneOrder({
				scenes: scenes.slice(1).map((scene, index) => ({
					id: scene.id,
					sortOrder: index + REORDERABLE_SCENES_START_INDEX,
				})),
			});

			toast.show(t("perfect-day.change-order.success"));
			onCompleted(scenes);
		} catch (error) {
			logger.error(error);
			Toast.show({ text: t("perfect-day.change-order-error") });
		}
	};

	return (
		<>
			<IonHeader>
				<IonToolbar className="ion-p-0 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
					<IonTitle className="ion-text-brown-700 text-lg font-bold">
						{t("perfect-day.change-order.title")}
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<Content>
				<IonHeader collapse="condense" className="-mt-(--padding-top)">
					<IonToolbar className="ion-p-0">
						<IonTitle className="ion-text-brown-700 p-0 text-start text-3xl font-bold">
							{t("perfect-day.change-order.title")}
						</IonTitle>
						<IonButtons collapse slot="primary">
							<IonButton
								data-attr="page.perfect-day.overview.reorder.back"
								onClick={() => {
									if (!isUpdating) {
										setIsUpdating(true);
										onFinish().finally(() => setIsUpdating(false));
									}
								}}
								disabled={isUpdating}
								routerDirection="back"
								color="light"
								className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-orange-500 part-native:p-1 part-native:text-xs part-native:text-brown-100 min-h-0"
							>
								{isUpdating ? <IonSpinner /> : <IonIcon icon={checkIcon} />}
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonList>
					<PerfectDaySceneReorderItem scene={scenes[0]} key={scenes[0].id} />

					<IonReorderGroup
						disabled={false}
						onIonItemReorder={(event) => {
							const reorderedScenes = [...scenes];
							const itemToMove = reorderedScenes.splice(
								event.detail.from + REORDERABLE_SCENES_START_INDEX,
								1,
							)[0];
							reorderedScenes.splice(
								event.detail.to + REORDERABLE_SCENES_START_INDEX,
								0,
								itemToMove,
							);

							// Update sortOrder based on new positions
							for (let i = REORDERABLE_SCENES_START_INDEX; i < reorderedScenes.length; i++) {
								reorderedScenes[i].sortOrder = i;
							}

							setScenes(reorderedScenes);
							event.detail.complete();
						}}
						className="mt-4 flex flex-col gap-4"
					>
						{scenes.slice(1).map((scene) => (
							<PerfectDaySceneReorderItem key={scene.id} scene={scene} />
						))}
					</IonReorderGroup>
				</IonList>
			</Content>
		</>
	);
};
