import { IonLabel, IonList, IonListHeader } from "@ionic/react";
import { cx } from "@/lib/style/cva.config";

export function SettingsSection({
	header,
	...props
}: { header?: React.ReactNode } & React.ComponentPropsWithoutRef<"div">) {
	return (
		<div {...props} className={cx("flex flex-col gap-2", props.className)}>
			{!!header && (
				<IonListHeader className="ion-text-brown-700 min-h-0 p-0 text-xl font-semibold">
					<IonLabel className="m-0">{header}</IonLabel>
				</IonListHeader>
			)}
			<IonList inset className="border-brown-200 m-0! rounded-2xl! border">
				{props.children}
			</IonList>
		</div>
	);
}
