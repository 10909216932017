import { IonChip, IonFooter, IonIcon, IonModal, IonSpinner, useIonRouter } from "@ionic/react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { motion } from "motion/react";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { CoreValueBadge } from "@/components/CoreValue/Badge";
import { Button } from "@/components/Global/Button";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionCard } from "@/components/Reflection/Card";
import { backendService } from "@/lib/backend";
import { useExtractedQuery } from "@/lib/backend/utils/hooks";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowPathIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { queryOptions_coreValues } from "@/lib/query/functions/core-values/all";
import { queryOptions_reflection } from "@/lib/query/functions/reflection/all";
import { useQueryStream } from "@/lib/query/hooks/useQueryStream";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { jsonParse } from "@/utils/json/parse";

const MotionIonChip = motion.create(IonChip);

export function ModalReflectionQuestionCompleted() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const PreferredHaptics = usePreferredHaptics();
	const queryClient = useQueryClient();
	const router = useIonRouter();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.reflectionQuestionCompleted();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const presentModal = useSelectorModalStore.use.present();

	const queryCoreValues = useQuery({ ...queryOptions_coreValues(), enabled: modal.isOpen });
	const queryReflectionChat = useExtractedQuery({
		queryKey: ["reflection", "chat", modal.id!] as const,
		queryFn: (context) =>
			backendService.reflection.reflectionControllerGetReflectionById(context.queryKey[2], {
				signal: context.signal,
			}),
		enabled: modal.isOpen && !!modal.id,
	});

	const queryReflection = useQuery({
		...queryOptions_reflection(),
		enabled: modal.isOpen,
	});

	const notCompletedItems = queryReflection.data?.filter(
		(question) =>
			question.reflectionChat?.status !== "CHAT_COMPLETED" &&
			question.reflectionChat?.status !== "FINALIZED",
	);

	const reflectionChat = queryReflectionChat.data;

	const [questionCoreValues, queryQuestionCoreValues] = useQueryStream({
		// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
		queryKey: ["reflection", "finalize", reflectionChat?.id!] as const,
		queryFn: (params, context) =>
			backendService.reflection.reflectionControllerGenerateCoreValues(context.queryKey[2], params),
		enabled: modal.isOpen && !!reflectionChat?.id && reflectionChat.status === "CHAT_COMPLETED",
	});

	const parsedQuestionCoreValues = jsonParse({
		input: questionCoreValues || "[]",
		schema: z.array(
			z.object({
				name: z.string().nullish(),
				score: z.coerce.number().nullish(),
				reason: z.string().nullish(),
			}),
		),
	});

	useEffect(() => {
		if (queryQuestionCoreValues.status === "success") {
			queryClient.invalidateQueries({ queryKey: ["reflection", "chat", modal.id] });
			posthog.capture("Reflection Question Finalized", {
				questionId: modal.id,
				coreValues: parsedQuestionCoreValues?.map((coreValue) => coreValue.name),
			});
		}
	}, [queryQuestionCoreValues.status]);

	const coreValues =
		queryQuestionCoreValues.isFetching || queryQuestionCoreValues.isFetched
			? parsedQuestionCoreValues
					?.map((coreValue) =>
						queryCoreValues.data?.find((coreValueItem) => coreValueItem.name === coreValue.name),
					)
					.filter(Boolean)
			: null;

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => {
				present("reflectionQuestionCompleted");
				posthog.capture("Modal Reflection Question Completed Present", { id: modal.id });
			}}
			onWillDismiss={() => {
				dismiss("reflectionQuestionCompleted", { id: null });
				posthog.capture("Modal Reflection Question Completed Dismiss", { id: modal.id });
			}}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="ion-h-auto"
		>
			<div className="ion-padding flex flex-col gap-2 pt-12">
				{!!reflectionChat && (
					<ReflectionCard className="flex -rotate-1 flex-col gap-3">
						<strong className="text-xl font-semibold text-pretty">
							{reflectionChat.reflection?.name}
						</strong>
						<div data-mask-text className="font-normal">
							{reflectionChat.userSummary}
						</div>
						<div className="flex flex-wrap gap-1">
							{!!coreValues && coreValues.length > 0 ? (
								coreValues.map((coreValue) => (
									<CoreValueBadge key={coreValue.id} value={coreValue} />
								))
							) : !!reflectionChat.generatedCoreValues &&
							  reflectionChat.generatedCoreValues.length > 0 ? (
								reflectionChat.generatedCoreValues.map((coreValue) => (
									<CoreValueBadge key={coreValue.id} value={coreValue} />
								))
							) : (
								<>
									{queryQuestionCoreValues.isError && (
										<MotionIonChip
											data-attr="modal.reflection.question.completed.try-again"
											initial={{ opacity: 0, display: "none" }}
											animate={{ opacity: 1, display: "block" }}
											className="bg-brown-200 text-brown-700 m-0 h-8 min-h-0 py-1"
											onClick={() => {
												PreferredHaptics.impact();
												queryQuestionCoreValues.refetch();
											}}
										>
											{t("try-again")}
											<IonIcon icon={arrowPathIcon} className="size-4" />
										</MotionIonChip>
									)}
								</>
							)}
							{queryQuestionCoreValues.isPending && (
								<div className="flex items-center gap-2">
									<IonSpinner className="text-brown-500 size-4" />
									{t("loading")}
								</div>
							)}
						</div>
					</ReflectionCard>
				)}
			</div>

			<IonFooter className="ion-no-border">
				<Toolbar className="ion-bg-brown-200 ion-p-4">
					<div className="flex flex-col gap-2">
						<Button
							data-attr="modal.reflection.question.completed.confirm"
							expand="block"
							fill="solid"
							className="!ion-rounded-2xl text-lg font-bold"
							onClick={() => {
								modalRef.current?.dismiss();

								if (!!notCompletedItems && notCompletedItems?.length > 0) {
									if (
										notCompletedItems[0].reflectionChat?.messages.length &&
										notCompletedItems[0].reflectionChat?.messages.length > 0
									) {
										router.push(
											Routes.ReflectionQuestion({ id: notCompletedItems[0].id }),
											"forward",
										);
									} else {
										presentModal("reflectionQuestionFirstAnswer", {
											id: notCompletedItems[0].id,
										});
									}
								} else {
									presentModal("reflectionCompleted");
								}
							}}
						>
							{t("modal.reflection.explainer.question.completed.confirm")}
						</Button>
						<Button
							data-attr="modal.reflection.question.completed.cancel"
							expand="block"
							fill="clear"
							className="ion-bg-a-brown-100 ion-bg-brown-100 ion-bg-f-brown-100 ion-bg-h-brown-100 ion-text-brown-700 !ion-rounded-2xl text-lg font-bold"
							onClick={() => {
								modalRef.current?.dismiss();
								router.push(Routes.Reflection(), "back");
							}}
						>
							{t("modal.reflection.explainer.question.completed.cancel")}
						</Button>
					</div>
				</Toolbar>
			</IonFooter>
		</IonModal>
	);
}
