import { IonModal, IonText } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Content } from "@/components/Global/Content";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalPerfectDayChatCompleted() {
	const { t } = useTranslation();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.perfectDayCompleted();
	const dismiss = useSelectorModalStore.use.dismiss();

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onClick={() => {
				modalRef.current?.dismiss();
			}}
			onWillDismiss={() => {
				dismiss("perfectDayCompleted", { id: null });
			}}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="!ion-rounded-none ion-h-full ion-w-full cursor-pointer"
		>
			<Content
				fullscreen
				scrollY={false}
				className="part-background:bg-gradient-to-b part-background:from-orange-500 part-background:to-[#FF944C] part-scroll:items-center part-scroll:justify-center part-scroll:gap-3 part-scroll:text-center part-scroll:text-white"
			>
				<IonText className="text-4xl font-bold">
					{t("modal.perfect-day.scene.completed.title")}
				</IonText>
				<IonText className="font-semibold text-gray-100">
					{t("modal.perfect-day.scene.completed.text")}
				</IonText>
			</Content>
		</IonModal>
	);
}
