import {
	IonBackButton,
	IonButtons,
	IonHeader,
	IonPage,
	IonSkeletonText,
	IonTitle,
	useIonRouter,
} from "@ionic/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { type SetOptional } from "type-fest";
import { FriendCheckFilledCard } from "@/components/FriendCheck/FilledCard";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { NotFound } from "@/components/Global/NotFound";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { type FriendCheckAnswerPair, type PatchFriendCheckSeenDto } from "@/lib/backend/api";
import { useExtractedMutation, useExtractedQuery } from "@/lib/backend/utils/hooks";
import { getAnswer } from "@/lib/functions/answer";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { Routes } from "@/lib/router";

export function FriendCheckSubmission() {
	const { t, i18n } = useTranslation();
	const posthog = usePostHog();
	const router = useIonRouter();
	const PreferredHaptics = usePreferredHaptics();
	const searchParams = Routes.FriendCheckSubmission.useParams();
	const queryClient = useQueryClient();

	function navigateBack() {
		if (router.routeInfo.pathname !== Routes.FriendCheckSubmission()) {
			return;
		}

		return router.canGoBack() ? router.goBack() : router.push(Routes.FriendCheck());
	}

	useEffect(() => {
		if (!searchParams.id) {
			navigateBack();
		}
	}, [searchParams.id]);

	const id = searchParams.id!;

	const queryFriendCheck = useExtractedQuery({
		queryKey: ["friend-check", id] as const,
		queryFn: (context) =>
			backendService.friendcheck.friendcheckControllerGetSubmission(context.queryKey[1], {
				signal: context.signal,
			}),
		enabled: !!id && id !== "undefined",
		retry: false,
	});

	const friendCheck = queryFriendCheck.data;

	const mutation = useExtractedMutation({
		mutationKey: ["friend-check", "submission", "seen"],
		mutationFn: ({ friendCheckId, seen = true }: SetOptional<PatchFriendCheckSeenDto, "seen">) =>
			backendService.friendcheck.friendcheckControllerMarkFriendCheckSeen({
				friendCheckId,
				seen,
			}),
		onSuccess: () => queryClient.refetchQueries({ queryKey: ["friend-check"] }),
	});

	useEffect(() => {
		if (!friendCheck || !!friendCheck.seen) {
			return;
		}

		mutation.mutate({ friendCheckId: friendCheck.id });
		posthog.capture("Friend Check Submission Seen", { friendCheckId: friendCheck.id });
	}, [friendCheck]);

	function flattenQuestions(
		question: FriendCheckAnswerPair | null | undefined,
	): FriendCheckAnswerPair[] {
		if (!question) {
			return [];
		}

		return [question, ...flattenQuestions(question.followUpQuestion)];
	}

	const questions = friendCheck?.questions.flatMap((question) => [
		question,
		...flattenQuestions(question.followUpQuestion),
	]);

	if (!id || (!queryFriendCheck.isLoading && !queryFriendCheck.data && searchParams.id)) {
		return (
			<IonPage>
				<IonHeader className="ion-no-border">
					<Toolbar className="ion-p-2 ion-pb-2 ion-pt-6 ion-min-h-16 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
						<IonButtons slot="secondary">
							<IonBackButton
								data-attr="page.friend-check.submission.back"
								data-touch-target
								defaultHref={Routes.FriendCheck()}
								color="light"
								className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
								icon={arrowLeftIcon}
								text=""
							/>
						</IonButtons>
					</Toolbar>
				</IonHeader>
				<Content>
					<NotFound
						title={t("page.friend-check.not-found.title")}
						description={t("page.friend-check.not-found.description")}
					>
						<Button
							data-attr="page.friend-check.not-found.action"
							color="secondary"
							onClick={() => router.push(Routes.FriendCheck())}
						>
							{t("page.friend-check.not-found.action")}
						</Button>
					</NotFound>
				</Content>
			</IonPage>
		);
	}

	return (
		<IonPage>
			<IonHeader>
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),--spacing(4))]">
					<IonTitle className="text-brown-700 text-base font-semibold">
						{t("friend-check.name")}
					</IonTitle>
					<IonButtons slot="secondary" onClick={() => PreferredHaptics.impact()}>
						<IonBackButton
							data-attr="page.friend-check.submission.back"
							data-touch-target
							defaultHref={Routes.FriendCheck()}
							color="light"
							className="part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600 min-h-0"
							icon={arrowLeftIcon}
							text=""
						/>
					</IonButtons>
				</Toolbar>
			</IonHeader>

			<Content>
				{queryFriendCheck.isLoading ? (
					<>
						<IonSkeletonText animated className="min-h-[4.375rem] w-full rounded-2xl" />
						{Array.from({ length: 3 }).map((_, index) => (
							<IonSkeletonText
								key={index}
								animated
								data-index={index}
								className="h-64 w-full rounded-2xl data-[index='1']:h-56 data-[index='2']:h-48"
							/>
						))}
					</>
				) : (
					<>
						{!!friendCheck && <FriendCheckFilledCard data={friendCheck} />}
						{questions?.map((question) => (
							<div key={question.question} className="flex flex-col gap-4 rounded-2xl bg-white p-3">
								<strong
									data-mask-text
									className="text-brown-600 text-base font-semibold text-pretty"
								>
									{question.question}
								</strong>
								<p
									data-mask-text
									className="border-brown-200 bg-brown-100 text-brown-600 rounded-2xl border-2 p-4 text-base font-medium"
								>
									{getAnswer(question.answer, { language: i18n.language })}
								</p>
							</div>
						))}
					</>
				)}
			</Content>
		</IonPage>
	);
}
